import { Avatar, Box, Button, Grid, Paper, Stack, Typography, Collapse, useMediaQuery } from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import Profile from "../../../assets/avater.png";
import IconLabelDescription from "../../../components/mui-custom/icon-label-description";
import { CustomDivider } from "../../../components/mui-custom/custome-divider";
import { API } from "../../../services/api-config";
import { getApiCallDynamic, postApiCallDynamicWithOutReturn } from "../../../services/api-service";
import { usPhoneNumberFormatDefaultValue } from '../../../constants/validation'
import axios from "axios";
import {
  NMLS_STATUS,
  NMLS_STATUS_INVALITED,
  ROLE_LIST,
  roleOwner,
  UPDATE,
} from "../../../constants/constatnt-variable";
import useInput from "../../../helpers/use-input";
import NmlsNoteForm from "./nmls-note-form";
import RadioButtonGroup from "../../../helpers/radio-button-group";
import { useRecoilValue } from "recoil";
import { profileState } from "../../../recoil/profile-atom";
import UpdateUserInfo from "../../../components/admin/update-user-info";
import CustomReactMaterialTable from "../../../helpers/table/react-material-table";
import CustomDropDown from "../../../helpers/dropdown/custom-dropdown";
import {
  KSArrowDownSmIcon,
  KSDocumentTextIcon,
  KSIdentificationIcon,
  KSMailIcon,
  KSPhoneIcon,
} from "../../../icons/custome-icon";
import { DialogCommonHooks } from "../../../helpers/modal/modal";
import AdminInvoiceTabPanel from "./AdminInvoiceTabPanel";
import { kstheme } from "../../../theme/theme";

export function UserCard({ userInfo, getUserInfo }) {
  const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));
  let profileInfo = useRecoilValue(profileState);
  const [onArrowClick, setOnArrowClick] = useState(false);
  const [updatedNmls, setUpdatedNmls] = useState(userInfo.stateLicenses.find((element) => element.status === 'not validated') || {});
  const [nmlsIdStatus] = useInput({
    data: null,
    status: false,
    initial: null,
  });
  const [status, setStatus] = useState('not validated');
  const [isGridVisible, setIsGridVisible] = useState(true);
  const [companyNmlsIdStatus] = useInput({
    data: null,
    status: false,
    initial: null,
  });
  const [phone] = useInput({
    data: userInfo.phone,
    status: true,
    initial: null,
  });

  const [email] = useInput({
    data: userInfo.email,
    status: true,
    initial: null,
  });

  useEffect(() => {
    if (userInfo != null) {
      nmlsIdStatus.saveValue(userInfo.nmlsIdStatus);
      companyNmlsIdStatus.saveValue(userInfo.companyNmlsIdStatus);
    }
  }, [userInfo]);

  {/*} useEffect(() => {
    if (userInfo != null) {
      console.log("NMLS " + nmlsIdStatus.value)
      console.log("User info nmls status " + userInfo.nmlsIdStatus)
      console.log("user info " + userInfo)
      if (
        nmlsIdStatus.value != null &&
        userInfo.nmlsIdStatus != nmlsIdStatus.value
      ) {
        nmlsChange({
          userCode: userInfo.userCode,
          nmlsId: userInfo.nmlsId,
          nmlsIdStatus: nmlsIdStatus.value,
        });
      }
      if (
        companyNmlsIdStatus.value != null &&
        userInfo.companyNmlsIdStatus != companyNmlsIdStatus.value
      ) {
        nmlsChange({
          userCode: userInfo.userCode,
          companyNmlsId: userInfo.companyNmlsId,
          companyNmlsIdStatus: companyNmlsIdStatus.value,
        });
      }
    }
  }, [nmlsIdStatus, companyNmlsIdStatus]);*/}


  useEffect(() => {
    if (userInfo != null) {

      if (
        nmlsIdStatus.value != null &&
        userInfo.nmlsIdStatus != nmlsIdStatus.value
      ) {
        nmlsChange({
          userCode: userInfo.userCode,
          nmlsId: userInfo.nmlsId,
          nmlsIdStatus: nmlsIdStatus.value,
        });
      }

    }
  }, [nmlsIdStatus, userInfo]);

  const [Avtar, setAvtar] = useState();
  useEffect(() => {
    const PDF_GENERATE_URL = `${API.getAvatar}?userCode=${userInfo.userCode}`;
    axios.get(PDF_GENERATE_URL, { responseType: 'blob' })
      .then((res) => {
        // Create a Blob from the PDF data received
        //  const blob = new Blob([res.data], { type: 'application/pdf' });
        // getApiCallDynamic({ path: API.getAvatar, param: { userCode: userInfo.userCode } })
        // .then((res) => {
        if (res?.status === 200) {

          const blob = new Blob([res.data], { type: 'image/png' });

          // Create a URL for the blob
          const imageUrl = URL.createObjectURL(blob);
          setAvtar(imageUrl);
          // Render the image using the URL
          console.log(imageUrl);

        }
      })
      .catch(error => {
        console.error("Error fetching avatar:", error);
        // setAvtar(Profile); // Set default avatar in case of an error
      });
  }, [userInfo.userCode]); // This hook runs whenever userInfo.userCode changes

  console.log(Avtar);
  //console.log(Profile);
  useEffect(() => {
    if (userInfo != null) {

      if (
        companyNmlsIdStatus.value != null &&
        userInfo.companyNmlsIdStatus != companyNmlsIdStatus.value
      ) {
        nmlsChange({
          userCode: userInfo.userCode,
          companyNmlsId: userInfo.companyNmlsId,
          companyNmlsIdStatus: companyNmlsIdStatus.value,
        });
      }
    }
  }, [companyNmlsIdStatus])


  const nmlsChange = (data) => {
    postApiCallDynamicWithOutReturn({
      data: data,
      refresh: () => {
        // Nothing
      },
      path: API.nmlsStatusChange,
      header: UPDATE,
    });
  };

  if (!userInfo) {
    return <></>;
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "Company NMLS ID",
        minSize: 30,
        maxSize: 30,
        enableSorting: false,
        size: 30,
        header: "Company NMLS ID",
        enableColumnActions: false,
        Cell: ({ cell }) => {
          const cellData = cell.row.original
          return (
            <>
              {cellData?.companyNmlsId || '-'}
            </>
          );
        },
      },
      {
        accessorKey: "Company State License ID",
        minSize: 70,
        maxSize: 70,
        size: 70,
        enableSorting: false,
        header: "Company State License ID",
        enableColumnActions: false,
        Cell: ({ cell }) => {
          return (
            <>
              {cell.row.original?.companyStateLicense || '-'}
            </>
          );
        },
      },
      {
        accessorKey: "NMLS ID",
        minSize: 40,
        enableSorting: false,
        maxSize: 40,
        size: 40,
        header: "NMLS ID",
        enableColumnActions: false,
        Cell: ({ cell }) => {
          return (
            <>
              {cell.row.original?.userNmlsId || '-'}
            </>
          );
        },
      },

      {
        accessorKey: "State License ID",
        minSize: 30,
        enableSorting: false,
        maxSize: 30,
        size: 30,
        header: "State License ID",
        enableColumnActions: false,
        Cell: ({ cell }) => {
          return (
            <>
              {cell.row.original?.stateLicenseCode || '-'}
            </>
          );
        },
      },

      {
        accessorKey: "State",
        minSize: 40,
        maxSize: 40,
        size: 40,
        header: "State",
        enableSorting: false,
        padding: 0,
        style: { padding: 0 },
        enableColumnActions: false,
        Cell: ({ cell }) => {
          return (
            <>
              {cell.row.original?.state || '-'}
            </>
          );
        },
      },
      {
        accessorKey: "Status",
        minSize: 40,
        enableSorting: false,
        maxSize: 40,
        size: 40,
        header: "Status",
        enableColumnActions: false,
        Cell: ({ cell }) => {
          const cellData = cell.row.original;
          const showError = !cellData?.companyStateLicense || !cellData?.stateLicenseCode || !cellData?.state || cellData?.companyStateLicense === '-' || cellData?.stateLicenseCode === '-'
          const status = ['Valid', 'Invalid', 'Not validated']
          const value = cellData?.status.charAt(0).toUpperCase() + cellData?.status.slice(1)
          return (
            <>
              <CustomDropDown
                style={{
                  border: '1px solid #2F80ED', color: '#2F80ED', fontWeight: 600, width: '165px', height: '34px'
                }}
                disabled={cellData?.status === 'valid' || showError}
                rowData={value}
                changeCallBack={(e) => onStatusChange(e.value, cellData)}
                defaultValue={value}
                option={status.map((i) => {
                  return {
                    value: i,
                    label: i,
                  };
                })}
              />
              {
                showError &&
                <Typography variant="p" color={'red'}>Cannot change status unless the details are completely filled.</Typography>
              }
            </>
          );
        },
      },
    ]
  );

  const rowData = [
    {
      id: 0,
      state: userInfo?.state,
      userNmlsId: userInfo?.nmlsId,
      companyNmlsId: userInfo?.companyNmlsId,
      companyStateLicense: '-',
      stateLicenseCode: '-',
      status: 'Not Validated'
    }
  ]

  const onStatusChange = (status, data) => {
    data.status = status
    setStatus(status)
    setUpdatedNmls(data)
  }

  const updateInfo = async (note) => {
    await postApiCallDynamicWithOutReturn({
      data: { ...updatedNmls, notes: note, userCode: userInfo.userCode },
      path: API.adminUpdateNMLA,
      header: UPDATE,
    });
    getUserInfo({ userCode: userInfo.userCode })
    setStatus('Not Validated')
  }

  return (
    <>
      <Grid display={'flex'} alignItems={'start'}>
        <Grid minWidth={'65%'}>
          <Grid
            minWidth={'100%'}
            item
            container
            borderRight={{ xs: 0, md: "1px solid #cccccc" }}
            position={"relative"}
            md={6}
          >
            <IconLabelDescription
              alignItems="center"
              icon={
                <Avatar

                  src={Avtar ? Avtar : Profile}
                  sx={{
                    height: { md: "50%", xs: "100%" },
                    width: { md: "50%", xs: "90%", sm: "70%" },
                  }}
                />
              }
              description={
                <>
                  <Typography variant="h3">
                    {userInfo.firstName + " " + userInfo.lastName}
                  </Typography>
                  <Typography variant="body1">{userInfo.role}</Typography>
                </>
              }
            />
          </Grid>
          <Grid
            minWidth={'100%'}
            item
            container
            borderRight={{ xs: 0, md: "1px solid #cccccc" }}
            position={"relative"}
            md={6}
          >
            <IconLabelDescription
              icon={<PlaceOutlinedIcon />}
              iconLabel={"Address"}
              /*  description={
                  userInfo?.firstAddress ?   + " " +
                  userInfo?.city + " " +
                  userInfo?.state +
                  " " +
                  userInfo?.zip
                } */
              description={
                `${userInfo?.firstAddress ? userInfo.firstAddress : '-'} ${userInfo?.city ? userInfo.city : '-'} ${userInfo?.state ? userInfo.state : '-'} ${userInfo?.zip ? userInfo.zip : '-'}`
              }

            />
          </Grid>
          <Grid
            minWidth={'100%'}
            item
            container
            borderRight={{ xs: 0, md: "1px solid #cccccc" }}
            position={"relative"}
            md={6}
          >
            <IconLabelDescription
              icon={<KSPhoneIcon />}
              iconLabel={"Phone"}
              description={usPhoneNumberFormatDefaultValue(phone.value)}
            />
          </Grid>
          <Grid
            minWidth={'100%'}
            item
            container
            borderRight={{ xs: 0, md: "1px solid #cccccc" }}
            position={"relative"}
            md={6}
          >
            <IconLabelDescription
              icon={<KSMailIcon />}
              iconLabel={"Mail"}
              description={email.value}
            />
          </Grid>
        </Grid>
        {!isMobile && (
          <Grid width={'50%'} mt={'auto'}>
            <Grid
              minWidth={'100%'}
              item
              md={6}
              px={2}
              py={{ xs: 0, md: 2 }}
              container
              justifyContent="center"
              position={"relative"}
            >
              {/* for phone update */}
              <UpdateUserInfo
                variant={"phone"}
                updatedValue={phone}
                userCode={userInfo.userCode}
                userEmail={userInfo.email}
                adminEmail={profileInfo.email}
              />

              <CustomDivider display={{ xs: "block", md: "none" }} width={"90%"} />
            </Grid>

            <Grid
              minWidth={'100%'}

              item
              md={6}
              px={2}
              py={{ xs: 0, md: 2 }}
              container
              justifyContent="center"
              position={"relative"}
            >
              {/* for Email update */}
              <UpdateUserInfo
                variant="email"
                updatedValue={email}
                userCode={userInfo.userCode}
                userEmail={userInfo.email}
                adminEmail={profileInfo.email}
              />

              <CustomDivider display={{ xs: "block", md: "none" }} width={"90%"} />
            </Grid>
          </Grid>
        )}
      </Grid>
      {isMobile && (
        <Grid width={'100%'} mt={'auto'}>
          <Grid
            minWidth={'100%'}
            item
            md={6}
            px={2}
            py={{ xs: 0, md: 2 }}
            container
            justifyContent="center"
            position={"relative"}
          >
            {/* for phone update */}
            <UpdateUserInfo
              variant={"phone"}
              updatedValue={phone}
              userCode={userInfo.userCode}
              userEmail={userInfo.email}
              adminEmail={profileInfo.email}
            />

            <CustomDivider display={{ xs: "block", md: "none" }} width={"90%"} />
          </Grid>

          <Grid
            minWidth={'100%'}

            item
            md={6}
            px={2}
            py={{ xs: 0, md: 2 }}
            container
            justifyContent="center"
            position={"relative"}
          >
            {/* for Email update */}
            <UpdateUserInfo
              variant="email"
              updatedValue={email}
              userCode={userInfo.userCode}
              userEmail={userInfo.email}
              adminEmail={profileInfo.email}
            />

            <CustomDivider display={{ xs: "block", md: "none" }} width={"90%"} />
          </Grid>
        </Grid>)}
      {/* license section */}
      {(userInfo.role === "Broker" || userInfo.role === "MLO" || userInfo.role === "MLO Owner") &&
        <>
          <Grid
            minWidth={'65%'}
            style={{ borderRight: '1px solid #cccccc' }}
            item
            container
            position={"relative"}
            md={6}
          >
            <IconLabelDescription
              onArrowClick={() => setOnArrowClick(!onArrowClick)}
              icon={<KSDocumentTextIcon />}
              iconLabel={"Licenses"}
              description={<KSArrowDownSmIcon />}
            />
          </Grid>
          {onArrowClick &&
            <Box p={3} minWidth={'100%'}>
              <CustomReactMaterialTable
                enableTopToolbar={false}
                enablePagination={false}
                rowData={userInfo.stateLicenses.length ? userInfo.stateLicenses : rowData}
                columns={columns}
                enableRowActions={false}
                enableBottomToolbar={false}
              />
            </Box>
          }
          {status === 'Invalid' &&
            <NmlsNoteForm userCode={userInfo.userCode} updateInfo={updateInfo} />}
          {status === 'Valid' &&
            <DialogCommonHooks
              show={true}
              handleClose={() => setStatus('Not Validated')}
              title={`Confirm Valid Status`}
              body={
                <>
                  <Box width={'100%'} my={2} >
                    <Box >
                      <Typography textAlign={'justify'} mb={2}>
                        {
                          `Are you sure you want to confirm the status as "Valid"?`
                        }
                      </Typography>

                      <Stack direction={'row-reverse'} spacing={2}>
                        <Button variant='contained' onClick={(e) => { e.preventDefault(); updateInfo(); setStatus('Not Validated'); }}>Yes</Button>
                        <Button variant='text' onClick={() => setStatus('Not Validated')}>No</Button>
                      </Stack>
                    </Box>
                  </Box>

                </>
              }
            />
          }
        </>
      }
      <Grid
        // minWidth={'100%'}
        item
        container
        borderRight={{ xs: 0, }}
        position={"relative"}
        md={12} // Use md={12} to make it span full width on medium and larger screens

      >
        <Collapse in={isGridVisible}>
          <Box width={'100%'} my={2} >
            <AdminInvoiceTabPanel userInfo={userInfo} />
          </Box>
        </Collapse>
      </Grid>
      <Grid
        minWidth={'100%'}
        display={
          nmlsIdStatus.value === NMLS_STATUS_INVALITED ||
            companyNmlsIdStatus.value === NMLS_STATUS_INVALITED
            ? ""
            : "none"
        }
        container
        p={{ xs: 2, md: "1px solid #cccccc" }}
      >
        <NmlsNoteForm userCode={userInfo.userCode} />
      </Grid>
    </>
  );
}
