import React from 'react'
import { ArrowBackIos, ArrowForwardIos, NavigateNext } from '@mui/icons-material'
import { Box, Breadcrumbs, Button, Link as MLink, Stack, Typography } from '@mui/material'
import { Controller, useForm, useWatch } from 'react-hook-form'
import {
    MARITAL_STATUS,
    STATUS_OF_CITIZENSHIP,
    SUFFIX,
    VISA_TYPE
} from '../../../../../../constants/constatnt-variable'
import CustomInput from '../../../../../../helpers/custom-input'
import CustomInputSideCheckbox from '../../../../../../helpers/custom-input-side-checkbox'
import CustomDropDown from '../../../../../../helpers/dropdown/custom-dropdown'
import CustomLargeLabel from '../../../../../../helpers/large-label'
import CustomRadioChip from '../../../../../../helpers/radio-chip'
import { Link } from 'react-router-dom'
import { TimeStampToMonthDateYearForInput } from '../../../../../../helpers/time-formater'
import { PersonalInfoValidation } from '../../../../../../constants/validation-rules'
import { ssnDataFormat } from '../../../../../../constants/validation'


// Form State builder 
const PersonalForm = ({ onSubmit, data, partnerInfoPath }) => {
    function formatSSN(value) {
        if (value === null) {
            return '';
        }
        return value.replace(/^(\d{3})(\d{2})(\d{4})$/, '$1-$2-$3');
    }

    function unformatSSN(value) {
        return value.replace(/\D/g, '');
    }

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            firstName: data?.firstName,
            lastName: data?.lastName,
            middleName: data?.middleName,
            alternateName: data?.alternateName,
            ssn: ssnDataFormat(data?.ssn),
            suffix: data?.suffix,
            dateOfBirth: TimeStampToMonthDateYearForInput(data?.dateOfBirth),
            statusOfCitizenship: data?.statusOfCitizenship,
            visatype: data?.visatype,
            maritalStatus: data?.maritalStatus
        },
    })

    const { statusOfCitizenship, maritalStatus } = useWatch({ control })


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                <Box>
                    <Breadcrumbs aria-label="breadcrumb"
                        separator={<NavigateNext fontSize="small" />}
                    >
                        <MLink fontWeight={700} underline="hover" color="inherit" href="#">
                            {`${partnerInfoPath == "partner-info" ? "Co-Borrower" : "Borrower"} Info`}
                        </MLink>
                        <Typography color="primary.main" fontWeight={700}>Personal</Typography>
                    </Breadcrumbs>
                    <Box maxWidth={{ xs: '100%', md: '50%' }}>
                        <CustomLargeLabel value={'Tell us more about yourself'} required />
                        <Controller
                            name='firstName'
                            control={control}
                            rules={PersonalInfoValidation.firstName}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    type={'text'}
                                    label={'First Name'}
                                    required
                                    error={errors?.firstName?.message}
                                />
                            )}
                        />

                        <Controller
                            name='middleName'
                            control={control}
                            rules={PersonalInfoValidation.middleName}
                            render={({ field }) => (
                                <CustomInput {...field} type={'text'} label={'Middle Name'} error={errors?.middleName?.message} />
                            )}
                        />

                        <Controller
                            name='lastName'
                            control={control}
                            rules={PersonalInfoValidation.lastName}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    type={'text'}
                                    label={'Last Name'}
                                    required
                                    error={errors?.lastName?.message}
                                />
                            )}
                        />
                        <Controller
                            name='suffix'
                            control={control}
                            rules={PersonalInfoValidation.suffix}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    type={'text'}
                                    label={`Suffix`}
                                    error={errors?.suffix?.message}
                                />
                            )}
                        />


                        <Controller
                            name='ssn'
                            control={control}
                            rules={PersonalInfoValidation.ssn}
                            render={({ field }) => (
                                <CustomInputSideCheckbox
                                    {...field}
                                    type={'text'}
                                    label={'SSN'}
                                    checkLabel={'ITIN'}

                                    value={(field.value)}
                                    onChange={(e) => {
                                        const formattedValue = unformatSSN(e.target.value);
                                        if (formattedValue.length <= 9) {
                                            field.onChange(formattedValue);
                                        }
                                    }}
                                    onBlur={(e) => {
                                        const formattedValue = formatSSN(e.target.value);
                                        field.onChange(formattedValue);
                                    }}

                                    required
                                    error={errors?.ssn?.message}
                                />
                            )}
                        />


                        <Controller
                            name='dateOfBirth'
                            control={control}
                            rules={PersonalInfoValidation.dateOfBirth}
                            render={({ field }) => (
                                <CustomInput {...field}
                                    type={'date'}
                                    label={'Date of Birth'}
                                    required
                                    error={errors?.dateOfBirth?.message}

                                />

                            )}
                        />

                        <Controller
                            name='alternateName'
                            control={control}
                            rules={PersonalInfoValidation.alternateName}
                            render={({ field }) => (
                                <CustomInput {...field} type={'text'}
                                    label={'Alternate Name'}
                                    placeholder={'Enter your alternate name (e.g., suffix/first/middle/last)'}
                                    error={errors?.alternateName?.message} />
                            )}
                        />


                        {/* <Controller
                            name='statusOfCitizenship'
                            control={control}
                            rules={PersonalInfoValidation.statusOfCitizenship}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    radioWidth='600px'
                                    field={field}
                                    state={statusOfCitizenship}
                                    required
                                    label={'Citizenship'}
                                    data={STATUS_OF_CITIZENSHIP}
                                    error={errors?.statusOfCitizenship?.message}

                                />
                            )}
                        /> */}

                        <Box width={{ xs: '100%', md: '100%' }} maxWidth="600px">
                            <Controller
                                name='statusOfCitizenship'
                                control={control}
                                rules={PersonalInfoValidation.statusOfCitizenship}
                                render={({ field }) => (
                                    <CustomRadioChip
                                        {...field}
                                        radioWidth='100%'
                                        field={field}
                                        state={statusOfCitizenship}
                                        required
                                        label={'Citizenship'}
                                        data={STATUS_OF_CITIZENSHIP}
                                        error={errors?.statusOfCitizenship?.message}
                                    />
                                )}
                            />
                        </Box>


                        {statusOfCitizenship === 'Non-Permanent Resident Alien' ?
                            <>
                                <Controller
                                    name='visatype'
                                    control={control}
                                    rules={PersonalInfoValidation.visatype}
                                    render={({ field }) => (
                                        <CustomDropDown
                                            {...field}
                                            field={field}
                                            required
                                            label={'Visa type'}
                                            option={VISA_TYPE}
                                            error={errors?.visatype?.message}
                                        />
                                    )}
                                />
                            </>
                            : null
                        }

                        <Controller
                            name='maritalStatus'
                            control={control}
                            rules={PersonalInfoValidation.maritalStatus}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    state={maritalStatus}
                                    required
                                    label={'Marital Status'}
                                    data={MARITAL_STATUS}
                                    error={errors?.maritalStatus?.message}
                                />
                            )}
                        />
                    </Box>
                </Box>
                <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                    <Stack direction={'row'} justifyContent={'space-between'}>


                        {partnerInfoPath === "partner-info" ? (
                            <Link to={'../../borrower-info/military-service'}>
                                <Button startIcon={<ArrowBackIos />}>
                                    Previous
                                </Button>
                            </Link>
                        ) : (
                            <Link to={'../../loan-details'}>
                                <Button startIcon={<ArrowBackIos />}>
                                    Previous
                                </Button>
                            </Link>
                        )}

                        <Button type='submit' endIcon={<ArrowForwardIos />}>
                            Save and Next
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        </form>
    )

}
export default PersonalForm;