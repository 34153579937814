import { Box, Button, Stack, Typography, FormControlLabel, Checkbox } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomRadioChip from '../../../../../helpers/radio-chip'
import CustomInput from '../../../../../helpers/custom-input'
import CustomDropDown from '../../../../../helpers/dropdown/custom-dropdown'
import { AttachMoney } from '@mui/icons-material'
import { Controller, useForm, useWatch } from 'react-hook-form'
import {
    LOAN_CREDIT_TYPE, LOAN_CREDIT_TYPE_MORTGAGE, LOAN_CREDIT_TYPE_MORTGAGE_OPTION,
    LOAN_CREDIT_TYPE_NON_MORTGAGE, LOAN_CREDIT_TYPE_NON_MORTGAGE_OPTION, LOAN_CREDIT_TYPE_EXPENSE,
    LOAN_CREDIT_TYPE_EXPENSE_OPTION, LOAN_OWNED_BY, LOAN_OWNED_BY_BORROWER, OMIT_REASON
} from '../../../../../constants/constatnt-variable'
import SingleSelectDynamicValueDropdown from '../../../../../helpers/dropdown/single-select-dropdown'
import { KSPlusCircleIcon } from '../../../../../icons/custome-icon'
import { useLocation, useNavigate } from 'react-router-dom'
import { LiabilitiesValidation } from '../../../../../constants/validation-rules'
import { formatNumericValue, parseNumericValue } from '../../../../../helpers/numeric-format'

export default function LiabilitiesForm({ realEstate, onSubmit, editItem, handleClose, hasPartner, value }) {



    let propertyAddressOptionList = [];
    realEstate.map((item) => {

        if (item.propertyMortgage === 'Yes') {

            propertyAddressOptionList.push({
                "label": item?.addressLine1,
                "value": item?.addressLine1
            });
        }
    });



    const location = useLocation();
    const route = useNavigate();


    const { control, setValue, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            creditType: editItem?.creditType ? editItem?.creditType : value,
            companyName: editItem?.companyName,
            propertyAddress: editItem?.propertyAddress ? editItem?.propertyAddress : null,
            accountNo: editItem?.accountNo,
            unpaidBalance: editItem?.unpaidBalance,
            monthlyPay: editItem?.monthlyPay,
            monthsLeft: editItem?.monthsLeft,
            liabilityType: editItem?.liabilityType ? editItem?.liabilityType : null,
            ownedBy: editItem?.ownedBy ? editItem?.ownedBy : null,

            omitStatus: editItem ? editItem.omitStatus : false,
            omitType: editItem?.omitType ? editItem?.omitType : null,
            isTaxesInsuranceIncluded: editItem?.isTaxesInsuranceIncluded,
            notes: editItem?.notes,
            mode: editItem && editItem.mode != null ? editItem.mode : 'Manual Entry',

        }
    })

    const { creditType, omitStatus } = useWatch({ control })



    const [loanTerm, setLoanTerm] = useState([]);

    const [liabilityType, setLiabilityType] = useState([]);

    useEffect(() => {
        if (creditType == LOAN_CREDIT_TYPE_MORTGAGE) {
            setLiabilityType(LOAN_CREDIT_TYPE_MORTGAGE_OPTION)
        }
        if (creditType == LOAN_CREDIT_TYPE_NON_MORTGAGE) {
            setLiabilityType(LOAN_CREDIT_TYPE_NON_MORTGAGE_OPTION)
        }
        if (creditType == LOAN_CREDIT_TYPE_EXPENSE) {
            setLiabilityType(LOAN_CREDIT_TYPE_EXPENSE_OPTION)
        }
    }, [creditType])

    useEffect(() => {
        if (omitStatus === null || omitStatus === undefined) {
            setValue('omitStatus', false);
        }
    }, [omitStatus]);




    useEffect(() => {
        if (omitStatus === false) {
            setValue('omitType', null)

        }

    }, [omitStatus])
    const [mode, setMode] = useState('Manual Entry');



    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <Box width={'100%'} my={2} >
                <Box>
                    <Controller
                        name='creditType'
                        control={control}
                        rules={LiabilitiesValidation.creditType}
                        render={({ field }) => (
                            <CustomRadioChip
                                {...field}
                                field={field}
                                required
                                label={'Credit Type'}
                                data={LOAN_CREDIT_TYPE}
                                error={errors?.creditType?.message}
                            />
                        )}
                    />
                    {creditType !== LOAN_CREDIT_TYPE_EXPENSE ?

                        <Controller
                            name='companyName'
                            control={control}
                            rules={LiabilitiesValidation.companyName}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    type={'text'}
                                    label={'Company Name'}
                                    required
                                    error={errors?.companyName?.message}
                                    disabled={editItem.length != 0 && editItem.mode !== 'Manual Entry'}

                                />

                            )}

                        /> : null}
                    {creditType == LOAN_CREDIT_TYPE_MORTGAGE ?
                        <Controller
                            name='propertyAddress'
                            control={control}
                            rules={LiabilitiesValidation.propertyAddress}
                            render={({ field }) => (
                                <>

                                    <CustomDropDown
                                        {...field}
                                        field={field}
                                        label={'Property'}
                                        labelRightComponent={
                                            <Stack onClick={() => route(location.pathname.replace(/[^/]*$/, 'real-estate'))} direction={'row'} gap={1} color={'primary.main'}>
                                                <KSPlusCircleIcon />
                                                <Typography>{`Add New Property`}</Typography>
                                            </Stack>
                                        }
                                        required
                                        option={propertyAddressOptionList}
                                        error={errors?.propertyAddress?.message}
                                    />
                                </>
                            )}
                        /> : null}
                    {creditType !== LOAN_CREDIT_TYPE_EXPENSE ?
                        <>
                            <Controller
                                name='accountNo'
                                control={control}
                                rules={LiabilitiesValidation.accountNo}
                                render={({ field }) => (
                                    <CustomInput
                                        {...field}
                                        type={'string'}
                                        label={'Account Number'}
                                        required
                                        error={errors?.accountNo?.message}
                                        disabled={editItem.length != 0 && editItem.mode !== 'Manual Entry'}
                                    />

                                )}
                            />
                            <Controller
                                name='unpaidBalance'
                                control={control}
                                rules={LiabilitiesValidation.unpaidBalance}
                                render={({ field }) => (
                                    <CustomInput
                                        {...field}
                                        type={'text'}
                                        label={'Unpaid Balance'}
                                        required
                                        sicon={<AttachMoney />}
                                        error={errors?.unpaidBalance?.message}
                                        value={formatNumericValue(field.value)}
                                        onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                        disabled={editItem.length != 0 && editItem.mode !== 'Manual Entry'}
                                    />

                                )}
                            />
                        </> : null}
                    <Controller
                        name='monthlyPay'
                        control={control}
                        rules={LiabilitiesValidation.monthlyPay}
                        render={({ field }) => (
                            <CustomInput
                                {...field}
                                type={'text'}
                                label={'Monthly Payment'}
                                required
                                sicon={<AttachMoney />}
                                error={errors?.monthlyPay?.message}
                                value={formatNumericValue(field.value)}
                                onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                disabled={editItem.length != 0 && editItem.mode !== 'Manual Entry'}
                            />

                        )}
                    />
                    {creditType == LOAN_CREDIT_TYPE_MORTGAGE ?
                        <Controller
                            name="isTaxesInsuranceIncluded"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                        />
                                    }
                                    label={"Payment includes taxes & insurance"}
                                    error={errors?.occupancyType?.message}
                                />
                            )}
                        /> : null}
                    <Controller
                        name='monthsLeft'
                        control={control}
                        rules={LiabilitiesValidation.monthsLeft}
                        render={({ field }) => (
                            <CustomInput
                                {...field}
                                type={'number'}
                                label={'Months Left to pay'}

                                error={errors?.monthsLeft?.message}
                                disabled={editItem.length != 0 && editItem.mode !== 'Manual Entry'}
                            />

                        )}
                    />
                    <Controller
                        name='liabilityType'
                        control={control}
                        rules={LiabilitiesValidation.liabilityType}
                        render={({ field }) => (
                            <SingleSelectDynamicValueDropdown
                                label={"Liability Type"}
                                {...field}
                                field={field}
                                options={liabilityType}
                                required
                                value={loanTerm}
                                setValue={setLoanTerm}
                                error={errors?.liabilityType?.message}
                            />
                        )}
                    />

                    {hasPartner ?
                        <Controller
                            name='ownedBy'
                            control={control}
                            rules={LiabilitiesValidation.ownedBy}
                            render={({ field }) => (
                                <>
                                    <CustomDropDown
                                        {...field}
                                        field={field}
                                        label={'Liability Owned By'}
                                        option={LOAN_OWNED_BY}
                                        error={errors?.ownedBy?.message}

                                    />
                                </>
                            )}
                        /> :
                        <Controller
                            name='ownedBy'
                            control={control}
                            rules={LiabilitiesValidation.ownedBy}
                            render={({ field }) => (
                                <>
                                    <CustomDropDown
                                        {...field}
                                        field={field}
                                        label={'Liability Owned By'}
                                        option={LOAN_OWNED_BY_BORROWER}
                                        error={errors?.ownedBy?.message}
                                    />
                                </>
                            )}
                        />}

                    <Controller
                        name="omitStatus"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...field}
                                        checked={field.value}
                                    />
                                }
                                label={"Omit"}
                                error={errors?.occupancyType?.message}
                            />
                        )}
                    />
                    {omitStatus == true ?
                        <Controller
                            name='omitType'
                            control={control}
                            rules={LiabilitiesValidation.omitType}

                            render={({ field }) => (
                                <>
                                    <CustomDropDown
                                        {...field}
                                        field={field}
                                        label={'Omit Reason'}
                                        required
                                        option={OMIT_REASON}
                                    />
                                </>
                            )}
                        /> : null}



                    {editItem.length != 0 && ( // Conditionally render the Notes field
                        <Controller
                            name='notes'
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label={'Notes'}
                                    placeholder={'add reason why you’re editing'}
                                    size="large"
                                    multiline
                                    rows={3}
                                />
                            )}
                        />
                    )}







                </Box>

                <Stack direction={'row-reverse'} gap={2} >
                    <Button type='submit' variant='contained' >
                        {editItem.length == 0 ? 'Add liability' : 'Update'}

                    </Button>
                    <Button variant='text' onClick={handleClose}>{`Cancel`}</Button>
                </Stack>
            </Box>
        </form>
    )
}
