import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import ConfirmationModel from '../../../../../../helpers/modal/confirm-modal';
import { DialogCommonHooks } from '../../../../../../helpers/modal/modal';
import CustomReactMaterialTable from '../../../../../../helpers/table/react-material-table';
import { DateToTimestapmFormater, TimeStampToMonthDateYearForInput } from '../../../../../../helpers/time-formater';
import { KSPencilAltIcon, KSTrashIcon } from '../../../../../../icons/custome-icon';
import AdditionalIncomeForm from './additional-income-form';
import { formatNumericValue, parseNumericValue } from '../../../../../../helpers/numeric-format'



export default function AdditionalIncome({ setValue, incomeList, setIncomeList }) {


    const [editState, setEditState] = useState(() => {
        return null
    })


    useEffect(() => {
        setValue('additionalIncome', incomeList)
    }, [incomeList])





    // Edit table
    useEffect(() => {
        if (editState != null) {
            let list = _.cloneDeep(incomeList)
            let editItem = list.find((item, i) => i == editState)
            setAddIncomeEdit(editItem)
            setModalOpen(true);
        }

    }, [editState])


    const [deleteState, setDeleteState] = useState(() => {
        return null
    })




    // delete table
    useEffect(() => {
        if (deleteState != null) {
            incomeList.splice(deleteState, 1)
            setIncomeList([...incomeList])
            setDeleteState(null)
        }

    }, [deleteState])

    const tableColumn = useMemo(
        () => [
            {
                accessorKey: "incomeSource",
                header: "Income Source",
                enableColumnActions: false,
            },
            {
                accessorKey: "monthIncome",
                header: "Monthly Income",
                enableColumnActions: false,

                Cell: ({ cell, row }) => (
                    <Typography >
                        {`$ ${formatNumericValue(row.original["monthIncome"])}`}
                    </Typography>
                ),
            },
        ],
        []
    );


    const [modalOpen, setModalOpen] = useState(false);
    const [addIncomeEdit, setAddIncomeEdit] = useState();

    const handleModalOpen = () => {
        setAddIncomeEdit([])
        setModalOpen(true);
    }

    const handleClose = () => {
        setModalOpen(false);
        setEditState(null)
    }

    const handleCloseDelete = (event, row) => {

        if (event.target.value == 'yes') {
            setDeleteState(row.index)

        }
    }
    const addIncomeSubmit = async data => {


        let list = _.cloneDeep(incomeList) || [];
        data = Object.assign(data, {



        })

        if (editState != null) {
            list[editState] = {
                ...list[editState],
                ...data
            }


            setEditState(null)
        } else {
            list.push(data)

        }

        setIncomeList(list)
        handleClose();

    }




    return (
        <Box>
            <Typography variant='h3' mb={1} mt={3} >{`Do you have additional income?`}</Typography>

            <CustomReactMaterialTable
                staticLoad={true}
                staticData={incomeList}
                columns={tableColumn}
                enablePagination={false}
                enableBottomToolbar={false}
                enableSorting={false}
                enableToolbarInternalActions={false}
                renderTopToolbarCustomActions={
                    () => (
                        <Button onClick={handleModalOpen} variant='outlined' >Add</Button>
                    )
                }
                enableRowActions={true}
                renderRowActions={
                    ({ row }) => {

                        return (
                            <Stack direction={'row'}>
                                <IconButton onClick={() => { setEditState(row.index) }}>
                                    <KSPencilAltIcon />
                                </IconButton>


                                <ConfirmationModel
                                    body={<IconButton
                                        id={row.index} >
                                        <KSTrashIcon />
                                    </IconButton>}
                                    message={`Are you sure want remove ${row.original.businessName} ?`}
                                    title={'Delete '}
                                    handleSubmit={(event) => { handleCloseDelete(event, row) }}
                                />

                            </Stack>
                        )
                    }
                }
            />

            <DialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={`Additional Income Info`}
                body={
                    <AdditionalIncomeForm
                        onSubmit={addIncomeSubmit}
                        editItem={addIncomeEdit}
                    />
                }
            />
        </Box>
    )
}
