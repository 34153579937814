import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";

export default function IconLabelDescription({
  icon,
  iconLabel,
  description,
  alignItems = { xs: "center", md: "center" },
  divider = true,
  onArrowClick
}) {
  return (
    <>
      <Grid item container alignItems={alignItems} direction={"row"} p={2}>
        <Grid item xs={5} md={4}>
          <Stack
            direction={"row"}
            gap={{ md: 3, xs: 1 }}
            alignItems={alignItems}
          >
            {icon}
            <Typography variant="body2">{iconLabel}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={7} md={8} pl={onArrowClick ? 4 : 5} onClick={onArrowClick} mt={onArrowClick ? 2 : ''}>
          <Typography variant="body2">{description}</Typography>
        </Grid>
      </Grid>

      <Box
        display={{ xs: "none", md: "block" }}
        width={"90%"}
        position={"absolute"}
        bottom={1}
      >
        <Divider variant="fullWidth" />
      </Box>
    </>
  );
}
