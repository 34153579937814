import { ArrowBackIos, ArrowForwardIos, NavigateNext } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Breadcrumbs, Button, IconButton, Stack, Tab, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { DialogCommonHooks } from '../../../../../helpers/modal/modal';
import CustomReactMaterialTable from '../../../../../helpers/table/react-material-table';
import LiabilitiesForm from './liabilities-form';
import { LOAN_CREDIT_TYPE_EXPENSE, LOAN_CREDIT_TYPE_MORTGAGE, LOAN_CREDIT_TYPE_NON_MORTGAGE, UPDATE } from '../../../../../constants/constatnt-variable';
import { KSPencilAltIcon, KSTrashIcon } from '../../../../../icons/custome-icon';
import ConfirmationModel from '../../../../../helpers/modal/confirm-modal';
import { useRecoilState } from 'recoil';
import { formState } from '../../../../../recoil/profile-atom';
import CustomInput from '../../../../../helpers/custom-input';
import DialogSuccesMsg from '../../../../../helpers/modal/dialog-succes-msg';
import { API } from '../../../../../services/api-config';
import { postApiCallDynamicWithOutReturn } from '../../../../../services/api-service';
import { Link as MLink } from '@mui/material';
import { formatNumericValue, parseNumericValue } from '../../../../../helpers/numeric-format'

import { getApiCallDynamic } from "../../../../../services/api-service";

export default function LoanLiabilities({ partnerInfoPath }) {
    const location = useLocation();
    const route = useNavigate();



    let [formValue, setFormValue] = useRecoilState(formState)





    const [value, setValue] = useState(LOAN_CREDIT_TYPE_MORTGAGE);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const renderLiabilityType = (liabilityType) => {
        switch (liabilityType) {
            case "Collection": return "Collections Judgements and Liens";
            case "Installment": return "Installment(e.g., car, student, personal loans)";
            case "Auto": return "Lease(not real estate)";
            case "Open": return "Open 30 - Day(balance paid monthly)";
            case "Revolving": return "Revolving(e.g., credit cards)";
            case "CreditLine": return "Mortgage Loan - Second Lien";
            case "Mortgage": return "Mortgage Loan - First lien";
            case "Unknown": return "Others";
            default:
                return liabilityType;
        }
    };

    let [loanMortgageLiabilityData, setLoanMortgageLiabilityData] = useState([])

    let [loanNonMortgageLiabilityData, setLoanNonMortgageLiabilityData] = useState([])
    let [loanExpenseData, setLoanExpenseData] = useState([])


    useEffect(() => {
        getApiCallDynamic({
            path: API.formData,
            param: { formId: formValue.id },
        }).then((res) => {
            if (res?.status === 200) {
                setLoanMortgageLiabilityData(res.data?.liabilities?.page?.mortgage)
                setLoanNonMortgageLiabilityData(res.data?.liabilities?.page?.nonMortgage)
                setLoanExpenseData(res.data?.liabilities?.page?.expense)

            } else {
                // navigatetoFromPage("/");
            }
            // setSpinner(false);
        });

    }, []);








    const tableColumnMortgageLiability = useMemo(
        () => [
            {
                accessorKey: "companyName",
                header: "Company Name",
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <>
                        <Typography>
                            {`${row.original["companyName"]}`}
                        </Typography><br />
                        <Typography color={'A7.main'} variant='body2'>
                            {`Owned by : ${row.original["ownedBy"] == 'Partner' ?
                                formValue?.getStarted?.page?.partner?.firstName :
                                formValue?.borrowerInfo?.page?.personal?.subPage?.firstName}`}
                        </Typography>
                    </>
                ),
            },
            {
                accessorKey: "liabilityType",
                header: "Liability Type",
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <>
                        <Typography>

                            {renderLiabilityType(row.original["liabilityType"])}
                        </Typography><br />
                        {/* <Typography color={'A7.main'} variant='body2'>
                            {`Address: ${row.original["propertyAddress"]}`}
                        </Typography> */}
                        <Typography color={'A7.main'} variant='body2'>
                            {`Address: ${row.original["propertyAddress"] ? row.original["propertyAddress"] : ''}`}
                        </Typography>

                    </>
                ),
            },
            {
                accessorKey: "unpaidBalance",
                header: "Balance",
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <Typography mb={4.5}>
                        {`$ ${formatNumericValue(row.original["unpaidBalance"])}`}
                    </Typography>
                ),
            },
            {
                accessorKey: "monthlyPay",
                header: "Monthly Payment",
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <Typography mb={4.5}>
                        {`$ ${formatNumericValue(row.original["monthlyPay"])}`}
                    </Typography>
                ),
            },
            {
                accessorKey: "monthsLeft",
                header: "Months Left",
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <>
                        <Typography mb={2}>
                            {`${row.original["monthsLeft"]}`}
                        </Typography><br />
                        <Typography color={'A7.main'} variant='body2'>
                            {row.original["omitType"] ? (
                                <Typography>
                                    {`Omit reason: ${row.original["omitType"]}`}
                                </Typography>
                            ) : null}
                        </Typography>
                    </>
                ),
            },
            {
                accessorKey: "accountNo",
                header: "Account No",
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <Typography mb={4.5}>
                        {`${row.original["accountNo"]}`}
                    </Typography>
                ),
            },
        ],
        []
    );



    const tableColumnNonMortgageLiability = useMemo(
        () => [
            {
                accessorKey: "companyName",
                header: "Company Name",
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <>
                        <Typography>
                            {`${row.original["companyName"]}`}
                        </Typography><br />
                        <Typography color={'A7.main'} variant='body2'>
                            {`Owned by : ${row.original["ownedBy"]}`}
                        </Typography>
                    </>
                ),
            },
            {
                accessorKey: "liabilityType",
                header: "Liability Type",
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <>
                        <Typography>

                            {renderLiabilityType(row.original["liabilityType"])}
                        </Typography><br />
                        <Typography color={'A7.main'} variant='body2'>
                            {row.original["omitType"] ? (
                                <Typography>
                                    {`Omit reason: ${row.original["omitType"]}`}
                                </Typography>
                            ) : null}
                        </Typography>
                    </>
                ),
            },
            {
                accessorKey: "unpaidBalance",
                header: "Balance",
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <Typography>
                        {`$ ${formatNumericValue(row.original["unpaidBalance"])}`}
                    </Typography>
                ),
            },
            {
                accessorKey: "monthlyPay",
                header: "Monthly Payment",
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <Typography>
                        {`$ ${formatNumericValue(row.original["monthlyPay"])}`}
                    </Typography>
                ),
            },
            {
                accessorKey: "monthsLeft",
                header: "Months Left",
                enableColumnActions: false,
            },
            {
                accessorKey: "accountNo",
                header: "Account No",
                enableColumnActions: false,
            },
        ],
        []
    );




    const tableColumnExpense = useMemo(
        () => [


            {
                accessorKey: "liabilityType",
                header: "Liability Type",
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <>
                        <Typography>

                            {renderLiabilityType(row.original["liabilityType"])}
                        </Typography><br />
                        {row.original["omitType"] ? (
                            <Typography color={'A7.main'} variant='body2'>
                                {`Omit reason: ${row.original["omitType"]}`}
                            </Typography>
                        ) : null}
                    </>
                ),

            },
            {
                accessorKey: "monthlyPay",
                header: "Monthly Payment",
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <Typography>

                        {`$ ${formatNumericValue(row.original["monthlyPay"])}`}
                    </Typography>
                ),
            },
            {
                accessorKey: "monthsLeft",
                header: "Months Left",
                enableColumnActions: false,
            },
        ],
        []
    );

    const [editState, setEditState] = useState(() => {
        return null
    })


    // Edit table
    useEffect(() => {
        let list
        if (value == LOAN_CREDIT_TYPE_MORTGAGE) {
            list = _.cloneDeep(loanMortgageLiabilityData)
        }
        if (value == LOAN_CREDIT_TYPE_NON_MORTGAGE) {
            list = _.cloneDeep(loanNonMortgageLiabilityData)
        }
        if (value == LOAN_CREDIT_TYPE_EXPENSE) {
            list = _.cloneDeep(loanExpenseData)
        }
        if (editState != null) {
            let editItem = list.find((item, i) => i == editState)
            setSecond(editItem)
            setModalOpen(true)
        }
    }, [editState])

    const [deleteState, setDeleteState] = useState(() => {
        return null;
    })

    //Delete

    useEffect(() => {
        if (deleteState != null) {
            if (value == LOAN_CREDIT_TYPE_MORTGAGE) {
                loanMortgageLiabilityData.splice(deleteState, 1)
                setLoanMortgageLiabilityData([...loanMortgageLiabilityData])
            }
            if (value == LOAN_CREDIT_TYPE_NON_MORTGAGE) {
                loanNonMortgageLiabilityData.splice(deleteState, 1)
                setLoanNonMortgageLiabilityData([...loanNonMortgageLiabilityData])
            }
            if (value == LOAN_CREDIT_TYPE_EXPENSE) {
                loanExpenseData.splice(deleteState, 1)
                setLoanExpenseData([...loanExpenseData])
            }

            setDeleteState(null)
        }
    }, [deleteState])





    const [modalOpen, setModalOpen] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);

    const [second, setSecond] = useState();

    const handleClickOpen = () => {
        setSecond([])
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
        setEditState(null)
        setSuccessModalOpen(false)
    };

    const handleCloseDelete = (event, row) => {
        if (event.target.value == 'yes') {
            setDeleteState(row.index)

        }

    }



    const liabilitySubmit = (data) => {

        if (editState != null) {
            if (data?.creditType == LOAN_CREDIT_TYPE_MORTGAGE) {
                loanMortgageLiabilityData[editState] = {
                    ...loanMortgageLiabilityData[editState],
                    ...data
                }
                setLoanMortgageLiabilityData([...loanMortgageLiabilityData])
            }
            if (data?.creditType == LOAN_CREDIT_TYPE_NON_MORTGAGE) {
                loanNonMortgageLiabilityData[editState] = {
                    ...loanNonMortgageLiabilityData[editState],
                    ...data
                }
                setLoanNonMortgageLiabilityData([...loanNonMortgageLiabilityData])
            }
            if (data?.creditType == LOAN_CREDIT_TYPE_EXPENSE) {
                loanExpenseData[editState] = {
                    ...loanExpenseData[editState],
                    ...data
                }
                setLoanExpenseData([...loanExpenseData])
            }
            setEditState(null)
        } else {

            if (data?.creditType == LOAN_CREDIT_TYPE_MORTGAGE) {
                loanMortgageLiabilityData.push(data);
                setLoanMortgageLiabilityData([...loanMortgageLiabilityData])
            }
            if (data?.creditType == LOAN_CREDIT_TYPE_NON_MORTGAGE) {
                loanNonMortgageLiabilityData.push(data);
                setLoanNonMortgageLiabilityData([...loanNonMortgageLiabilityData])
            }
            if (data?.creditType == LOAN_CREDIT_TYPE_EXPENSE) {
                loanExpenseData.push(data);
                setLoanExpenseData([...loanExpenseData])
            }
        }

        handleClose()
    }




    const handleSubmit = async () => {
        const apiCallData = {
            page: {
                mortgage: loanMortgageLiabilityData,
                nonMortgage: loanNonMortgageLiabilityData,
                expense: loanExpenseData,
            },
            formId: formValue?.id,
            isComplete: true
        }

        const updateState = () => {
            setFormValue({
                ...formValue,
                liabilities: {
                    ...formValue.liabilities,
                    page: {
                        mortgage: loanMortgageLiabilityData,
                        nonMortgage: loanNonMortgageLiabilityData,
                        expense: loanExpenseData,
                    },
                    isComplete: true,
                }
            })
            route(location.pathname.replace(/[^/]*$/, 'documents/guided-document'));
        }

        await postApiCallDynamicWithOutReturn(
            {
                data: apiCallData,
                path: API.liabilities,
                header: UPDATE,
                refresh: updateState
            }

        )

    }



    return (
        <>
            <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                <Box>

                    <Typography variant='h3' mb={2} mt={2}>{`Liabilities`}</Typography>
                    <Typography variant='body1' mb={2}>
                        {`
                        The purpose of collecting this information is to make sure that every individual will be treated fairly
                        `}
                    </Typography>

                    <Box>
                        <TabContext value={value}>
                            <Stack direction={{ xs: 'column', md: 'row' }} justifyContent={'space-between'}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} minWidth={{ xs: 'inherit', md: '50%' }}>
                                    <TabList onChange={handleChange} variant='scrollable' allowScrollButtonsMobile >
                                        <Tab label="Mortgage Liability" value={LOAN_CREDIT_TYPE_MORTGAGE} />
                                        <Tab label="Non-Mortgage Liability" value={LOAN_CREDIT_TYPE_NON_MORTGAGE} />
                                        <Tab label="Expense" value={LOAN_CREDIT_TYPE_EXPENSE} />
                                    </TabList>
                                </Box>
                                <Box my={{ xs: 2, md: 1 }}>
                                    <Button variant='contained' onClick={handleClickOpen}>{`Add Liability`}</Button>
                                </Box>
                            </Stack>

                            <TabPanel value={LOAN_CREDIT_TYPE_MORTGAGE} sx={{ paddingX: 0, paddingY: 1 }}>
                                <CustomReactMaterialTable
                                    staticLoad={true}
                                    staticData={loanMortgageLiabilityData}
                                    columns={tableColumnMortgageLiability}
                                    enablePagination={false}
                                    enableBottomToolbar={false}
                                    enableSorting={false}
                                    enableToolbarInternalActions={false}
                                    enableTopToolbar={false}
                                    enableRowActions={true}
                                    renderRowActions={
                                        ({ row }) => {
                                            const rowMode = row.original?.mode;
                                            return (
                                                <Stack direction={'row'} mb={4.5}>
                                                    <IconButton onClick={() => { setEditState(row.index) }}>
                                                        <KSPencilAltIcon />
                                                    </IconButton>
                                                    {rowMode === 'Manual Entry' ?
                                                        <ConfirmationModel
                                                            body={<IconButton
                                                                id={row.index} onClick={() => {

                                                                }}>
                                                                <KSTrashIcon />
                                                            </IconButton>}

                                                            buttonName={`Delete`}

                                                            title={`Are you sure you want delete this?`}
                                                            handleSubmit={(event) => { handleCloseDelete(event, row) }}
                                                        />
                                                        : null}
                                                </Stack>
                                            )
                                        }
                                    }
                                />
                            </TabPanel>
                            <TabPanel value={LOAN_CREDIT_TYPE_NON_MORTGAGE} sx={{ paddingX: 0, paddingY: 1 }}>
                                <CustomReactMaterialTable
                                    staticLoad={true}
                                    staticData={loanNonMortgageLiabilityData}
                                    columns={tableColumnNonMortgageLiability}
                                    enablePagination={false}
                                    enableBottomToolbar={false}
                                    enableSorting={false}
                                    enableToolbarInternalActions={false}
                                    enableTopToolbar={false}

                                    enableRowActions={true}
                                    renderRowActions={

                                        ({ row }) => {
                                            const rowMode = row.original?.mode;
                                            return (
                                                <Stack direction={'row'}>
                                                    <IconButton onClick={() => { setEditState(row.index) }}>
                                                        <KSPencilAltIcon />
                                                    </IconButton>
                                                    {rowMode === 'Manual Entry' ?
                                                        <ConfirmationModel
                                                            body={<IconButton
                                                                id={row.index} onClick={() => {

                                                                }}>
                                                                <KSTrashIcon />
                                                            </IconButton>}

                                                            buttonName={`Delete`}
                                                            title={`Are you sure you want delete this?`}
                                                            handleSubmit={(event) => { handleCloseDelete(event, row) }}

                                                        /> : null
                                                    }
                                                </Stack>
                                            )
                                        }
                                    }
                                />
                            </TabPanel>
                            <TabPanel value={LOAN_CREDIT_TYPE_EXPENSE} sx={{ paddingX: 0, paddingY: 1 }}>
                                <CustomReactMaterialTable
                                    staticLoad={true}
                                    staticData={loanExpenseData}
                                    columns={tableColumnExpense}
                                    enablePagination={false}
                                    enableBottomToolbar={false}
                                    enableSorting={false}
                                    enableToolbarInternalActions={false}
                                    enableTopToolbar={false}
                                    // renderTopToolbarCustomActions={
                                    //     () => (
                                    //         <Button variant='outlined' onClick={handleClickOpen}>Add</Button>
                                    //     )
                                    // }
                                    enableRowActions={true}
                                    renderRowActions={
                                        ({ row }) => {
                                            const rowMode = row.original?.mode;
                                            return (

                                                <Stack direction={'row'}>
                                                    <IconButton onClick={() => { setEditState(row.index) }}>
                                                        <KSPencilAltIcon />
                                                    </IconButton>
                                                    {rowMode === 'Manual Entry' ?
                                                        <ConfirmationModel
                                                            body={<IconButton
                                                                id={row.index} onClick={() => {

                                                                }}>
                                                                <KSTrashIcon />
                                                            </IconButton>}

                                                            buttonName={`Delete`}
                                                            title={`Are you sure you want delete this?`}
                                                            handleSubmit={(event) => { handleCloseDelete(event, row) }}
                                                        />
                                                        : null}
                                                </Stack>
                                            )
                                        }
                                    }
                                />
                            </TabPanel>
                        </TabContext>
                    </Box>

                </Box>

                <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Link to={'../credit-report'}>
                            <Button startIcon={<ArrowBackIos />}>
                                Previous
                            </Button>
                        </Link>
                        <Link onClick={handleSubmit}>
                            <Button endIcon={<ArrowForwardIos />} >
                                Save and Next
                            </Button>
                        </Link>
                    </Stack>
                </Box>
            </Stack>


            <DialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}

                title={`Add New Mortgage Liability`}
                body={
                    <LiabilitiesForm
                        hasPartner={formValue?.hasPartner}
                        onSubmit={liabilitySubmit}
                        editItem={second}
                        handleClose={handleClose}
                        value={value}
                        realEstate={formValue?.realEstate?.page}
                    />
                }
            />


            <DialogCommonHooks
                show={successModalOpen}
                handleClose={handleClose}
                body={
                    <DialogSuccesMsg
                        description={`The Mortageg Liability has been omitted successfully`}
                        handleClose={handleClose}
                    />
                }
            />
        </>
    )
}
