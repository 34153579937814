import React, { useState, useEffect, useContext } from 'react';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, Grid } from '@mui/material';
import { FindInPageOutlined as FindInPageOutlinedIcon, CloudDownloadOutlined as CloudDownloadOutlinedIcon, EmailOutlined as EmailOutlinedIcon } from '@mui/icons-material';
import axios from 'axios';
import {
  ViewInvoiceIcon,
  DownloadInvoiceIcon,
  MailInvoiceIcon
} from "../../../icons/custome-icon";
//import { BillContext } from "./BillContext";
import { API } from '../../../services/api-config'
import CloseIcon from '@mui/icons-material/Close';


function PastInvoice({ userCode }) {

  // const { isNewUser, userCode } = useContext(BillContext);

  // let userCode;
  const [pastInvoices, setPastInvoices] = useState([]);
  const [previewInvoice, setPreviewInvoice] = useState([]);
  const [showCloseButton, setShowCloseButton] = useState(false);
  //const BASE_URL = "https://stage.kramasoft.com/ks-bill/api/v1/invoice";
  const BASE_URL = API.billInvoice
  const PAST_INVOICE_URL = "past-month-invoice?userCode=";
  const PAST_INVOICE_ID = userCode;  // With Coupon
  //const PAST_INVOICE_ID = "52fqo9bknq_1676555997907";  // With Coupon
  const PAST_INVOICE_API_URL = `${BASE_URL}/${PAST_INVOICE_URL}${PAST_INVOICE_ID}`;

  useEffect(() => {
    // Fetch data from the API using Axios
    console.log("Past Invoice Url: ", PAST_INVOICE_API_URL);
    axios.get(PAST_INVOICE_API_URL)
      .then(response => setPastInvoices(response.data))
      .catch(error => console.error('Error fetching invoices:', error));
  }, []);

  //**********************added by punit  download bill*/
  const handleDownloadPDF = (invoiceNumber) => {
    if (invoiceNumber) {
      const PDF_GENERATE_URL = `${API.downloadInvoice}/generatePdf?invoiceNumber=${invoiceNumber}`;
      axios.get(PDF_GENERATE_URL, { responseType: 'blob' })
        .then((response) => {
          // Create a Blob from the PDF data received
          const blob = new Blob([response.data], { type: 'application/pdf' });
          // Create a link element to download the PDF
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute('download', 'invoice.pdf');
          document.body.appendChild(link);
          link.click();
          // Cleanup
          document.body.removeChild(link);
        })
        .catch((error) => {
          // Handle error in PDF generation
          console.error("Error generating PDF:", error);
        });
    }
  };
  //*****************************end */

  //**********************added by punit  preview bill*/
  const handlePreviewInvoice = (invoiceNumber) => {
    if (invoiceNumber) {
      const PDF_GENERATE_URL = `${API.downloadInvoice}/generatePdf?invoiceNumber=${invoiceNumber}`;
      axios.get(PDF_GENERATE_URL, { responseType: 'blob' })
        .then((response) => {
          // Create a Blob from the PDF data received
          const blob = new Blob([response.data], { type: 'application/pdf' });
          // Create a link element to download the PDF
          const imageUrl = URL.createObjectURL(blob);
          setPreviewInvoice(imageUrl);
          setShowCloseButton(true);
        })
        .catch((error) => {
          // Handle error in PDF generation
          console.error("Error generating PDF:", error);
        });
    }
  };
  const handleClosePreview = () => {
    // Hide or remove preview PDF element
    setPreviewInvoice(null);
    // Hide close button
    setShowCloseButton(false);
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  //*****************************end */
  return (
    <Grid container item xs={12} sm={12} md={12} justifyContent="center" marginLeft={0}>
      {pastInvoices.length > 0 ? (
        <Grid item xs={12} sm={12} md={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Invoice Month</TableCell>
                  <TableCell>Due Date</TableCell>
                  <TableCell>Paid Date </TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pastInvoices.map(invoice => (
                  <TableRow key={invoice.invoiceNumber}>
                    <TableCell>{invoice.monthYear}</TableCell>
                    <TableCell>{formatDate(invoice.dueDate)}</TableCell>
                    {/*} <TableCell>{invoice.dueDate}</TableCell>*/}
                    <TableCell>{invoice.invoicePaymentTime ? `${invoice.invoicePaymentTime}` : "-"} </TableCell>
                    <TableCell>{invoice.invoicePaymentStatus ? `${invoice.invoicePaymentStatus}` : "Pending"}</TableCell>
                    <TableCell>${invoice.netTotal}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => handlePreviewInvoice(invoice.invoiceNumber)}
                      >
                        <ViewInvoiceIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDownloadPDF(invoice.invoiceNumber)}
                      >
                        <DownloadInvoiceIcon />
                      </IconButton>
                      {/* <IconButton>
                        <MailInvoiceIcon />
                        </IconButton>*/}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : (
        <Grid item xs={12} sm={12} md={6}>
          <p>No invoices available.</p>
        </Grid>
      )}
      {/* Render PDF preview */}
      {previewInvoice && (
        <Grid item xs={12} sm={12} md={12}>
          <div style={{ position: 'relative' }}>
            {showCloseButton && (
              <IconButton
                style={{ position: 'absolute', top: 0, right: 0, zIndex: 1000, color: "blue" }}
                onClick={handleClosePreview}
              >
                <CloseIcon />
              </IconButton>
            )}
            <embed src={previewInvoice} type="application/pdf" width="100%" height="500px" />
          </div>
        </Grid>
      )}
    </Grid>
  );
}

export default PastInvoice;
