import React from "react";
import "./not-found.css";

const NotFound = () => (
  <div className="NotFound">
    <h3>Sorry, page not found!</h3>
  </div>
);

export default NotFound;
