import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoanStatusTab from "../loan-status-tab";
import { loanMenuMetaData } from "./loan-menu";

export default function LoanSideBar({
  formData,
  profileData,
  mobileNvbarClose,
}) {
  const location = useLocation();
  const activeTab =
    location.pathname.split("/")[location.pathname.split("/").length - 1];
  const activeChildTab =
    location.pathname.split("/")[location.pathname.split("/").length - 2];

  const [loanMenu, setloanMenu] = useState([]);
  useEffect(() => {
    setloanMenu(
      loanMenuMetaData({ partner: formData?.hasPartner, formData: formData, role: profileData?.role })
    );
  }, [formData?.hasPartner, window.location.pathname]);

  return (
    <>
      {loanMenu
        .filter((obj) => Object.keys(obj).length !== 0)
        .map((value) => (
          <>
            <LoanStatusTab
              key={value.tabName}
              tabName={value.tabName}
              pageHref={value.pageHref}
              tabActive={activeTab == value?.pageHref}
              tabComplete={value.tabComplete}
              tabProgress={value.tabProgress}
              inProgress={value.inProgress}
              isComplete={value?.isComplete}
              currentFormPanel={formData?.currentFormPanel}
              isLocked={formData?.isLocked}
              role={profileData?.role}
              mobileNvbarClose={mobileNvbarClose}
            />
            {value.child
              ?.filter((obj) => Object.keys(obj).length !== 0)
              .map((item) => (
                <LoanStatusTab
                  key={item.tabName}
                  tabName={item.tabName}
                  pageHref={value.pageHref + "/" + item.pageHref}
                  tabActive={
                    activeTab == item?.pageHref &&
                    activeChildTab == value?.pageHref
                  }
                  tabComplete={item.tabComplete}
                  tabProgress={item.tabProgress}
                  tabChild={true}
                  inProgress={value.inProgress}
                  isComplete={item?.isComplete}
                  currentFormPanel={formData?.currentFormPanel}
                  isLocked={formData?.isLocked}
                  role={profileData?.role}
                  mobileNvbarClose={mobileNvbarClose}
                />
              ))}
          </>
        ))}
    </>
  );
}
