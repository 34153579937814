import {
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import React from "react";
import ErrorIcon from "@mui/icons-material/Error";

const styles = {
  root: {
    "& div.Mui-disabled": {
      background: "#dddddd70",
    },
    "& input:disabled": {
      "-webkit-text-fill-color": "black",
      cursor: "not-allowed",
    },
  },
};
function CustomInput({
  label,
  labelRightComponent,
  sicon, // prefix Icon
  eicon, // postfixs Icon
  description = null,
  change,
  placeholder,
  size = "small",
  type = "text",
  pattern,
  labelSize,
  maxDate,
  minDate,
  helperText,
  error = null,
  disabled = false,
  readOnly = false,
  maxLength = 500,
  boxProps,
  required,
  marginBottom = 3,
  marginTop = 1,
  onNextLine,

  ...props
}) {
  const errorMessageFontSize = 14;
  return (
    <Box {...boxProps} mb={marginBottom} mt={marginTop}>
      <Stack direction={onNextLine ? "column" : "row"} justifyContent={"space-between"} alignItems={'center'}>
        <InputLabel style={{ fontSize: labelSize }}>
          {label}
          {required ? (
            <Typography variant="small" color="error">
              *
            </Typography>
          ) : null}
        </InputLabel>
        <InputLabel>{labelRightComponent}</InputLabel>
      </Stack>
      {description ? (
        <Typography>{description}</Typography>
      ) : (
        <TextField
          {...props}
          disabled={disabled}
          type={type}
          size={size}
          fullWidth
          placeholder={placeholder}
          {...change}
          InputProps={{
            inputProps: {
              maxLength: maxLength,
              pattern: pattern,
              max: maxDate,
              min: minDate,
              step: "any",
            },
            readOnly: readOnly,
            endAdornment:
              error && props?.id !== "password" && (
                <ErrorIcon color="error" />
              ) || eicon && (
                <InputAdornment position="end" >{eicon}</InputAdornment>
              ),
            startAdornment: sicon ? (
              <InputAdornment position="start">{sicon}</InputAdornment>
            ) : null,
          }}
          {...(error && {
            error: true,
            //   helperText: error 
            helperText: (
              <Typography variant="body2" color="error" style={{ fontSize: errorMessageFontSize }}>
                {error}
              </Typography>
            )

          })}
        />
      )}


    </Box>
  );
}
export default withStyles(styles)(CustomInput);
