import React, { useEffect } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { Box, Button, Stack, Typography } from '@mui/material'

import CustomInput from '../../../helpers/custom-input'
import { PropertyAddress } from '../property-address'
import { ContactAdditionalValidation } from '../../../constants/validation-rules'
import CustomRadioChip from '../../../helpers/radio-chip'
import { AttachMoney } from "@mui/icons-material";

import { formatNumericValue, parseNumericValue } from '../../../helpers/numeric-format'
import {
    OWNERSHIP_STATUS
} from '../../../constants/constatnt-variable'

export default function AdditionalAddressForm({ onSubmit, editItem, handleClose }) {

    const { control, setValue, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            addressLine1: editItem?.addressLine1,
            addressLine2: editItem?.addressLine2,
            unit: editItem?.unit,
            city: editItem?.city,
            stateOfCountry: editItem?.stateOfCountry ? editItem?.stateOfCountry : null,
            country: editItem?.country ? editItem?.country : null,
            zip: editItem?.zip,
            year: editItem?.year,
            month: editItem?.month,
            ownerShipType: editItem?.ownerShipType,
            rentAmount: editItem?.rentAmount
        }
    })

    const { country = null, state = null, ownerShipType, rentAmount } = useWatch({ control })

    useEffect(() => {
        if (ownerShipType === "Own" || ownerShipType === "No Primary Housing Expenses")

            setValue('rentAmount', null);

    }, [ownerShipType]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box width={'100%'} my={2} >

                <PropertyAddress control={control} Controller={Controller} stateOfCountry={state} country={country} errors={errors} />

                <Typography variant='body1'>Period of Stay</Typography>
                <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 0, md: 2 }}>
                    <Box width={'100%'}>
                        <Controller
                            name='year'
                            control={control}
                            rules={ContactAdditionalValidation.year}
                            render={({ field }) => (
                                <CustomInput {...field} type={'number'} label={'Year'} required error={errors?.year?.message} />
                            )}
                        />
                    </Box>
                    <Box width={'100%'}>
                        <Controller
                            name='month'
                            control={control}
                            rules={ContactAdditionalValidation.month}
                            render={({ field }) => (
                                <CustomInput {...field} type={'number'} label={'Month'} required error={errors?.month?.message} />
                            )}
                        />
                    </Box>
                </Stack>

                <Controller
                    name='ownerShipType'
                    control={control}
                    rules={ContactAdditionalValidation.ownerShipType}
                    render={({ field }) => (
                        <CustomRadioChip
                            {...field}
                            field={field}
                            state={ownerShipType}
                            required
                            label={'Ownership Type'}
                            data={OWNERSHIP_STATUS}
                            error={errors?.ownerShipType?.message}
                        />
                    )}
                />
                {ownerShipType == "Rent" ?
                    <Controller
                        name="rentAmount"
                        control={control}
                        rules={ContactAdditionalValidation.rentAmount}
                        render={({ field }) => (
                            <CustomInput {...field}
                                type={'text'}
                                label={'Rent Amount'}
                                required
                                error={errors?.rentAmount?.message}
                                value={formatNumericValue(field.value)}
                                onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                sicon={<AttachMoney />} />
                        )}
                    />
                    : null}

                <Stack direction={'row-reverse'} gap={2} >
                    <Button type='submit' variant='contained' >
                        {editItem.length !== 0 ? 'Update' : 'Add'}
                    </Button>
                    <Button variant='text' onClick={handleClose}>Cancel</Button>
                </Stack>
            </Box>
        </form>
    )
}
