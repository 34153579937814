import { ArrowBackIos, ArrowForwardIos, NavigateNext } from '@mui/icons-material'
import { Box, Breadcrumbs, Button, FormHelperText, Link as MLink, Stack, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { DialogCommonHooks } from '../../../../../../helpers/modal/modal'
import useInput from '../../../../../../helpers/use-input'
import { useRecoilState } from 'recoil'
import { formState } from '../../../../../../recoil/profile-atom'
import { useForm } from 'react-hook-form'
import AdditionalIncome from './additional-income'

import { API } from '../../../../../../services/api-config'
import { postApiCallDynamicWithOutReturn } from '../../../../../../services/api-service'
import { UPDATE } from '../../../../../../constants/constatnt-variable'
import { getMonthsBetweenDates } from '../../../../../../helpers/time-formater'

export default function LoanAdditionalIncome() {


    let [formValueId, setFormValueId] = useState(() => null);

    const route = useNavigate()
    const location = useLocation();
    const partnerInfoPath = location.pathname.split("/")[location.pathname.split("/").length - 2]


    let [formValue, setFormValue] = useRecoilState(formState)




    const [modalOpen, setModalOpen] = useState(false);


    let [income, setIncome] = useState([]);


    useEffect(() => {

        const initialIncome = partnerInfoPath === "partner-info"
            ? _.cloneDeep(formValue?.partnerInfo?.page?.additionalIncome?.subPage?.additionalIncome ?? [])
            : _.cloneDeep(formValue?.borrowerInfo?.page?.additionalIncome?.subPage?.additionalIncome ?? []);

        setIncome(initialIncome);
    }, [partnerInfoPath, formValue]);






    const onSubmit = async (data, event) => {

        if (event.target.id == "main") {

            const apiCallData = {
                page: {
                    subPage: {
                        additionalIncome: income,
                    },
                    isComplete: true
                },
                formId: formValue.id,
                isComplete: true
            }

            let updateState, path

            if (partnerInfoPath == "partner-info") {
                path = API.partnerInfoAdditionalIncome
                updateState = {
                    ...formValue,
                    partnerInfo: {
                        ...formValue.partnerInfo,
                        page: {
                            ...formValue.partnerInfo.page,
                            additionalIncome: apiCallData.page
                        }
                    }

                }

            } else {
                path = API.borrowerInfoAdditionalIncome
                updateState = {
                    ...formValue,
                    borrowerInfo: {
                        ...formValue.borrowerInfo,
                        page: {
                            ...formValue.borrowerInfo.page,
                            additionalIncome: apiCallData.page
                        }
                    }

                }

            }



            const additionalIncomeInfoUpdateInRecoil = () => {
                setFormValue(updateState)
                route(location.pathname.replace(/[^/]*$/, 'military-service'));
            }

            await postApiCallDynamicWithOutReturn(
                {
                    data: apiCallData,
                    path: path,
                    header: UPDATE,
                    refresh: additionalIncomeInfoUpdateInRecoil
                }

            )


        }


    }



    const { setValue, control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            additionalIncome: income,
        }

    })

    return (
        <>
            <form key={'main'} id='main' onSubmit={handleSubmit(onSubmit)} >
                <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} minHeight={'calc(100vh - 200px)'} overflow={'auto'}>
                    <Box>
                        <Breadcrumbs aria-label="breadcrumb"
                            separator={<NavigateNext fontSize="small" />}
                        >
                            <MLink fontWeight={700} underline="hover" color="inherit" href="#">
                                {`${partnerInfoPath == "partner-info" ? "Co-Borrower" : "Borrower"} Info`}
                            </MLink>
                            <Typography color="primary.main" fontWeight={700}>Additional Income</Typography>
                        </Breadcrumbs>
                        <Box maxWidth={{ xs: '100%', md: '100%' }} mt={2}>

                            <AdditionalIncome setValue={setValue} incomeList={income} setIncomeList={setIncome} />




                        </Box>
                    </Box>
                    <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Link to={'../employment'}>
                                <Button startIcon={<ArrowBackIos />}>
                                    Previous
                                </Button>
                            </Link>
                            <Button form='main' type='submit' endIcon={<ArrowForwardIos />} >
                                Save and Next
                            </Button>
                        </Stack>
                    </Box>

                </Stack>
            </form>

        </>
    )
}
