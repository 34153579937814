import { Box, Button, Checkbox, FormControlLabel, FormHelperText, Stack, Typography } from '@mui/material'
import React from 'react'
import { useRecoilValue } from 'recoil';
import { ERROR_MESSAGE } from '../../constants/constatnt-variable'
import { profileState } from '../../recoil/profile-atom';

export default function LoanDeclarationsCheck({ onSumbit, handleSubmit, Controller, control, checkbox = false, errors }) {
    let profileInfo = useRecoilValue(profileState);

    const data = [
        `I/We declare that the information that I/we have provided in connection with my/our application for a CliQLoan through ${profileInfo.companyName} is true and accurate.`,
        `I/we agree that the representatives of ${profileInfo.companyName} may make any checks that they feel necessary regarding this information in order to help make a decision on the application for finance.`,
        `I/We authorize ${profileInfo.companyName} to discuss and exchange any appropriate information (including copies of documentation verifying my identity, address, and bank details) with the CliQLoan Company in relation to my loan application.`,
        `I/We agree that representatives of ${profileInfo.companyName}} may carry out searches against me at credit reference agencies and agree and acknowledge that details of such searches will be added to the credit reference agencies records about me and may be seen by other organizations making searches. I/We also agree and acknowledge that information held about me by credit reference agencies may be linked to records relating to any person with whom I am/we are linked financially.`,
        `We will make checks such as; assessing this application for credit and verifying identities to prevent and detect crime and money laundering. We may also make periodic searches at CRAs and FPAs to manage your account with us.`,
        `I / we understand that all information relating to this loan application provided by me/us to ${profileInfo.companyName} will be treated as commercially confidential and handled in accordance with the data protection principles laid down in the Data Protection Act 1998. With this understanding:`
    ]

    const list = [
        `I / we authorize ${profileInfo.companyName} to disclose any relevant and necessary information relating to me/us or this application to any third party (including credit reference agencies, and partner organizations)`,
        `I / we authorize ${profileInfo.companyName} to obtain any information concerning my/our business and/or financial affairs from any other person providing financial facilities to me/us.`,
        `I/we authorize ${profileInfo.companyName} and the CliQLoan to contact me/us by telephone, mail or the internet about other services that ${profileInfo.companyName} or its partner organizations currently or may in the future provide, or to undertake research.`,
        `I/we agree to provide updates to this information when requested by ${profileInfo.companyName} to satisfy the needs of project sponsors and the requirements of the Data Protection Act.`
    ]
    return (

        <form onSubmit={handleSubmit(onSumbit)}>
            <Box width={'100%'} my={2} overflow={'auto'}>
                {data.map((item, i) => {
                    return (
                        <Typography color={'#000000'} key={i} mb={3} variant={'inherit'} textAlign={'justify'}>
                            {item}
                        </Typography>
                    )
                })
                }
                <ul>
                    {list.map((item, i) => {
                        return (
                            <li style={{ color: '000000' }} key={i}>
                                <Typography color={'#000000'} variant={'inherit'} textAlign={'justify'}> {item}</Typography>
                            </li>
                        )
                    })}
                </ul>
                <Typography color={'#000000'} mb={3} variant={'inherit'} textAlign={'justify'}>
                    If you give us false or inaccurate information and we suspect or identify fraud we will record this and may also pass this information to FPAs and other organizations involved in crime and fraud prevention.
                </Typography>
                <FormControlLabel
                    control={
                        <Controller
                            name='checkbox'
                            control={control}
                            rules={{ required: { value: true, message: ERROR_MESSAGE.required } }}
                            render={({ field }) => (
                                <Checkbox defaultChecked={checkbox} {...field} />
                            )}
                        />}
                    style={{ color: '#000000' }}
                    label={`I/We Agree to consent to do business electronically and agree to the processing of my personal information as set out.`}
                />
                {errors.checkbox && (
                    <FormHelperText error>{errors.checkbox.message}</FormHelperText>
                )}
                <Stack direction={'row-reverse'} gap={2} >
                    {/* <Link to={'../dependent'}> */}
                    <Button type='submit' variant='contained' >{`I Agree`}</Button>
                    {/* </Link> */}
                </Stack>
            </Box>
        </form>

    )
}