import { AttachMoney } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import {
    GIFT_STATUS,
    GIFT_ASSET_TYPE,
    GIFT_SETTLEMENT_TYPE,
    GIFT_SOURCE_TYPE
} from '../../../../../constants/constatnt-variable'
import CustomInput from '../../../../../helpers/custom-input'
import CustomDropDown from '../../../../../helpers/dropdown/custom-dropdown'
import CustomLargeLabel from '../../../../../helpers/large-label'
import CustomRadioChip from '../../../../../helpers/radio-chip'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { GiftAndGrantsValidation } from '../../../../../constants/validation-rules'
import { PropertyAddress } from '../../../../../components/loan-application/property-address'
import { formatNumericValue, parseNumericValue } from '../../../../../helpers/numeric-format'

import {
    TYPES_OF_LOAN,
} from "../../../../../constants/constatnt-variable";
import { LoanDetailsValidation } from "../../../../../constants/validation-rules";
import { useEffect } from 'react'


export function GiftAndGrantsForm({ onSubmit, editItem, hasPartner }) {

    const { control, handleSubmit, setValue, formState: { errors } } = useForm({
        defaultValues: {
            giftStatus: editItem?.giftStatus,
            assetType: editItem?.assetType,
            settlementType: editItem?.settlementType,
            sourceType: editItem?.sourceType,
            marketValue: editItem?.marketValue,

        }
    })

    const { giftStatus, assetType = null, settlementType = null, sourceType = null } = useWatch({ control })







    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <Box width={'100%'} my={2} >
                <Box>



                    <Controller
                        name='assetType'
                        control={control}
                        rules={GiftAndGrantsValidation.assetType}
                        render={({ field }) => (
                            <CustomDropDown
                                {...field}
                                field={field}
                                label={'Asset Type'}
                                required
                                state={assetType}
                                option={GIFT_ASSET_TYPE}
                                error={errors?.assetType?.message}
                            />
                        )}
                    />



                    <Controller
                        name='settlementType'
                        control={control}
                        rules={GiftAndGrantsValidation.settlementType}
                        render={({ field }) => (
                            <CustomDropDown
                                {...field}
                                field={field}
                                label={'Deposited/Not Deposited'}
                                required
                                state={settlementType}
                                option={GIFT_SETTLEMENT_TYPE}
                                error={errors?.settlementType?.message}
                            />

                        )}
                    />

                    <Controller
                        name='sourceType'
                        control={control}
                        rules={GiftAndGrantsValidation.sourceType}
                        render={({ field }) => (
                            <CustomDropDown
                                {...field}
                                field={field}
                                label={'Source'}
                                required
                                state={sourceType}
                                option={GIFT_SOURCE_TYPE}
                                error={errors?.sourceType?.message}
                            />
                        )}
                    />



                    <Controller
                        name='marketValue'
                        control={control}
                        rules={GiftAndGrantsValidation.marketValue}
                        render={({ field }) => (
                            <CustomInput {...field}
                                type={'text'}
                                label={'Cash or Market Value'}
                                required
                                sicon={<AttachMoney />}
                                value={formatNumericValue(field.value)}
                                onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                error={errors?.marketValue?.message} />
                        )}
                    />



                    {/* </> : null} */}
                </Box>
                <Box textAlign={'right'}>
                    <Button type='submit' variant='contained' >
                        {editItem.length != 0 ? 'Update' : 'Add'}
                    </Button>
                </Box>
            </Box>
        </form >
    )
}