import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  IconButton,
  Link as MuLink,
  Typography,
} from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import BusinessIcon from '@mui/icons-material/Business';
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

export default function TermCondition({
  open,
  handleClose,
  setTermCondtion,
  termConditon,
  handleClickOpen,
  formik,
}) {
  const data = [
    {
      title: 'License and Use: ',
      subTitle: 'The Company grants the User a non-exclusive, non-transferable license to use the Application for the sole purpose of loan origination, subject to the terms and conditions of this Agreement. The User shall not sublicense, sell, lease, or otherwise transfer the Application to any third party without the prior written consent of the Company.'
    },
    {
      title: 'User Registration: ',
      subTitle: 'The User agrees to provide accurate and complete information during the registration process. The User is responsible for maintaining the confidentiality of their account credentials and for all activities that occur under their account.'
    },
    {
      title: 'Data Privacy: ',
      subTitle: `The Company will handle personal and financial information in accordance with its Privacy Policy, available on the Company's website.The User consents to the collection, use, and disclosure of their information as described in the Privacy Policy.`
    },
    {
      title: 'Security: ',
      subTitle: 'The User is responsible for taking appropriate measures to ensure the security and confidentiality of their data and login credentials. The Company employs industry-standard security measures, but the User acknowledges that no system is completely secure.'
    },
    {
      title: 'Compliance with Laws: ',
      subTitle: 'The User agrees to comply with all applicable laws, regulations, and industry standards related to the use of the Application.'
    },
    {
      title: 'Intellectual Property: ',
      subTitle: 'All intellectual property rights in the Application, including but not limited to copyrights, trademarks, and patents, belong to the Company. The User agrees not to reverse engineer, decompile, or otherwise attempt to derive the source code of the Application. '
    },
    {
      title: 'Support and Maintenance: ',
      subTitle: `The Company will provide reasonable technical support and maintenance for the Application as outlined in the support policy available on the Company's website.`
    },
    {
      title: 'Limitation of Liability: ',
      subTitle: `The Company is not liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of the Application.In no event shall the Company's total liability exceed the amount paid by the User for the use of the Application.`
    },
    {
      title: 'Termination: ',
      subTitle: `The Company reserves the right to terminate the User's access to the Application at its discretion, without prior notice, for any reason, including a breach of these terms.`
    },
    {
      title: 'Amendments: ',
      subTitle: 'The Company reserves the right to update or modify these Terms and Conditions at any time. The User is responsible for regularly reviewing the Agreement'
    }
  ]
  return (
    <>
      <Typography>
        <Checkbox
          style={{ paddingLeft: 0 }}
          sx={
            formik.errors.termConditon
              ? {
                color: "error.main",
              }
              : {}
          }
          checked={termConditon}
          onChange={(e) => {
            setTermCondtion((prev) => !prev);
          }}
        />
        <span>{`By signing up, I accept Kramasoft's `}</span>
        <MuLink
          sx={{ textDecoration: "none", cursor: "pointer" }}
          onClick={handleClickOpen}
        >
          {`Terms & Conditions`}
        </MuLink>
      </Typography>

      <FormHelperText sx={{ color: "error.main", ml: "2" }}>
        {formik.errors.termConditon}
      </FormHelperText>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            backgroundColor: "#2F80ED",
            color: "white",
            fontWeight: "bold",
          }}
        >
          {"Terms & Conditions"}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ mt: "20px" }} style={{ fontSize: '16px', fontWeight: 500 }}>
          <Typography fontWeight={500} id="alert-dialog-description">
            {`These Terms and Conditions ("Agreement") constitute a legal agreement between KRAMASOFT LLC, hereinafter referred to as "the Company," and the user, whether an individual or an entity, hereinafter referred to as "the User." By accessing or using the CliQloan Loan Origination System Application ("the Application"), the User agrees to be bound by the terms and conditions outlined in this Agreement. If the User does not agree to these terms, they should refrain from using the Application.`}
          </Typography>
          {data.map((item, i) => {
            return (
              <p key={i}><span style={{ fontWeight: 600 }}>{item.title} </span>{item.subTitle}</p>
            )
          })}
          <p>By using the Application, the User acknowledges that they have read, understood, and agree to be bound by these Terms and Conditions. If any provision of this Agreement is found to be invalid or unenforceable, the remaining provisions will continue to be valid and enforceable.</p>
          <p>Last Updated: [01/17/2024]</p>
          {/* <strong></strong><p style={{ marginTop: '5px' }}>KRAMASOFT LLC <br /><strong />
            289 Iron point road, Folsom, California - 95630<br />
            (408) 479-2436</p> */}
          <p style={{ marginTop: '5px' }}>
            <strong> <BusinessIcon style={{ fontSize: '1.2rem', marginRight: '0.5rem' }} />KRAMASOFT LLC</strong><br />
            <RoomIcon style={{ fontSize: '1.2rem', marginRight: '0.5rem' }} />289 Iron Point Road, Folsom, California - 95630<br />
            <PhoneIcon style={{ fontSize: '1.2rem', marginRight: '0.5rem' }} /> (408) 479-2436
          </p>
        </DialogContent>
        <DialogActions sx={{ pb: 2, pt: 2 }}>
          <Button
            onClick={() => {
              setTermCondtion(false);
              handleClose();
            }}
            sx={{ borderRadius: "24px" }}
          >
            Decline
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setTermCondtion(true);
              handleClose();
            }}
            sx={{ borderRadius: "24px", fontWeight: "bold", mr: 2 }}
          >
            I Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
