import React, { useState } from "react";
import {
  Button,
  FormHelperText,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  UPDATE,
} from "../../../../constants/constatnt-variable";
import {
  KSCloudDownloadIcon,
  KSCloudUploadIcon,
  KSDocumentSearchIcon,
} from "../../../../icons/custome-icon";
import CustomInput from "../../../../helpers/custom-input";
import { API } from "../../../../services/api-config";
import {
  postApiCallDynamicWithOutReturn,
  uploadFile,
} from "../../../../services/api-service";
import { TimeStampToDateMonth } from "../../../../helpers/time-formater";
import { downloadReportFromServer, documentPreview } from "../../../../helpers/file-upload-download";
import { toastNotify } from "../../../../helpers/alert-msg";
import { ResloveBorrowerValidation } from "../../../../constants/validation-rules";

export default function LoanSummaryTaskResolveBorrowerForm({
  formId,
  taskResolveData,
  handleClose,
  taskReload,
}) {
  const [taskStatus, setTaskStatus] = useState("");

  // For Changing Task Status Start
  const handleBorrowerSubmit = () => {
    setTaskStatus("Review Pending");
  };
  // For Changing Task Status End



  const [borrowerFileID, setBorrowerFileID] = useState(null);
  const [disableResolveTask, setDisableResolveTask] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: taskResolveData?.id,
      formId: formId,
      createdTime: taskResolveData?.createdTime,
      dueDate: taskResolveData?.dueDate,
      priority: taskResolveData?.priority,
      ownerNote: taskResolveData?.ownerNote,
      taskType: taskResolveData?.taskType,
    },
  });

  const resloveOnSubmit = async (data) => {
    data = Object.assign(data, {
      statusInfo: taskStatus,
      formId: formId,
      id: data?.id,
      borrowerDocId: borrowerFileID ? taskResolveData.borrowerDocId : null,
    });

    await postApiCallDynamicWithOutReturn({
      data: data,
      path: API.taskStatus,
      header: taskResolveData ? UPDATE : "",
      refresh: taskReload,
    });

    handleClose();
  };

  const downloadDocument = () => {
    downloadReportFromServer(API.proxyDocumentDownload, {
      id: taskResolveData?.brokerDocId,
      serviceType: "task",
    });
  };


  const previewDocument = () => {

    documentPreview(API.proxyDocumentDownload, {
      id: taskResolveData?.brokerDocId,
      serviceType: "task",
    });

  }

  const fileUpload = (event) => {
    setDisableResolveTask(true)
    let file = event.target.files[0];
    if (event.target.files) {
      uploadFile({
        formId: formId,
        docId: taskResolveData?.borrowerDocId,
        file: file,
        serviceType: "task",
        path: API.proxyDocumentOthersUpload,
      }).then((res) => {
        if (res.status === 201) {
          toastNotify("success", res.data.message);
          setBorrowerFileID(res?.data?.docId);
          setDisableResolveTask(false)
        }
      });
    } else {
      console.log("not found");
    }
  };

  return (
    <>
      <Stack
        direction={{ xs: "column", md: "row" }}
        gap={1}
        justifyContent={"space-between"}
        my={2}
      >
        <Typography color={"A7.main"} variant="body2">
          {`Due on: ${TimeStampToDateMonth(taskResolveData?.createdTime)}`}
        </Typography>
        <Typography color={"A7.main"} variant="body2">
          {`Created on: ${TimeStampToDateMonth(taskResolveData?.dueDate)}`}
        </Typography>
        <Typography color={"A7.main"} variant="body2">
          {`Priority: ${taskResolveData?.priority}`}
        </Typography>
      </Stack>
      <Typography variant="h6" mt={3}>{`Task Description`}</Typography>
      <Typography color={"A7.main"} mb={2}>
        {taskResolveData.description}
      </Typography>
      <Typography variant="h6">{`Broker's Note`}</Typography>
      <Typography color={"A7.main"} mb={2}>
        {taskResolveData.ownerNote
          ? taskResolveData.ownerNote
          : "Not available."}
      </Typography>
      {taskResolveData.taskType == "Download & upload" ? (
        <>
          <Typography variant="h6">{`Is Lender's Condition`}</Typography>
          <Typography color={"A7.main"} mb={2}>
            {taskResolveData.isLender ? "Yes" : "No"}
          </Typography>

          <Stack direction={"row"} gap={1} alignItems={"center"} my={2}>
            <Typography variant="h6">{`Original Document`}</Typography>
            <IconButton onClick={previewDocument}>
              <KSDocumentSearchIcon color={"primary"} />
            </IconButton>
            <Typography color={"primary"}>{taskResolveData.borrowerDocFileName}</Typography>
            <IconButton onClick={downloadDocument}>
              <KSCloudDownloadIcon color={"primary"} />
              <Typography color={"primary"}>{taskResolveData.brokerDocFileName}</Typography>
            </IconButton>
          </Stack>
        </>
      ) : null}

      <form onSubmit={handleSubmit(resloveOnSubmit)}>
        {taskResolveData.taskType == "Download & upload" ||
          taskResolveData.taskType == "Upload" ? (
          <>
            <Controller
              name="borrowerDocId"
              control={control}
              rules={ResloveBorrowerValidation.borrowerDocId}
              render={({ field }) => (
                <>
                  <Stack direction={"row"} gap={1} alignItems={"center"} my={2}>
                    <Typography variant="h6">{`Upload ${taskResolveData.taskType == "Download & upload"
                      ? "Signed "
                      : ""
                      }Document`}</Typography>
                    <IconButton component="label" {...field}>
                      <input
                        onChange={fileUpload}
                        hidden
                        //accept="image/*"
                        type="file"
                      />
                      <KSCloudUploadIcon />
                    </IconButton>
                    <FormHelperText error>
                      {errors?.borrowerDocId?.message}
                    </FormHelperText>
                  </Stack>
                </>
              )}
            />
          </>
        ) : null}
        <Controller
          name="borrowerNote"
          control={control}
          rules={ResloveBorrowerValidation.borrowerNote}
          render={({ field }) => (
            <CustomInput
              {...field}
              field={field}
              required
              type="text"
              label={"Note"}
              error={errors?.borrowerNote?.message}
            />
          )}
        />

        <Stack direction={{ xs: "column", md: "row-reverse" }} gap={2}>
          <Button
            type="submit"
            variant="contained"
            onClick={handleBorrowerSubmit}
            disabled={disableResolveTask}
          >
            {`Submit`}
          </Button>
          <Button variant="text" onClick={handleClose}>
            {`Cancel`}
          </Button>
        </Stack>
      </form>
    </>
  );
}
