import React, {useEffect} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {Path} from '../../routes/route-config'

// MLO website apply Redirecting component
export default function ApplyRedirect() {
    const router = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        if (searchParams.get("profileUri") == null) {
            router(
                Path.landing, {

            })
        }
        else {
            router(
                Path.invitationReg, {
                state: {
                    'website': {
                        "profileUri": searchParams.get("profileUri")
                    }
                }

            })
        }

    }, [])
    return (
        <></>
    )
}
