import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useRecoilState } from 'recoil';
import { DialogCommonHooks } from '../../../../../helpers/modal/modal';
import CustomReactMaterialTable from '../../../../../helpers/table/react-material-table'
import { profileState } from '../../../../../recoil/profile-atom';


export default function PartnerCoBorrowerSet({
    borrowerType,
    data,
    coborrowersPartner,
    partnerData,
    primaryBorrower,
    primaryBorrowerPartner,
    primaryBorrowerPartnerOrigi,
    hasPartner,
    handleSubmit,
    setPage
}) {




    function mergeData(data, coborrowersPartner) {

        const coborrowersMap = new Map();
        coborrowersPartner.forEach(partner => {
            coborrowersMap.set(partner.formId, partner);
        });


        const mergedData = data.map(person => {
            const partner = coborrowersMap.get(person.formId);
            if (partner) {
                return {
                    ...person,
                    lastName: person.lastName + " (CA)",
                    partnerData: {
                        ...partner,
                        lastName: partner.lastName + " (CA-CB)"
                    }
                };
            } else {
                return {
                    ...person,
                    lastName: person.lastName + " (CA)",
                    partnerData: null
                };
            }
        });


        coborrowersPartner.forEach(partner => {
            if (!coborrowersMap.has(partner.formId)) {
                mergedData.push({
                    ...partner,
                    lastName: partner.lastName + " (CA-CB)",
                    partnerData: null
                });
            }
        });


        data.forEach(person => {
            if (!coborrowersMap.has(person.formId) && !mergedData.find(item => item.formId === person.formId)) {
                mergedData.push({
                    ...person,
                    lastName: person.lastName + " (CA)",
                    partnerData: null
                });
            }
        });


        const allFormIds = new Set([...data.map(item => item.formId), ...coborrowersPartner.map(item => item.formId)]);
        allFormIds.forEach(formId => {
            if (!mergedData.find(item => item.formId === formId)) {
                const partner = coborrowersMap.get(formId);
                if (partner) {
                    mergedData.push({
                        ...partner,
                        lastName: partner.lastName + " (CA-CB)",
                        partnerData: null
                    });
                } else {
                    const person = data.find(item => item.formId === formId);
                    if (person) {
                        mergedData.push({
                            ...person,
                            lastName: person.lastName + " (CA)",
                            partnerData: null
                        });
                    }
                }
            }
        });

        return mergedData;
    }



    let merged;
    if (borrowerType !== 'Primary') {
        coborrowersPartner = [];
        coborrowersPartner.push(primaryBorrowerPartner);
        //merged = mergeData(data, coborrowersPartner); 
        const newData = data.map(obj => ({ ...obj, formId: 34 }));
        const newCoborrowersPartner = coborrowersPartner.map(obj => ({ ...obj, formId: 34 }));
        merged = mergeData(newData, newCoborrowersPartner);


    }


    else {
        merged = mergeData(data, coborrowersPartner);
    }


    let newData = _.unionBy(data.map(item => ({ ...item, initials: '(CA' })), coborrowersPartner.map(item => ({ ...item, initials: 'CA-CB' })), 'id');
    newData = _.cloneDeep(newData).map((v, index) => ({ ...v, initials: `${v.initials} ${index + 1})` }));


    primaryBorrower = {
        ...primaryBorrower,
        partnerData: {
            ...primaryBorrowerPartner,
            lastName: `${primaryBorrowerPartner?.lastName} (PB-CB)`
        }
    };

    if (borrowerType !== 'Primary') {

        primaryBorrower = {
            ...primaryBorrower,
            partnerData: {
                ...primaryBorrowerPartnerOrigi,
                lastName: primaryBorrowerPartnerOrigi?.lastName
            }
        };



    }



    let primaryBorrowerList = [primaryBorrower];


    let coborrowerList = _.cloneDeep(newData)


    if (borrowerType !== 'Primary' && primaryBorrowerPartner.firstName !== null) {
        let modifiedPrimaryBorrowerPartner = Object.assign({}, primaryBorrowerPartner);
        let initials = "(CA-CB)";
        modifiedPrimaryBorrowerPartner.initials = initials;
        coborrowerList.push(modifiedPrimaryBorrowerPartner);
    }


    if (borrowerType === 'Primary' && coborrowersPartner !== null) {
        coborrowerList = _.unionBy(coborrowerList, coborrowersPartner)
    }



    let list = _.unionBy(primaryBorrowerList, merged)


    let [profileData, setProfileData] = useRecoilState(profileState);





    let coBorrowerNamesForConfirm = data.map(a => " " + a.firstName);


    const tableColumn = useMemo(
        () => [
            {
                accessorKey: "firstName",
                header: "Name",
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <>
                        <Typography>
                            {`${row.original["firstName"]} ${row.original["lastName"]} ${row.original.initials ? row.original.initials : ""}`}
                        </Typography>

                        {
                            row.original["partnerData"] && row.original["partnerData"]["firstName"] && (
                                <Typography>
                                    {`${row.original["partnerData"]["firstName"]} ${row.original["partnerData"]["lastName"]}`}
                                </Typography>
                            )
                        }

                    </>
                ),
            },
            {
                accessorKey: "phone",
                header: "Mobile Number",
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <>
                        <Typography>
                            {`${row.original["phone"]}`}
                        </Typography>
                        {
                            row.original["partnerData"] && row.original["partnerData"]["firstName"] && (
                                <Typography>
                                    {`${row.original["partnerData"]["phone"]}`}
                                </Typography>
                            )
                        }
                    </>
                ),
            },
            {
                accessorKey: "email",
                header: "Email ID",
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <>
                        <Typography>
                            {`${row.original["email"]}`}
                        </Typography>
                        {
                            row.original["partnerData"] && row.original["partnerData"]["firstName"] && (
                                <Typography>
                                    {`${row.original["partnerData"]["email"]}`}
                                </Typography>
                            )
                        }
                    </>
                ),
            },


        ],
        []
    );



    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalChildOpen, setModalChildOpen] = React.useState(false);

    const handleClickOpen = () => {
        if (borrowerType === 'Primary') {
            if (data?.length != 0) {
                setModalOpen(true);
            } else {
                handleSubmit()
            }
        }
        else
            handleSubmit({ coBorrAdd: "no" })

    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleClickChildClose = () => {
        setModalChildOpen(false);
        setModalOpen(false);

    };

    const handleClickChildOpen = () => {
        setModalChildOpen(true);
    };

    const handleChildClose = (e) => {
        handleSubmit({ coBorrInv: e.target.value })
        handleClose()
        setModalChildOpen(false);
    };

    return (
        <>
            <Stack direction={'column'} justifyContent={'space-between'} height={'100%'} px={{ xs: 1, md: 4 }}>
                <Box ml={3}>
                    <Box my={2}>
                        <Typography variant={'h2'}>{`Hello ${profileData?.firstName},`}</Typography>
                        <Typography component={'p'} mt={1}>
                            {`Here's the list of all the borrowers of this loan. Now by pressing next you will get into the 1003 application form.`}
                        </Typography>
                    </Box>
                    <Box my={2}>
                        <CustomReactMaterialTable
                            staticLoad={true}
                            staticData={list}
                            columns={tableColumn}
                            enableTopToolbar={false}
                            enableBottomToolbar={false}
                            enableSorting={false}
                            enableToolbarInternalActions={false}
                            endPoint={"/user"}
                        />
                    </Box>
                </Box>
                <Box my={2} ml={2} width={{ xs: '100%', md: '50%' }}>
                    <Stack direction={'row'} justifyContent={'space-between'}>

                        <Button
                            type='button'
                            startIcon={<ArrowBackIos />}
                            onClick={() => {
                                if (borrowerType === "Primary") {
                                    setPage("co-borrower");
                                } else {
                                    setPage("partner");
                                }
                            }}
                        >
                            Previous
                        </Button>

                        <Button endIcon={<ArrowForwardIos />} onClick={handleClickOpen}>
                            Save and Next
                        </Button>
                    </Stack>
                </Box>
            </Stack>

            <DialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={`Confirm Additional Borrower`}
                body={
                    <>
                        <Box width={'100%'} my={2} >
                            <Box >
                                <Typography textAlign={'justify'} mb={2}>
                                    {
                                        `Are you sure you want to add ${coBorrowerNamesForConfirm} as a additional borrower for this loan?`
                                    }
                                </Typography>

                                <Stack direction={'row-reverse'} spacing={2}>
                                    <Button variant='contained' onClick={() => { handleClickChildOpen() }}>Yes</Button>
                                    <Button variant='text' onClick={() => { handleClose(); handleSubmit({ coBorrAdd: "no" }); }}>No</Button>
                                </Stack>
                            </Box>
                        </Box>

                    </>
                }
            />

            <DialogCommonHooks
                show={modalChildOpen}
                handleClose={handleClickChildClose}
                title={`Invite Additional Borrower`}
                body={

                    <Box width={'100%'} my={2}>

                        <Box>
                            {borrowerType === 'Primary' ? (
                                <Typography textAlign={'justify'} mb={2}>
                                    {`Do you want to send an invitation to these additional borrowers (${coBorrowerNamesForConfirm})?`}
                                </Typography>
                            ) : (
                                <Typography textAlign={'justify'} mb={2}>
                                    Sending an invitation is not applicable for additional borrower.
                                </Typography>
                            )}

                            <Stack direction={'row-reverse'} spacing={2}>
                                {borrowerType !== 'Primary' ? (

                                    <Button variant='contained' onClick={handleChildClose}>Next</Button>
                                ) : (

                                    <>
                                        <Button value={"co-bor"} onClick={async (e) => { handleChildClose(e) }} variant='contained'>Yes</Button>
                                        <Button variant='text' onClick={handleChildClose}>No</Button>
                                    </>
                                )}
                            </Stack>
                        </Box>

                    </Box>

                }
            />
        </>
    )
}
