import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { globalSpinner } from "../recoil/profile-atom";
import React from 'react'


// Higher order component for adding hooks as parameters to class component
export const withRouter = (Component) => {
  const Wrapper = (props) => {
    return (
      <Component
        history={useNavigate()}
        location={useLocation()}
        globalSpinnerState={useRecoilValue(globalSpinner)}
        {...props}
      />
    );
  };

  return Wrapper;
};
