import { Button, FormHelperText, Grid, Link as MuLink, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Path } from "../../routes/route-config";

import CustomInput from "../../helpers/custom-input";
import useInput from "../../helpers/use-input";
import { API } from "../../services/api-config";
import { getApiCallDynamic, postApiCallDynamic, } from "../../services/api-service";
import { Auth } from "../../auth/auth";
import { toastNotify } from "../../helpers/alert-msg";
import { CountDown } from "../../helpers/time-counter";
import { useFormik } from "formik";
import { PAGENAME, SERVICE_TYPE } from "../../constants/constatnt-variable";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBack";
import AuthSideUi from "../../components/auth-side-ui";

export default function OTP(props) {
  const { state, ...location } = useLocation();

  console.log(" hi i am state " + JSON.stringify(state, null, 2))

  const router = useNavigate();
  useEffect(() => {
    if (state == null) {
      router(Path.landing);
    }
  }, []);
  let [emailOTP] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  let [phoneOTP] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  const resendOtp = (event) => {
    event.preventDefault();
    let path = "";
    let param = {
      email: state.email,
      type: event.target.value,
      service: state.from,
    };
    switch (event.target.id) {
      case PAGENAME.signin:
        path = API.signinOtp;
        break;
      case PAGENAME.signup:
        path = API.signupOtp;
        break;
      case PAGENAME.forget:
        path = API.forgetOtp;
        break;
      case PAGENAME.invitation:
        path = API.resendOtp;
        break;
    }

    getApiCallDynamic({ param: param, path: path }).then((res) => {
      if (res?.status === 200) {
        if (event.target.value === "email") {
          emailOtpCount.setCounter(res.data.emailOtpCount);
        }
        if (event.target.value === "phone") {
          phoneOtpCount.setCounter(res.data.phoneOtpCount);
        }
      }
    });
  };

  const signIn = (data) => {
    Auth.tokenRequest(data).then((response) => {
      props.userHasAuthenticated(response[0], response[1]);

      console.log(" hi signin dat a" + JSON.stringify(data, null, 2))
      console.log(" hi response[0] " + JSON.stringify(response[0], null, 2))
      console.log(" hi response[1] " + JSON.stringify(response[1], null, 2))

      if (response[0]) {
        location.state = {
          ...location.state,
          initialLocation: `${Path.loanFormApply}${response[1]?.data?.formId}`
        }

        if (state.website) {
          router(`${Path.loanFormApply}${response[1]?.data?.formId}`);

        }
        else if (state.initialLocation) {
          router(state.initialLocation);
        } else {
          router(Path.index);
        }

      } else {
        if (response[1] && response[1].error && response[1].error.errorCode) {
          if (response[1].error.errorCode === 2005) {
            alert(response[1].error.errorMessage);
          } else {
            toastNotify("error", response[1].error.errorMessage);
          }
        } else {
          toastNotify("error", "API Server Unreachable");
        }

      }
    });
  };
  const invitationSignUp = () => {
    let data = {
      email: state.email,
      emailOTP: emailOTP.value,
      phoneOTP: phoneOTP.value,
      service: state.from,
    };
    postApiCallDynamic(
      {
        data: data,
        refresh: (res) => {
          return res;
        },
        path: API.verifyOTP
      }
    ).then((res) => {
      if (
        res &&
        (res.status === 200 || res.status === 201 || res.status === 202)
      ) {
        router(Path.invitationReg, {
          state: { ...state, ...res.data, ...res.requestData, from: state.from },
        });
      }
    });
  };
  const handleSubmit = () => {
    if (state.from == SERVICE_TYPE.invitation || state.from == SERVICE_TYPE.teamInvitation || state.from == SERVICE_TYPE.coBorrowerInvitation) {
      invitationSignUp();
    }

    if (state.from == SERVICE_TYPE.signin) {

      let data = {
        email: state.email,
        emailOTP: emailOTP.value,
        phoneOTP: phoneOTP.value,
        service: SERVICE_TYPE.signin,
      };

      signIn(data);
    }
    if (state.from == SERVICE_TYPE.forget) {
      let data = {
        email: state.email,
        emailOTP: emailOTP.value,
        phoneOTP: phoneOTP.value,
        service: SERVICE_TYPE.forget,
      };
      postApiCallDynamic(
        {
          data: data,
          refresh: (res) => {
            return res;
          },
          path: API.forgetOtp
        }
      ).then((res) => {
        if (
          res &&
          (res.status === 200 || res.status === 201 || res.status === 202)
        ) {
          router(Path.setPassword, {
            state: { ...state, ...res.data, ...res.requestData, from: state.from },
          });
        }
      });
    }
    if (state.from == SERVICE_TYPE.signup) {
      let data = {
        email: state.email,
        emailOTP: emailOTP.value,
        phoneOTP: phoneOTP.value,
        service: SERVICE_TYPE.signup,
      };

      postApiCallDynamic(
        {
          data: data,
          refresh: (res) => {
            return res;
          },
          path: API.verifyOTP
        }
      ).then((res) => {
        if (
          res &&
          (res.status === 200 || res.status === 201 || res.status === 202)
        ) {
          router(Path.setPassword, {
            state: { ...state, ...res.data, ...res.requestData, from: state.from },
          });
        }
      });
    }
  };

  let [emailOtpCount] = CountDown({ sec: state?.emailOtpCount });
  let [phoneOtpCount] = CountDown({ sec: state?.phoneOtpCount });

  const handleValidation = (values) => {
    let errors = {};

    if (emailOTP.value.trim() == "") {
      errors.emailOTP = "Please enter your email otp";
    }
    if (phoneOTP.value.trim() == "") {
      errors.phoneOTP = "Please enter your phone otp";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      emailOTP: emailOTP.value,
      phoneOTP: phoneOTP.value,
    },
    validate: (values) => handleValidation(values),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  return (
    <>
      <Grid container>
        {/* side background */}
        <AuthSideUi />
        {/* otp form */}
        <Grid item xs={12} sm={6} sx={{ height: "100vh", overflow: "auto" }}>
          <Box p={2}>
            <Grid container md={12} justifyContent="space-between">
              <Grid item>
                <Stack direction={"row"} spacing={2}>
                  <Button
                    onClick={() => {
                      router(-1);
                    }}
                  >
                    <ArrowBackOutlinedIcon />
                  </Button>
                </Stack>
              </Grid>
              <Grid
                item
                display={state?.from === SERVICE_TYPE.invitation ? "none" : ""}
                sx={{ textAlign: "right" }}
              >
                <Typography>
                  New here? &nbsp;
                  <Link to={Path.signup} style={{ color: "#2f80ed" }}>
                    Sign up
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box
            px={{ xs: 2, md: 20 }}
            pb={{ xs: 2, md: 3 }}
            minHeight="85vh"
            justifyContent="center"
            flexDirection="column"
            display="flex"
          >
            <Typography variant="h4" fontWeight="bold" mb={2}>
              Verify your identity
            </Typography>
            <Typography variant="body" mb={3} width={'368px'}>
              We have sent OTP to your registered email ID &amp; mobile number
            </Typography>

            {/* For Every Input Field */}
            <form onSubmit={formik.handleSubmit}>
              <Box my={2} width={'368px'}>
                <FormHelperText
                  sx={{
                    color: "blue",
                    width: "25%",
                    float: "right",
                    textAlign: "right",
                  }}
                >
                  {emailOtpCount.counter == 0 ? (
                    <MuLink
                      component="button"
                      id={state.from}
                      value="email"
                      onClick={resendOtp}
                    >
                      Resend
                    </MuLink>
                  ) : (
                    emailOtpCount.obj.m + " min " + emailOtpCount.obj.s + " sec"
                  )}
                </FormHelperText>
                <CustomInput
                  // style={{ borderRadius: '8px', border: '1px solid #999' }}
                  maxLength={15}
                  label={"Enter OTP sent on your email"}
                  placeholder={"Email OTP"}
                  change={emailOTP}
                  error={formik.errors.emailOTP}
                />
              </Box>

              <Box my={2} width={'368px'}>
                <FormHelperText
                  sx={{
                    color: "blue",
                    width: "25%",
                    float: "right",
                    textAlign: "right",
                  }}
                >
                  {phoneOtpCount.counter == 0 ? (
                    <MuLink
                      component="button"
                      id={state.from}
                      value="phone"
                      onClick={resendOtp}
                    >
                      Resend
                    </MuLink>
                  ) : (
                    phoneOtpCount.obj.m + " min " + phoneOtpCount.obj.s + " sec"
                  )}
                </FormHelperText>
                <CustomInput
                  maxLength={15}
                  // style={{ borderRadius: '8px', border: '1px solid #999' }}
                  label={"Enter OTP sent on your phone"}
                  placeholder={"Phone OTP"}
                  change={phoneOTP}
                  error={formik.errors.phoneOTP}
                />
              </Box>

              <Box my={2} width={'368px'}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  sx={{ borderRadius: "24px", fontWeight: "bold" }}
                  type="submit"
                >
                  Verify
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
