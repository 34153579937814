import { ROLE_LIST } from "../../../constants/constatnt-variable";



export const loanMenuMetaData = ({ partner = false, formData, role }) => {

  // custom function for not null value from an object
  const valueCounter = (obj) => {
    let count = 0;
    for (let key in obj) {
      if (obj[key] !== null) {
        count++;
      }
    }
    return count;
  };
  const valueCounterUndefined = (obj) => {
    let count = 0;
    for (let key in obj) {
      if (obj[key] !== undefined) {
        count++;
      }
    }
    return count;
  };
  // for getting started progress bar
  const gettingStartedProgress = () => {
    let progress = formData?.getStarted?.isComplete ? 100 : 0;
    return progress;
  };

  // for loan details progress bar
  const loanDetailsProgressBar = () => {
    let progress = valueCounter(formData?.loanDetails?.page);
    if (progress == 0) {
      return progress;
    } else {
      progress = 100;
      return progress;
    }
  };

  // for borrower info, personal info progress bar
  const borrowerInfoPersonalInfoProgressBar = () => {
    let progress
    let filled = formData?.borrowerInfo?.page?.personal?.subPage?.lastName
    if (filled) {
      progress = 100;
    } else {
      progress = 90;
    }
    return progress
  };

  // for borrower info , contact info progress bar
  const borrowerInfoContactInfoProgressBar = () => {

    let progress
    let filled = formData?.borrowerInfo?.page?.contact?.subPage?.cellPhone
    if (filled) {
      progress = 100;
    } else {
      progress = 90;
    }
    return progress
  };

  // for borrowerInfo, Demographic progress bar
  const borrowerInfoDemographicProgressBar = () => {
    let progress = valueCounter(
      formData?.borrowerInfo?.page?.demographics?.subPage
    );
    if (progress == 0) {
      return progress;
    } else {
      progress = 100;
      return progress;
    }
  };

  // for borrowerInfo, Declarations progressbar
  const borrowerInfoDeclarationsProgressBar = () => {
    let declarationObj = valueCounterUndefined(
      formData?.borrowerInfo?.page?.declarations?.subPage
    );

    let progress;
    if (declarationObj >= 15)
      progress = 100;
    return progress;
  };

  // for borrowerInfo, Dependent progress bar
  const borrowerInfoDependentProgressBar = () => {
    let progress = valueCounter(
      formData?.borrowerInfo?.page?.dependent?.subPage
    );
    if (progress == 0) {
      return progress;
    } else {
      progress = 100;
      return progress;
    }
  };

  // for borrowerInfo, Employment progress bar
  const borrowerInfoEmploymentProgressBar = () => {
    let progress =
      formData?.borrowerInfo?.page?.employment?.isComplete ? 100 : 0;
    return progress;
  };

  // for borrowerInfo, Employment progress bar
  const partnerInfoEmploymentProgressBar = () => {
    let progress =
      formData?.partnerInfo?.page?.employment?.isComplete ? 100 : 0;

    return progress;
  };

  // for borrowerInfo, Additional Income progress bar
  const borrowerInfoAdditionalIncomeProgressBar = () => {
    let progress =
      formData?.borrowerInfo?.page?.additionalIncome?.isComplete ? 100 : 0;
    return progress;
  };

  // for partnerInfo, Additional Income progress bar
  const partnerInfoAdditionalIncomeProgressBar = () => {
    let progress =
      formData?.partnerInfo?.page?.additionalIncome?.isComplete ? 100 : 0;

    return progress;
  };

  // for borrowerInfo,  military Service progressbar
  const borrowerInfoMilitaryServiceProgressBar = () => {
    let progress = valueCounter(
      formData?.borrowerInfo?.page?.militaryServices?.subPage
    );
    if (progress == 0) {
      return progress;
    } else {
      progress = 100;
      return progress;
    }
  };

  // for partner info, personal info progress bar
  const partnerInfoPersonalInfoProgressBar = () => {

    let progress
    let filled = formData?.partnerInfo?.page?.personal?.subPage?.lastName
    if (filled) {
      progress = 100;
    } else {
      progress = 90;
    }
    return progress

  };

  // for partner info , contact info progress bar
  const partnerInfoContactInfoProgressBar = () => {

    let progress
    let filled = formData?.partnerInfo?.page?.contact?.subPage?.cellPhone
    if (filled) {
      progress = 100;
    } else {
      progress = 90;
    }
    return progress
  };

  // for partner info, Demographic progress bar
  const partnerInfoDemographicProgressBar = () => {
    let progress = valueCounter(
      formData?.partnerInfo?.page?.demographics?.subPage
    );
    if (progress == 0) {
      return progress;
    } else {
      progress = 100;
      return progress;
    }
  };

  // for partner info, Declarations progressbar
  const partnerInfoDeclarationsProgressBar = () => {
    let declarationObj = valueCounter(
      formData?.partnerInfo?.page?.declarations?.subPage
    );

    let progress;
    if (declarationObj >= 15)
      progress = 100;
    return progress;
  };

  // for partner info, Dependent progress bar
  const partnerInfoDependentProgressBar = () => {
    let progress = valueCounter(
      formData?.partnerInfo?.page?.dependent?.subPage
    );
    if (progress == 0) {
      return progress;
    } else {
      progress = 100;
      return progress;
    }
  };

  // for partner info,  military Service progressbar
  const partnerInfoMilitaryServiceProgressBar = () => {
    let progress = valueCounter(
      formData?.partnerInfo?.page?.militaryServices?.subPage
    );
    if (progress == 0) {
      return progress;
    } else {
      progress = 100;
      return progress;
    }

  };

  // assets & credit progress bar
  const assetsAndCreditProgressBar = () => {

    // let totalValue = formData?.assetsCredits?.page
    //   ? formData?.assetsCredits?.page?.assetCredit[0]
    //   : {};
    // let progress = valueCounter(totalValue);



    let progress = formData?.assetsCredits?.isComplete ? 100 : 0;
    if (progress == 0) {
      return progress;
    } else {
      progress = 100;
      return progress;
    }

  };

  // realestate progress bar
  const giftProgressBar = () => {
    let progress = formData?.giftGrants?.isComplete ? 100 : 0;
    if (progress == 0) {
      return progress;
    } else {
      progress = 100;
      return progress;
    }

  };

  // realestate progress bar
  const realEstateProgressBar = () => {
    let progress = formData?.realEstate?.isComplete ? 100 : 0;
    if (progress == 0) {
      return progress;
    } else {
      progress = 100;
      return progress;
    }
  };

  // credit Report progress bar
  const creditReportProgressBar = () => {
    let progress = formData?.creditReport?.isComplete ? 100 : 0;
    if (progress == 0) {
      return progress;
    } else {
      progress = 100;
      return progress;
    }
  };

  // liabilities progress bar
  const liabilitiesProgressBar = () => {
    let progress = formData?.liabilities?.isComplete ? 100 : 0;
    if (progress == 0) {
      return progress;
    } else {
      progress = 100;
      return progress;
    }
  };

  // Guided Document progress bar
  const guidedDocumentProgressBar = () => {
    let progress = formData?.documents?.page?.guidedDocument?.isComplete
      ? 100
      : 0;
    if (progress == 0) {
      return progress;
    } else {
      progress = 100;
      return progress;
    }
  };

  // additional Document progress bar
  const additionalDocumentProgressBar = () => {
    let progress = formData?.documents?.page?.additionalDocument?.isComplete
      ? 100
      : 0;
    if (progress == 0) {
      return progress;
    } else {
      progress = 100;
      return progress;
    }
  };

  // review progress bar
  const reviewProgressBar = () => {
    let progress;
    if (formData?.review?.isComplete) {
      progress = 100;
      return progress;
    } else {
      progress = 0;
      return progress;
    }
  };

  // submit progress bar
  const submitProgressBar = () => {
    let progress;
    if (formData?.submit?.isComplete) {
      progress = 100;
      return progress;
    } else {
      progress = 0;
      return progress;
    }
  };

  // borrowerInfo progress bar
  const borrowerInfoProgressBar = () => {
    let totalValue =
      borrowerInfoPersonalInfoProgressBar() +
      borrowerInfoContactInfoProgressBar() +
      borrowerInfoDemographicProgressBar() +
      borrowerInfoDeclarationsProgressBar() +
      borrowerInfoDependentProgressBar() +
      borrowerInfoEmploymentProgressBar() +
      borrowerInfoAdditionalIncomeProgressBar() +
      borrowerInfoMilitaryServiceProgressBar();
    let progress = (totalValue * 100) / 800;
    return progress;
  };

  // Partner Info progress bar
  const partnerInfoProgressBar = () => {
    let totalValue =
      partnerInfoPersonalInfoProgressBar() +
      partnerInfoContactInfoProgressBar() +
      partnerInfoDemographicProgressBar() +
      partnerInfoDeclarationsProgressBar() +
      partnerInfoDependentProgressBar() +
      partnerInfoEmploymentProgressBar() +
      partnerInfoAdditionalIncomeProgressBar() +
      partnerInfoMilitaryServiceProgressBar();
    let progress = (totalValue * 100) / 800;
    return progress;
  };

  // Document progress bar
  const documentProgressBar = () => {
    let totalValue =
      guidedDocumentProgressBar() + additionalDocumentProgressBar();
    let progress = (totalValue * 100) / 200;
    return progress;
  };

  let data = [
    {
      tabName: "Getting Started",
      tabComplete: true,
      pageHref: "getting-started",
      tabProgress: gettingStartedProgress(),
      tabActive: false,
      inProgress: formData?.getStarted?.inProgress,
      isComplete: formData?.getStarted?.isComplete,
    },
    {
      tabName: "Loan Details",
      tabComplete: true,
      pageHref: "loan-details",
      tabProgress: loanDetailsProgressBar(),
      tabActive: false,
      inProgress: formData?.loanDetails?.inProgress,
      isComplete: formData?.loanDetails?.isComplete,
    },
    {
      tabName: "Borrower Info",
      tabComplete: false,
      pageHref: "borrower-info",
      tabProgress: borrowerInfoProgressBar(),
      tabActive: false,
      inProgress: true,
      isComplete: formData?.borrowerInfo?.isComplete,
      child: [
        {
          tabName: "Personal",
          tabComplete: true,
          pageHref: "personal-information",
          tabProgress: borrowerInfoPersonalInfoProgressBar(),
          tabActive: false,
          isComplete: formData?.borrowerInfo?.page?.personal?.isComplete,
        },
        {
          tabName: "Contact",
          tabComplete: true,
          pageHref: "contact-information",
          tabProgress: borrowerInfoContactInfoProgressBar(),
          tabActive: false,
          isComplete: formData?.borrowerInfo?.page?.contact?.isComplete,
        },
        {
          tabName: "Demographics",
          tabComplete: true,
          pageHref: "demographics",
          tabProgress: borrowerInfoDemographicProgressBar(),
          tabActive: false,
          isComplete: formData?.borrowerInfo?.page?.demographics?.isComplete,
        },
        {
          tabName: "Declarations",
          tabComplete: true,
          pageHref: "declarations",
          tabProgress: borrowerInfoDeclarationsProgressBar(),
          tabActive: false,
          isComplete: formData?.borrowerInfo?.page?.declarations?.isComplete,
        },
        {
          tabName: "Dependent",
          tabComplete: true,
          pageHref: "dependent",
          tabProgress: borrowerInfoDependentProgressBar(),
          tabActive: false,
          isComplete: formData?.borrowerInfo?.page?.dependent?.isComplete,
        },
        {
          tabName: "Employment",
          tabComplete: true,
          pageHref: "employment",
          tabProgress: borrowerInfoEmploymentProgressBar(),
          tabActive: false,
          isComplete: formData?.borrowerInfo?.page?.employment?.isComplete,
        },
        {
          tabName: "Additional Income",
          tabComplete: true,
          pageHref: "additional-income",
          tabProgress: borrowerInfoAdditionalIncomeProgressBar(),
          tabActive: false,
          isComplete: formData?.borrowerInfo?.page?.additionalIncome?.isComplete,
        },
        {
          tabName: "Military Services",
          tabComplete: true,
          pageHref: "military-service",
          tabProgress: borrowerInfoMilitaryServiceProgressBar(),
          tabActive: false,
          isComplete:
            formData?.borrowerInfo?.page?.militaryServices?.isComplete,
        },
      ],
    },
    {
      ...(partner && {
        tabName: "Co-Borrower Info",
        tabComplete: false,
        pageHref: "partner-info",
        tabProgress: partnerInfoProgressBar(),
        tabActive: true,
        inProgress: true,
        isComplete: formData?.partnerInfo?.isComplete,

        child: [
          {
            tabName: "Personal",
            tabComplete: true,
            pageHref: "personal-information",
            tabProgress: partnerInfoPersonalInfoProgressBar(),
            tabActive: false,
            isComplete: formData?.partnerInfo?.page?.personal?.isComplete,
          },
          {
            tabName: "Contact",
            tabComplete: true,
            pageHref: "contact-information",
            tabProgress: partnerInfoContactInfoProgressBar(),
            tabActive: false,
            isComplete: formData?.partnerInfo?.page?.contact?.isComplete,
          },
          {
            tabName: "Demographics",
            tabComplete: true,
            pageHref: "demographics",
            tabProgress: partnerInfoDemographicProgressBar(),
            tabActive: false,
            isComplete: formData?.partnerInfo?.page?.demographics?.isComplete,
          },
          {
            tabName: "Declarations",
            tabComplete: true,
            pageHref: "declarations",
            tabProgress: partnerInfoDeclarationsProgressBar(),
            tabActive: false,
            isComplete: formData?.partnerInfo?.page?.declarations?.isComplete,
          },
          {
            tabName: "Dependent",
            tabComplete: true,
            pageHref: "dependent",
            tabProgress: partnerInfoDependentProgressBar(),
            tabActive: false,
            isComplete: formData?.partnerInfo?.page?.dependent?.isComplete,
          },
          {
            tabName: "Employment",
            tabComplete: true,
            pageHref: "employment",
            tabProgress: partnerInfoEmploymentProgressBar(),
            tabActive: false,
            isComplete: formData?.partnerInfo?.page?.employment?.isComplete,
          },
          {
            tabName: "Additional Income",
            tabComplete: true,
            pageHref: "additional-income",
            tabProgress: partnerInfoAdditionalIncomeProgressBar(),
            tabActive: false,
            isComplete: formData?.partnerInfo?.page?.additionalIncome?.isComplete,
          },
          {
            tabName: "Military Services",
            tabComplete: true,
            pageHref: "military-service",
            tabProgress: partnerInfoMilitaryServiceProgressBar(),
            tabActive: false,
            inProgress:
              formData?.partnerInfo?.page?.militaryServices?.inProgress,
            isComplete:
              formData?.partnerInfo?.page?.militaryServices?.isComplete,
          },
        ],
      }),
    },
    {
      tabName: "Assets & Credits",
      tabComplete: true,
      pageHref: "assets-credits",
      tabProgress: assetsAndCreditProgressBar(),
      tabActive: false,
      inProgress: formData?.assetsCredits?.inProgress,
      //isComplete: formData?.assetsCredits?.isComplete,
      isComplete: formData?.assetsCredits?.isComplete
    },
    {
      tabName: "Gift & Grants",
      tabComplete: true,
      pageHref: "gift-grants",
      tabProgress: giftProgressBar(),
      tabActive: false,
      inProgress: formData?.giftGrants?.inProgress,
      isComplete: formData?.giftGrants?.isComplete,
    },
    {
      tabName: "Real Estate",
      tabComplete: true,
      pageHref: "real-estate",
      tabProgress: realEstateProgressBar(),
      tabActive: false,
      inProgress: formData?.realEstate?.inProgress,
      isComplete: formData?.realEstate?.isComplete,
    },
    {
      tabName: "Credit Report",
      tabComplete: true,
      pageHref: "credit-report",
      tabProgress: creditReportProgressBar(),
      tabActive: false,
      inProgress: formData?.creditReport?.inProgress,
      isComplete: formData?.creditReport?.isComplete,
    },
    {
      tabName: "Liabilities",
      tabComplete: true,
      pageHref: "liabilities",
      tabProgress: liabilitiesProgressBar(),
      tabActive: false,
      inProgress: formData?.liabilities?.inProgress,
      isComplete: formData?.liabilities?.isComplete,
    },
    {
      tabName: "Documents",
      tabComplete: false,
      pageHref: "documents",
      tabProgress: documentProgressBar(),
      tabActive: false,
      inProgress: formData?.documents?.inProgress,
      isComplete: formData?.documents?.isComplete,
      child: [
        {
          tabName: "Guided Document",
          tabComplete: true,
          pageHref: "guided-document",
          tabProgress: guidedDocumentProgressBar(),
          tabActive: formData?.documents?.page?.guidedDocument?.tabActive,
          isComplete: formData?.documents?.page?.guidedDocument?.isComplete,
        },

        {
          tabName: "Additional Document",
          tabComplete: true,
          pageHref: "additional-document",
          tabProgress: additionalDocumentProgressBar(),
          tabActive: formData?.documents?.page?.additionalDocument?.tabActive,
          isComplete: formData?.documents?.page?.additionalDocument?.isComplete,
        },
      ],
    },
    {
      tabName: "Review",
      tabComplete: true,
      pageHref: "review",
      tabProgress: reviewProgressBar(),
      tabActive: false,
      inProgress: formData?.review?.inProgress,
      isComplete: formData?.review?.isComplete,
    },
    {
      tabName: "Submit",
      tabComplete: true,
      pageHref: "submit",
      tabProgress: submitProgressBar(),
      tabActive: false,
      inProgress: formData?.submit?.inProgress,
      isComplete: formData?.submit?.isComplete,
    },
    // (role !== ROLE_LIST.borrower) && (
    //   {
    //     "tabName": "Loan Documents",
    //     "tabComplete": true,
    //     "pageHref": "loan-document",
    //     "tabProgress": false,
    //     "tabActive": false,
    //     'inProgress': false,
    //     'isComplete': true,
    //   }
    // )
  ]
  return data

}
