import React, { useEffect, useRef, useState } from "react";
import { Button, Grid, Typography, useMediaQuery, Box } from "@mui/material";
import CustomInput from "../../helpers/custom-input";
import CustomDropDown from "../../helpers/dropdown/custom-dropdown";
import { API } from "../../services/api-config";
import { getApiCallDynamic, postApiCallDynamicWithOutReturn } from "../../services/api-service";
import _ from "lodash";
import { UPDATE } from "../../constants/constatnt-variable";
import { kstheme } from "../../theme/theme";

export default function NmlsForm({ getOwnInfo, isSingleForm, formData, onCancelClick, isMLO, rowData, inputEnable }) {
    const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));
    const autoFill = typeof formData === 'object';
    const div = useRef(null);
    const [states, setStates] = useState([])
    const [error, setError] = useState('')
    const [nmlsData, setNmlsData] = useState({
        state: formData?.state || null,
        userNmlsId: formData?.userNmlsId || rowData?.userNmlsId,
        companyNmlsId: formData?.companyNmlsId || rowData?.companyNmlsId,
        //companyStateLicense: formData?.companyStateLicense || 'Company State License ID',
        //stateLicenseCode: formData?.stateLicenseCode || 'Your State License ID'
        companyStateLicense: formData?.companyStateLicense || '',
        stateLicenseCode: formData?.stateLicenseCode || ''
    })
    const [rowValue, setRowValue] = useState('')

    useEffect(() => {
        div.current.scrollIntoView({ behavior: "smooth", block: "end" })
        getApiCallDynamic({ path: API.getStates }).then((res) => {
            if (res?.status === 200) {
                setStates(res.data);
                if (formData?.state) {
                    res.data.forEach((item) => {
                        if (item.stateCode === formData.state) {
                            setRowValue(item.stateName)
                        }
                    })
                }
            }
        });
    }, []);

    const handleFieldChange = (value, type) => {
        let stateData = {}
        const data = { ...nmlsData }
        if (type === 'state') {
            setRowValue(value.value)
            states.forEach(state => {
                if (state.stateCode === value.value) {
                    stateData = state
                    return
                }
            })
            if (autoFill) {
                data[type] = typeof stateData === "object" ? stateData.stateCode : stateData;
            } else {
                //  console.log(stateData, 'state')
                data[type] = stateData.stateCode;
            }
        } else {
            data[type] = value;
        }
        setNmlsData(data)
    }

    const onSubmitClick = async () => {
        if (nmlsData.companyStateLicense === '-' || nmlsData.companyStateLicense === null || nmlsData.companyStateLicense === '' || nmlsData.state === null || nmlsData.stateLicenseCode === '-' || nmlsData.stateLicenseCode === null || nmlsData.stateLicenseCode === '') {
            setError('Please fill all the fields below')
            return;
        }
        // console.log(nmlsData, 'nmlsData')
        setError('')
        if (autoFill && !isSingleForm) {
            await postApiCallDynamicWithOutReturn({
                data: nmlsData,
                path: API.createNMLA,
                header: UPDATE,
            });
        } else {
            await postApiCallDynamicWithOutReturn({
                data: nmlsData,
                path: API.createNMLA,
            });
        }
        getOwnInfo();
    }

    function isNumeric(str) {
        if (typeof str != "string") return false
        return !isNaN(str) &&
            !isNaN(parseFloat(str))
    }

    return (
        <Grid container spacing={1} >

            <Grid mt={5} ml={isMobile ? 1 : 6} ref={div}>
                {!!error.length &&
                    <Typography variant="error" typography={'p'} color={'red'}>{error}</Typography>}
                <CustomInput
                    disabled
                    required
                    label={'Company NMLS ID'}
                    size="small"
                    // style={{ width: '50%' }}
                    style={{ width: isMobile ? '100%' : '100%' }}
                    placeholder={nmlsData.companyNmlsId}
                    value={nmlsData.companyNmlsId}
                    marginBottom={'24px'}
                    onChange={(e) => handleFieldChange(e.target.value, 'companyNmlsId')}
                    error={_.isEmpty(nmlsData.companyNmlsId)}
                />
                <CustomInput
                    disabled={isMLO}
                    required
                    label={'Company State License ID'}
                    size="small"
                    style={{ width: isMobile ? '100%' : '100%' }}
                    // placeholder={nmlsData.companyStateLicense}
                    placeholder={'Company State License ID'}
                    //value={isNumeric(nmlsData.companyStateLicense) ? nmlsData.companyStateLicense : ''}
                    value={nmlsData.companyStateLicense}
                    marginBottom={'24px'}
                    onChange={(e) => handleFieldChange(e.target.value, 'companyStateLicense')}
                    error={_.isEmpty(nmlsData.companyStateLicense)}

                // error={'Company State License ID is Required'}
                />
                <CustomInput
                    disabled
                    required
                    label={'Your NMLS ID'}
                    size="small"
                    style={{ width: isMobile ? '100%' : '100%' }}
                    placeholder={nmlsData.userNmlsId}
                    value={nmlsData.userNmlsId}
                    marginBottom={'24px'}
                    onChange={(e) => handleFieldChange(e.target.value, 'userNmlsId')}
                    error={_.isEmpty(nmlsData.userNmlsId)}
                />
                <CustomInput
                    required
                    label={'Your State License ID'}
                    size="small"
                    style={{ width: isMobile ? '100%' : '100%' }}
                    placeholder={'Your State License ID'}
                    value={nmlsData.stateLicenseCode ? nmlsData.stateLicenseCode : ''}
                    marginBottom={'24px'}
                    onChange={(e) => handleFieldChange(e.target.value, 'stateLicenseCode')}
                    error={_.isEmpty(nmlsData.stateLicenseCode)}
                // error={'State License is Required'}
                />
                <Box style={{ width: isMobile ? '200%' : '200%' }}>
                    <CustomDropDown
                        disabled={isMLO}
                        limitWidth={'50%'}
                        //limitWidth={isMobile ? '30%' : '50%'}
                        label="State"
                        style={{ marginBottom: '24px' }}
                        placeholder={nmlsData.state}
                        defaultValue={nmlsData.state}
                        rowData={rowValue || nmlsData.state}
                        value={nmlsData.state !== '-' ? nmlsData.state : ''}
                        option={states.map((i) => {
                            return {
                                value: i.stateCode,
                                label: i.stateCode,
                            };
                        })}
                        changeCallBack={(e) => handleFieldChange(e, 'state')}
                    />
                </Box>

                <Button
                    variant="outlined"
                    onClick={() => onCancelClick()}
                    sx={{ borderRadius: "24px", px: 4, mr: 5 }}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    onClick={() => onSubmitClick()}
                    sx={{ borderRadius: "24px", px: 5 }}
                >
                    {autoFill ? 'Update' : 'Add'}
                </Button>
            </Grid>
        </Grid>
    )
}
