import React, { useEffect, useState } from "react";
import InvitationEmptyPage from "../../components/invitation/empty-page";
import InvitationData from "../../components/invitation/invitation-data";
import MainLoyOut from "../../components/main-layout";
import { SERVICE_TYPE, UPDATE } from "../../constants/constatnt-variable";
import { toastNotify } from "../../helpers/alert-msg";
import { Path } from "../../routes/route-config";
import { API } from "../../services/api-config";
import { getApiCallDynamic, postApiCallDynamic } from "../../services/api-service";

function BorrowerInvitations() {

  const [invitationData, setInvitationData] = useState(
    [
    ]
  )

  const [reload, setReload] = useState(false)
  useEffect(() => {
    getApiCallDynamic({ path: API.borrowerInvitationList })
      .then((res) => {
        if (res?.status === 200) {
          setInvitationData(res.data)
        }
      })
  }, [reload]);

  const invitationStatusChange = (id, status, service) => {

    let data = {
      id: id,
      status: status,
      service: service
    }

    postApiCallDynamic({
      path: API.invitationStatus,
      refresh: (res) => {
        return res;
      },
      data: data,
      header: UPDATE
    }).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.status === "Accepted") {
          toastNotify("success", res.data.status)
          if (service == SERVICE_TYPE.invitation) {
            window.open(
              Path.loanApplication + `/${res.data.formId}`
            )
            console.log("service", service);
          }
        } else if (res?.data?.status === "Declined") {
          toastNotify("error", res.data.status)
        }
        setReload((prev) => !prev)
      }

    });
  }

  return (
    <MainLoyOut>
      {invitationData.length == 0 ? (
        <InvitationEmptyPage />
      ) : (
        <InvitationData invitationData={invitationData} invitationStatusChange={invitationStatusChange} />
      )}
    </MainLoyOut>
  );
}

export default BorrowerInvitations;
