import {Box, Divider} from '@mui/material'
import React from 'react'

export function CustomDivider({ display, width }) {
    return (
        <Box display={display} width={width} position={"absolute"} bottom={1}>
            <Divider variant="fullWidth" />
        </Box>
    )
}
