import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
const customStyle = makeStyles({
    ContentHeader: {
        fontFamily: "Inter-Medium !important",
        fontSize: "24px !important",
        color: "#221F1F",
    },

    ContentHeader2: {
        fontFamily: "DMSans-Regular !important",
        fontSize: "24px !important",
        color: "#221F1F",
    },

    ContentBody: {
        fontFamily: "DMSans-Regular !important",
        fontSize: "20px !important",
        color: "#000000",
        opacity: 0.8,
        textAlign: "justify",
    },

    ContentUnderline: {
        fontFamily: "DMSans-Regular !important",
        fontSize: "24px !important",
        color: "#221F1F",
        textDecoration: "underline",
    },
});

const data = [
    {
        title: 'California Residential Mortgage Lending Act (CRMLA):',
        subTitle: 'The CRMLA regulates residential mortgage lending in California. It requires individuals and entities engaged in mortgage lending activities to obtain a license from the California Department of Financial Protection and Innovation (DFPI). The CRMLA sets standards for licensing, disclosure, reporting, and conduct, with the goal of protecting consumers and promoting fair and transparent mortgage lending practices.',
        list: [
            {
                title: 'Licensing Requirements: ',
                subTitle: 'Mortgage lenders and brokers operating in California must obtain a license from the California Department of Financial Protection and Innovation (DFPI) to conduct residential mortgage lending activities. The licensing process typically involves meeting certain financial, character, and competency standards, as determined by the DFPI.',
            },
            {
                title: 'Standards of Conduct: ',
                subTitle: 'CRMLA establishes standards of conduct for licensed mortgage lenders and brokers. These standards encompass fair dealing, ethical practices, and compliance with applicable laws and regulations. Lenders and brokers are required to provide borrowers with accurate and transparent information about loan terms, fees, and other relevant details.',
            },
            {
                title: 'Consumer Protections: ',
                subTitle: 'The CRMLA includes provisions aimed at protecting consumers in residential mortgage transactions. This includes ensuring that borrowers have access to clear and understandable information about their mortgage agreements. Provisions may address issues such as prepayment penalties, adjustable-rate mortgage disclosures, and restrictions on certain loan features.',
            },
            {
                title: 'Record-Keeping and Reporting: ',
                subTitle: 'Mortgage lenders and brokers are typically required to maintain records of their mortgage transactions and make these records available for regulatory review. Reporting requirements may be in place to provide the DFPI with information about the volume and nature of mortgage lending activities within the state.',
            },
            {
                title: 'Enforcement and Penalties: ',
                subTitle: 'The CRMLA grants the DFPI the authority to enforce compliance with its provisions. Violations of the Act may result in penalties, fines, and other enforcement actions. Enforcement measures aim to deter unlawful practices and ensure that mortgage lenders and brokers adhere to the established standards.',
            },
            {
                title: 'Updates and Amendments: ',
                subTitle: 'The CRMLA may be subject to updates and amendments over time to address changes in the mortgage industry, market conditions, and regulatory requirements.',
            },
            {
                title: 'DFPI Oversight: ',
                subTitle: 'The California Department of Financial Protection and Innovation plays a central role in overseeing and regulating mortgage lenders and brokers under the CRMLA. The DFPI administers licensing, conducts examinations, and takes enforcement actions when necessary.'
            }
        ]
    },
    {
        title: 'Secure and Fair Enforcement for Mortgage Licensing Act (SAFE Act):',
        subTitle: 'California adheres to the federal SAFE Act, which mandates licensing and registration of mortgage loan originators (MLOs). MLOs must pass a written test, complete pre-licensing education, and undergo a criminal background check. The SAFE Act aims to enhance consumer protection and maintain the integrity of the residential mortgage loan market.',
        list: [
            {
                title: 'MLO Licensing and Registration: ',
                subTitle: 'The SAFE Act requires MLOs to obtain and maintain a unique identifier, known as the Nationwide Mortgage Licensing System and Registry (NMLS) number. MLOs must pass a written test demonstrating their understanding of federal and state mortgage laws and regulations.They are also required to undergo a background check, including criminal and financial history checks.'
            },
            {
                title: 'Education and Testing: ',
                subTitle: 'MLOs are mandated to complete pre-licensing education courses to ensure they have a fundamental understanding of mortgage lending practices, ethics, and regulations. Continuing education requirements are in place to keep MLOs informed about changes in laws and industry practices.'
            },
            {
                title: 'Uniform State Licensing: ',
                subTitle: 'The SAFE Act promotes uniformity by establishing a framework for states to adopt consistent licensing and registration requirements for MLOs. MLOs are generally required to be licensed in the state where they conduct business and registered on the NMLS.'
            },
            {
                title: 'Nationwide Mortgage Licensing System and Registry (NMLS): ',
                subTitle: 'The NMLS is a centralized database that facilitates the collection and storage of information about MLOs, including their licensing and registration status. It allows regulators, consumers, and industry professionals to access and verify information about MLOs across state lines.'
            },
            {
                title: 'Consumer Protection: ',
                subTitle: 'The SAFE Act contributes to consumer protection by ensuring that MLOs meet minimum standards of competency and integrity. Consumers can use the NMLS to verify the licensing status of MLOs and access information about their professional background.'
            },
            {
                title: 'State Implementation and Enforcement: ',
                subTitle: 'While the SAFE Act sets federal standards, individual states are responsible for implementing and enforcing these standards. States have the authority to establish additional requirements or standards for MLOs operating within their jurisdictions.'
            },
            {
                title: 'Prohibition of Fraud and Unfair Practices: ',
                subTitle: ' The SAFE Act prohibits fraud and unfair practices in the origination of mortgage loans. MLOs are expected to operate with honesty, integrity, and in compliance with all applicable laws and regulations.'
            },
            {
                title: 'Background Checks and Financial Responsibility: ',
                subTitle: 'MLOs are subject to background checks to assess their character and fitness for the role. They are also expected to demonstrate financial responsibility, as financial instability may raise concerns about their ability to act in the best interests of consumers.'
            }
        ]
    },
    {
        title: 'Equal Credit Opportunity Act (ECOA): ',
        subTitle: 'The federal ECOA prohibits discrimination in any aspect of a credit transaction, including mortgage lending. Lenders are prohibited from discriminating against applicants based on race, color, religion, national origin, sex, marital status, age, or other protected characteristics.',
        list: [
            {
                title: 'Protected Characteristics: ',
                subTitle: 'ECOA prohibits discrimination on the basis of race, color, religion, national origin, sex, marital status, age, or the receipt of income from public assistance programs'
            },
            {
                title: 'Scope of Application: ',
                subTitle: 'ECOA applies to a wide range of credit transactions, including loans, credit cards, mortgages, and other forms of credit. It covers both consumer and business credit.'
            },
            {
                title: 'Prohibition of Discrimination:',
                subTitle: ' Creditors are prohibited from discriminating against applicants in any aspect of a credit transaction, including the application process, approval or denial of credit, and the terms and conditions of credit.'
            },
            {
                title: 'Fair Lending Practices: ',
                subTitle: `ECOA mandates that creditors evaluate creditworthiness based on the applicant's financial information and credit history, rather than on characteristics such as race or gender.`
            },
            {
                title: 'Notification of Credit Decisions: ',
                subTitle: 'Creditors are required to provide applicants with a written explanation of credit decisions, including the reasons for denial if applicable. This helps applicants understand the basis of the decision.'
            },
            {
                title: 'Spousal Signature Requirements: ',
                subTitle: ' ECOA prohibits creditors from requiring the signature of a spouse on a credit application if the applicant qualifies independently. This provision aims to ensure that married individuals are not unfairly denied credit based on marital status.'
            },
            {
                title: 'Notification of Right to Receive a Copy: ',
                subTitle: ` Applicants have the right to request and receive a copy of the appraisal or other written valuation used by the creditor in connection with a credit application secured by the applicant's principal dwelling.`
            },
            {
                title: 'Business Credit: ',
                subTitle: 'While ECOA primarily focuses on protecting consumers, it also applies to certain business credit transactions. However, there are some exemptions and limitations for business credit under ECOA.'
            },
            {
                title: 'Regulatory Oversight:',
                subTitle: ' ECOA is enforced by various regulatory agencies, including the Consumer Financial Protection Bureau (CFPB) and the Federal Reserve Board. These agencies are responsible for ensuring compliance and investigating complaints related to ECOA violations.'
            },
            {
                title: 'Penalties for Non-Compliance: ',
                subTitle: 'Creditors found in violation of ECOA may face penalties, fines, and other legal consequences. Additionally, victims of discrimination may pursue legal remedies against creditors through private lawsuits'
            },
        ]
    },
    {
        title: 'Fair Housing Act (FHA):',
        subTitle: ' The federal Fair Housing Act prohibits discrimination in housing transactions, including mortgage lending. It ensures that individuals are not discriminated against in the mortgage lending process based on factors such as race, color, religion, national origin, sex, disability, or familial status.',
        list: [
            {
                title: 'Protected Characteristics: ',
                subTitle: 'The FHA prohibits discrimination based on the following protected characteristics: Race, Color, Religion, Sex, National origin, Familial status (including the presence of children under the age of 18 in a household) Disability.'
            },
            {
                title: 'Scope of Application: ',
                subTitle: 'The FHA applies to a wide range of housing-related activities, including the sale or rental of housing, financing, advertising, and the provision of brokerage services. It covers both public and private housing providers.'
            },
            {
                title: 'Prohibition of Discriminatory Practices: ',
                subTitle: 'Housing providers are prohibited from engaging in discriminatory practices, including refusing to sell or rent, refusing to negotiate, providing different terms or conditions, or falsely denying the availability of housing based on a protected characteristic.'
            },
            {
                title: 'Accessibility Requirements: ',
                subTitle: 'The FHA includes provisions related to accessibility for individuals with disabilities. Housing providers are required to make reasonable accommodations and modifications to allow individuals with disabilities equal access to housing.'
            },
            {
                title: 'Advertising and Marketing: ',
                subTitle: 'The FHA prohibits discriminatory advertising and marketing practices. Housing advertisements must not indicate preferences, limitations, or discrimination based on protected characteristics.'
            },
            {
                title: 'Exemptions: ',
                subTitle: 'Certain housing arrangements are exempt from the FHA, including owner-occupied buildings with four or fewer units, single-family homes sold or rented without the use of a real estate professional, and housing operated by religious organizations'
            },
            {
                title: 'Enforcement: ',
                subTitle: 'The U.S. Department of Housing and Urban Development (HUD) is responsible for enforcing the FHA. Individuals who believe they have experienced housing discrimination can file complaints with HUD.'
            },
            {
                title: 'Accessibility Guidelines (Design and Construction): ',
                subTitle: 'The FHA includes accessibility guidelines for the design and construction of multifamily housing, ensuring that new construction and certain alterations are accessible to individuals with disabilities.'
            },
            {
                title: 'Affirmative Fair Housing Marketing Plan (AFHMP): ',
                subTitle: 'Certain housing providers are required to develop and implement an Affirmative Fair Housing Marketing Plan, which outlines strategies to attract a diverse range of applicants and prevent discrimination.'
            }
        ]
    },
    {
        title: 'California Department of Financial Protection and Innovation (DFPI): ',
        subTitle: 'The DFPI oversees financial institutions and services in California, including mortgage lenders and brokers. It plays a crucial role in enforcing state laws and regulations related to mortgage transactions.',
        list: [
            {
                title: 'Consumer Protection: ',
                subTitle: 'The DFPI focuses on protecting consumers from unfair, deceptive, and abusive practices in the financial services industry. This includes overseeing various financial products, such as loans, credit cards, and other banking services.'
            },
            {
                title: 'Regulatory Oversight: ',
                subTitle: 'The department has regulatory authority over a broad spectrum of financial institutions, including banks, credit unions, money transmitters, and various non-bank financial services providers. It works to ensure compliance with state laws and regulations.'
            },
            {
                title: 'Licensing and Supervision: ',
                subTitle: 'The DFPI is responsible for licensing and supervising financial institutions and professionals operating in California. This includes mortgage lenders, money services businesses, debt collectors, and others.'
            },
            {
                title: 'Enforcement Actions: ',
                subTitle: 'The department has the authority to take enforcement actions against entities or individuals that violate financial laws and regulations. This may include imposing fines, revoking licenses, and pursuing legal actions to address violations.'
            },
            {
                title: 'Innovation and Emerging Technologies: ',
                subTitle: 'The DFPI is known for its focus on fostering innovation in financial services. It seeks to adapt to emerging technologies and support responsible innovation that benefits consumers while ensuring compliance with regulatory standards.'
            },
            {
                title: 'Consumer Education: ',
                subTitle: 'The department engages in consumer education efforts to inform the public about financial products, services, and their rights. This includes providing resources and information to help consumers make informed financial decisions.'
            },
            {
                title: 'Anti-Predatory Lending Initiatives: ',
                subTitle: 'The DFPI may be involved in initiatives to combat predatory lending practices, protect borrowers, and promote fair lending standards. This aligns with broader efforts to ensure access to responsible and affordable credit.'
            },
            {
                title: 'Coordination with Other Agencies: ',
                subTitle: 'The DFPI collaborates with other state and federal agencies to address financial challenges comprehensively. This coordination helps create a unified approach to consumer protection and regulatory oversight.'
            },
            {
                title: 'Adaptation to Legislative Changes: ',
                subTitle: 'The department adapts its regulations and policies based on legislative changes to keep pace with evolving financial landscapes and to address emerging challenges in consumer protection.'
            },
            {
                title: 'Complaint Resolution: ',
                subTitle: 'The DFPI may handle consumer complaints related to financial products and services. It works to resolve issues, investigate complaints, and take appropriate actions against entities that violate consumer protection laws'
            },
        ]
    }
]

function CaliforniaPolicy({ setPage }) {
    const classes = customStyle();
    return (
        <>
            <Box pt={4} id={"californiaPolicy"} style={{ backgroundColor: '#fff' }}>
                <Container maxWidth="lg">
                    <Grid container>
                        <Grid item xs={12} md={12} px={{ xs: 2 }} mb={6}>
                            <Typography variant="h2" color={"primary.main"} my={5} ml={3} fontWeight={700}>
                                {`California Policy`}
                            </Typography>
                            <Box>
                                <ol style={{ fontWeight: 500, fontSize: '20px', color: '#222222BF' }}>
                                    {Object.keys(data).map((key, index) => {
                                        return (
                                            <>
                                                <li style={{ fontWeight: 700, marginTop: '30px' }}>{data[key].title}<span style={{ fontWeight: 500 }}> {data[key].subTitle}</span></li>
                                                <ol>
                                                    {data[key].list.map((item, i) => {
                                                        return (
                                                            <li style={{ fontWeight: 700, marginTop: '20px' }} key={i}>{item.title} <span style={{ fontWeight: 500 }}>{item.subTitle}</span></li>
                                                        )
                                                    })}
                                                </ol>
                                            </>)
                                    })}
                                </ol>
                                <p style={{ fontWeight: 500, fontSize: '20px', color: '#222222BF' }}>{`Individuals and entities involved in mortgage transactions in California must be familiar with these laws and regulations to ensure compliance and uphold ethical standards in the mortgage industry. It's advisable to seek legal counsel to navigate the complexities of California's mortgage-related policies and regulations.`}</p>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}
export default CaliforniaPolicy;