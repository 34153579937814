import axios from "axios";
import { APIConfig } from "../auth/config";
import { AlertError } from "./alert-msg";
import { API } from "../services/api-config";
import FilePreview from "../pages/broker/leads/file-preview";
import MLOAvatar from '../icons/avatar_mlo.png';


// export const downloadReportFromServer = (url, data) => {
//     let params = {};
//     if (data) {
//         params = data;
//     }
//     console.log('download document called downloadReportFromServer' + url + JSON.stringify(data, null, 2))
//     return axios
//         .get(
//             url,
//             {
//                 params: params,
//                 //responseType: "blob",
//             }

//             // important
//         )
//         .then((response) => {
//             let filename = "";
//             let blob = new Blob([response.data]),
//                 downloadUrl = window.URL.createObjectURL(blob),
//                 disposition = response.headers["content-disposition"];
//             console.log("disposition", disposition);

//             if (disposition && disposition.indexOf("attachment") !== -1) {
//                 let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
//                     matches = filenameRegex.exec(disposition);

//                 if (matches[1]) {
//                     filename = matches[1].replace(/['"]/g, "");
//                 }
//             }

//             let a = document.createElement("a");
//             if (typeof a.download === "undefined") {
//                 window.location.href = downloadUrl;
//             } else {
//                 a.href = downloadUrl;
//                 a.download = filename;
//                 document.body.appendChild(a);
//                 a.click();
//             }
//         })
//         .catch((error) => AlertError(error));
// };
export const downloadReportFromServer = (url, data) => {
    let params = {};
    if (data) {
        params = data;
    }

    // console.log('download document called' + url + data)
    return axios
        .get(
            url,
            {
                params: params,
                responseType: "blob",
            }

            // important
        )
        .then((response) => {
            let filename = "";
            let blob = new Blob([response.data]),
                downloadUrl = window.URL.createObjectURL(blob),
                disposition = response.headers["content-disposition"];
            //  console.log("disposition", disposition);

            if (disposition && disposition.indexOf("attachment") !== -1) {
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
                    matches = filenameRegex.exec(disposition);

                if (matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            let a = document.createElement("a");
            if (typeof a.download === "undefined") {
                window.location.href = downloadUrl;
            } else {
                a.href = downloadUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
            }
        })
        .catch((error) => AlertError(error));
};

export const getFileUrl = (id, type) => {
    return process.env.REACT_APP_BASE_URL + API.leadDownload + `?id=${id}&&serviceType=${type}&&shouldPreview=true`
}

export const getImageUrl = (id, fileName, type) => {

    if (id && fileName) {
        return process.env.REACT_APP_BASE_URL + API.proxyUserDownload + `?id=${id}&serviceType=${type}&fileName=${fileName}`
    }
    /*
      const url = process.env.REACT_APP_BASE_URL + API.proxyUserDownload + `?id=${id}&serviceType=${type}&fileName=${fileName}`;
  
      fetch(url)
          .then(response => {
              if (response.status === 200) {
                  // Handle successful response
                  return url;
              } else {
                  // Return URL of local resource
                  return MLOAvatar;
              }
          })
          .then(downloadUrl => {
  
              return MLOAvatar;
              // Use the download URL (either from the server or local)
              //  console.log('Download URL:', downloadUrl);
              // Example: window.location.href = downloadUrl;
          })
          .catch(error => {
              // Handle fetch error
              console.error('Fetch error:', error);
          });
              */


}





export const documentPreview = (url, data) => {
    let params = {};
    if (data) {
        params = data;
    }

    return axios
        .get(
            url,
            {
                params: params,
                responseType: "arraybuffer",
            }
        )
        .then((response) => {
            const headers = response.headers || {};
            const contentType = headers["content-type"];

            if (!contentType) {
                console.error("Content type not found in headers.");
                return;
            }

            if (contentType.includes("application/pdf")) {
                // Handle PDF preview
                const url = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                window.open(url, '_blank');
            } else if (contentType.includes("image")) {
                // Handle image preview
                const url = URL.createObjectURL(new Blob([response.data], { type: contentType }));
                window.open(url, '_blank');
            }
            else {
                // Handle PDF preview
                const url = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                window.open(url, 'PopupWindow', 'width=600,height=400,toolbar=no,location=no,menubar=no,status=no,scrollbars=no,resizable=yes');
            }
        });
};
