import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useRecoilState} from 'recoil'
import {formState} from '../../../../../../recoil/profile-atom'
import {postApiCallDynamicWithOutReturn} from '../../../../../../services/api-service'
import {UPDATE} from '../../../../../../constants/constatnt-variable'
import {API} from '../../../../../../services/api-config'
import {DependentForm} from './dependent-form'

export default function LoanDependent() {

    const route = useNavigate()
    const location = useLocation();
    const partnerInfoPath = location.pathname.split("/")[location.pathname.split("/").length - 2]


    let [formValue, setFormValue] = useRecoilState(formState)

    let [dependent, setDependent] = useState(() => null);

    let [formValueId, setFormValueId] = useState(() => null);

    useEffect(() => {
        if (partnerInfoPath == "partner-info") {
            setDependent(formValue.partnerInfo?.page?.dependent?.subPage)
            setFormValueId(formValue.id)
        } else {
            setDependent(formValue.borrowerInfo?.page?.dependent?.subPage)
            setFormValueId(formValue.id)
        }
    }, [formValue.partnerInfo?.page?.dependent?.subPage, formValue.borrowerInfo?.page?.dependent?.subPage, formValue.id])


    const onSubmit = async data => {

        let path, updateState
        if (partnerInfoPath == "partner-info") {
            path = API.partnerInfoDependent
            updateState = {
                ...formValue,
                partnerInfo: {
                    ...formValue.partnerInfo,
                    page: {
                        ...formValue.partnerInfo.page,
                        dependent: {
                            ...formValue.partnerInfo.page.dependent,
                            subPage: data,
                            isComplete: true
                        }
                    }
                }
            }
        } else {
            path = API.borrowerInfoDependent
                updateState = {
                    ...formValue,
                    borrowerInfo: {
                        ...formValue.borrowerInfo,
                        page: {
                            ...formValue.borrowerInfo.page,
                            dependent: {
                                ...formValue.borrowerInfo.page.dependent,
                                subPage: data,
                                isComplete: true
                            }
                        }
                    }
                }
        }

        const apiCallData = {
            page: {
                subPage: data,
                isComplete: true
            },
            isComplete: true,
            formId: formValue.id
        }
        const setDependentInRecoil = () => {
            setFormValue(updateState);
            route(location.pathname.replace(/[^/]*$/, 'employment'))
        }
        await postApiCallDynamicWithOutReturn(
            {
                data: apiCallData,
                path: path,
                refresh: setDependentInRecoil,
                header: UPDATE,
            }
        )
    }

    return (
        <>
            {
                formValueId == null ?
                    <></>
                    :
                    <DependentForm partnerInfoPath={partnerInfoPath} data={dependent} onSubmit={onSubmit} />
            }
        </>
    )
}
