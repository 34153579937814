import {
    Box, Grid, Table, TableBody, TableCell, TableHead, TableRow,
    FormControlLabel, Checkbox, Typography, Button
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { getApiCallDynamic } from "../../../../services/api-service";
import { API } from "../../../../services/api-config";
//import { data } from "../../../borrower/loan-application/loan-pages/documents/data";
import VerifyOtpDialog from "../../../../helpers/otp-verification";
import AddUser from "./add-user";



import { useContext } from 'react';
import { BillContext } from '../BillContext';

const styles = {
    table: {
        borderCollapse: 'collapse', // Remove the separator lines
        width: '100%',
    },
    tableCell: {
        // padding: '0', // Remove padding from table cells
        borderBottom: 'none', // Remove bottom border from table cells
    },
    submitCell: {
        // padding: '0',
        //  textAlign: 'right', // Align the submit button to the right
        borderBottom: 'none',
    },
    rowHeading: {
        height: '10px',
        backgroundColor: '#F8FBFC', // Set the background color to grey
    },
};


function PaymentTerm() {



    const [isAddUserVisible, setIsAddUserVisible] = useState(false);
    const [shouldRenderAddUser, setShouldRenderAddUser] = useState(false);

    const { charges, discounts, loading, tndAcceptd, billUserCode, userCode,
        billing, isNewUser, ownerName, address, fetchData } = useContext(BillContext);



    useEffect(() => {
        fetchData();
    }, [userCode]);




    const [isChecked, setIsChecked] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);



    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };



    const handleAcceptTAndC = () => {
        if (isChecked) {
            //  OTP verification dialog when the "Accept T&C" button is clicked and the checkbox is checked
            setIsDialogOpen(true);
        } else {
            console.log('Please accept the Terms & Conditions.');
        }
    };

    const handleDialogClose = () => {
        // Close the OTP verification dialog
        setIsDialogOpen(false);
        console.log("Verification Done"

        )
    };


    const handleVerifySuccess = () => {
        setIsAddUserVisible(!isAddUserVisible); // render AddUser component
        setShouldRenderAddUser(!shouldRenderAddUser);
    };



    const chargesColumns = [
        {
            accessorKey: "role",
            header: "Role",
            enableColumnActions: false,
            enableSorting: false,
        },
        {
            accessorKey: "amount",
            header: "Amount",
            enableColumnActions: false,
            enableSorting: false,
        },
    ];

    const discountsColumns = [
        {
            accessorKey: "tier",
            header: "Tier",
            enableColumnActions: false,
            enableSorting: false,
        },
        {
            accessorKey: "range",
            header: "Range",
            enableColumnActions: false,
            enableSorting: false,
        },
        {
            accessorKey: "discount",
            header: "Discount",
            enableColumnActions: false,
            enableSorting: false,
        },
    ];

    return (
        <>

            <Grid container direction="row">
                <Grid item sm={12} md={7} my={1} overflow="auto">
                    <Typography variant="h5" fontWeight={700} display={'list-item'} color={'var(--Description-Grey, rgba(34, 34, 34, 0.75))'} marginLeft={'1rem'}>Charges</Typography>
                    <Table>
                        <TableHead>
                            <TableRow style={{ backgroundColor: '#F8FBFC' }}>
                                <TableCell style={styles.tableCell}><strong>Role</strong></TableCell>
                                <TableCell style={styles.tableCell}><strong>Amount</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={2} align="center">
                                        Loading...
                                    </TableCell>
                                </TableRow>
                            ) : charges.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={2} align="center">
                                        No data available.
                                    </TableCell>
                                </TableRow>
                            ) : (
                                charges.map((item) => (
                                    <TableRow key={item.role}>
                                        <TableCell style={styles.tableCell}>{item.role}</TableCell>
                                        <TableCell style={styles.tableCell}>$ {item.amount} Per Month</TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item sm={12} md={7} my={1} overflow="auto">
                    <Typography variant="h5" fontWeight={700}>User specific discounts</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={styles.tableCell}><strong>Tier</strong></TableCell>
                                <TableCell style={styles.tableCell}><strong>Range</strong></TableCell>
                                <TableCell style={styles.tableCell}><strong>Discount</strong></TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={3} align="center">
                                        Loading...
                                    </TableCell>
                                </TableRow>
                            ) : discounts.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={3} align="center">
                                        No data available.
                                    </TableCell>
                                </TableRow>
                            ) : (
                                discounts.map((item) => (
                                    <TableRow key={item.role}>
                                        <TableCell style={styles.tableCell}>{item.tier}</TableCell>
                                        <TableCell style={styles.tableCell}>{item.rangeMin} - {item.rangeMax}</TableCell>
                                        <TableCell style={styles.tableCell}>{item.discount} % discount</TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>

            <Box sx={{ paddingTop: 5 }}>

                <Box sx={{ marginBottom: 2 }}>
                    <Typography >
                        <span style={{ textDecoration: "underline" }}>Note:</span> Active users are calculated in each month. An active user is a user who logs in at least once in a month.

                    </Typography>
                </Box>


                <div>
                    {loading ? (
                        <p>Loading...</p>
                    ) : isNewUser === "Yes" ? (
                        <div>

                            {tndAcceptd && (
                                <Typography>
                                    You have already agreed to the terms and conditions on {tndAcceptd}. No further action is required at this time.
                                </Typography>
                            )}
                        </div>
                    ) : (
                        <div>

                            <Box mt={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isChecked}
                                            onChange={handleCheckboxChange}
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <Typography>
                                            By clicking the button, you confirm that you have read and agree to the terms and conditions.     </Typography>
                                    }
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!isChecked}
                                    onClick={handleOpenDialog}
                                >
                                    Accept T&C
                                </Button>


                                {isDialogOpen && <VerifyOtpDialog onClose={handleDialogClose} onVerifySuccess={handleVerifySuccess} />}

                                {isAddUserVisible && <AddUser userCode={userCode} shouldRender={shouldRenderAddUser} />}
                            </Box>
                        </div>
                    )}
                </div>

            </Box>

        </>
    );
}

export default PaymentTerm;
