import React from "react";

import { Box, styled } from "@mui/material";
import { AlertSeverity } from "../helpers/alert-msg";

//drawerHeader style
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function MainLoyOut({ p = { md: 3, xs: 2 }, ...props }) {
  return (
    <Box p={p} name={"mainLayout"} position={"relative"}>
      {/* Showing the alert notification on top of main layout */}
      <AlertSeverity />
      <Box position={"relative"}>{props.children}</Box>
    </Box>
  );
}
