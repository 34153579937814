import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import submitImage from '../../../../../assets/loan-submit.png'
import { formState } from '../../../../../recoil/profile-atom';
import { useRecoilState } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
import { API } from '../../../../../services/api-config';
import { UPDATE } from '../../../../../constants/constatnt-variable';
import { postApiCallDynamicWithOutReturn } from '../../../../../services/api-service';
import ConfirmationModel from '../../../../../helpers/modal/confirm-modal';

export default function LoanSubmit() {

    const location = useLocation();
    const route = useNavigate();

    let [formValue, setFormValue] = useRecoilState(formState)

    const goToReview = () => {
        route(location.pathname.replace(/[^/]*$/, 'review'))
    }

    const handleSubmit = async () => {
        const apiCallData = {
            page: {},
            formId: formValue.id,
            isComplete: true
        }

        const updateState = () => {
            setFormValue({
                ...formValue,
                submit: {
                    ...formValue.submit,
                    page: {},
                    isComplete: true,
                },
                isLocked: true
            })
            route(location.pathname.replace(/[^/]*$/, 'review'));
        }

        await postApiCallDynamicWithOutReturn(
            {
                data: apiCallData,
                path: API.submit,
                header: UPDATE,
                refresh: updateState
            }
        )
    }

    return (
        <>
            <Box
                height={'100%'}
                sx={{
                    backgroundImage: `url(${submitImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionX: 'right',
                    backgroundPositionY: 'bottom',
                    backgroundSize: 'contain'
                }}
            >
                <Box py={2} px={4}>
                    <Typography variant='h2' mb={2}>{`Hey, ${formValue.borrowerInfo?.page?.personal?.subPage?.firstName} ${" "} ${formValue?.borrowerInfo?.page?.personal?.subPage?.lastName}! You are almost there.`}</Typography>
                    <Typography variant='body1' mb={2}>{`You're almost there! secure your homeownership journey - review and submit your mortgage application today to begin building your dream.`}</Typography>

                    <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 2, md: 4 }} mt={{ xs: 3, md: 6 }}>
                        <Button onClick={() => { goToReview() }} variant='outlined' >{`Let me review it again!`}</Button>
                        <ConfirmationModel
                            body={<Button variant='contained' fullWidth>{`SUBMIT MY APPLICATION`}</Button>}
                            handleSubmit={
                                handleSubmit
                            }
                            message={
                                `Once you submit the application, you can't able to edit it. Are you sure you want to submit the application? `
                            }
                            title={"Confirmation"}
                        />

                    </Stack>

                </Box>
            </Box >
        </>
    )
}
