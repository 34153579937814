import React, { useEffect, useState } from "react";

import { Button, InputAdornment, Stack, } from "@mui/material";
import Grid from "@mui/material/Grid";
import CustomInput from "../../../helpers/custom-input";

import { ROLE_LIST, TEAM_INVITATION_ROLE, TEAM_INVITATION_ROLE_MLP } from "../../../constants/constatnt-variable";
import CustomDropDown from '../../../helpers/dropdown/custom-dropdown'
import { KSMailIcon, KSPhoneIcon } from "../../../icons/custome-icon";
import { Controller, useForm } from "react-hook-form";
import { TeamInvitationValidation } from "../../../constants/validation-rules";
import { useRecoilValue } from "recoil";
import { profileState } from "../../../recoil/profile-atom";

export default function TeamInvitationForm({ onSubmit, data, handleClose }) {

  let profileInfo = useRecoilValue(profileState);
  const [stateRow, setStateRow] = useState([])
  const [invitationMessage, setInvitationMessage] = useState('');
  const { control, setValue, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      id: data?.id,
      role: data?.role,
      firstName: data?.firstName,
      lastName: data?.lastName,
      phone: data?.phone,
      email: data?.email,
      stateCode: data?.state,
      invitationMessage: data?.message,

    }
  })
  useEffect(() => {
    const states = []
    profileInfo.stateLicenses.map((i) => {
      if (i.status === "valid") {
        states.push({
          value: i.state,
          label: i.state,
        })
      }
    })
    setStateRow(states)
  }, [])

  const handleInvitationMessageChange = (event) => {
    setInvitationMessage(event.target.value);
  };

  const remainingChars = 500 - invitationMessage.length;



  const [formatWorkPhone, setFormatWorkPhone] = useState(formatPhoneNumber(data?.phone));
  const [unformatWorkPhone, setUnformatWorkPhone] = useState(data?.phone || '');


  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) return '';
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumber;
  }

  function handleWorkPhoneChange(event) {
    let unformattedValue = event.target.value.replace(/\D/g, '');

    if (unformattedValue.length > 10) {
      unformattedValue = unformattedValue.slice(0, 10);
    }

    setUnformatWorkPhone(unformattedValue);
    setFormatWorkPhone(formatPhoneNumber(unformattedValue));
  }

  function handleWorkPhoneChangeDB() {
    setValue('phone', unformatWorkPhone)
  }












  return (

    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
        <Controller
          name='role'
          control={control}
          rules={TeamInvitationValidation.role}
          render={({ field }) => (
            <CustomDropDown
              {...field}
              field={field}
              label={'Role'}
              required
              option={profileInfo.role == ROLE_LIST.mloOwner ? TEAM_INVITATION_ROLE_MLP : TEAM_INVITATION_ROLE}
              error={errors?.role?.message}
            />
          )}
        />
      </Grid>
      <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
        <Controller
          name='firstName'
          control={control}
          rules={TeamInvitationValidation.firstName}
          render={({ field }) => (
            <CustomInput
              {...field}
              label={"First Name"}
              required
              size="small"
              fullWidth
              placeholder="Enter the name of the person "
              error={errors?.firstName?.message}
            />
          )}
        />
      </Grid>

      <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
        <Controller
          name='lastName'
          control={control}
          rules={TeamInvitationValidation.lastName}
          render={({ field }) => (
            <CustomInput
              {...field}
              label={"Last Name"}
              required
              size="small"
              fullWidth
              placeholder="Enter the name of the person "
              error={errors?.lastName?.message}
            />
          )}
        />
      </Grid>
      <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
        <Controller
          name='phone'
          control={control}
          rules={TeamInvitationValidation.phone}
          render={({ field }) => (
            <CustomInput
              {...field}
              label={"Phone Number"}
              required
              size="small"
              fullWidth
              placeholder="(655)349-4367"
              sicon={
                <InputAdornment position="start">
                  <KSPhoneIcon />
                </InputAdornment>
              }
              type={"text"}
              error={errors?.phone?.message}
              value={formatWorkPhone}
              onChange={handleWorkPhoneChange}
              onBlur={handleWorkPhoneChangeDB}
            />
          )}
        />
      </Grid>
      <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
        <Controller
          name='email'
          control={control}
          rules={TeamInvitationValidation.email}
          render={({ field }) => (
            <CustomInput
              {...field}
              label={"Email Id"}
              required
              sicon={<KSMailIcon />}
              size="small"
              disabled={data}
              fullWidth
              placeholder="Enter recipent Email Id"
              error={errors?.email?.message}
            />
          )}
        />
      </Grid>
      {profileInfo.role === ROLE_LIST.broker &&
        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
          <Controller
            name='stateCode'
            control={control}
            render={({ field }) => (
              <CustomDropDown
                {...field}
                field={field}
                label={'State'}
                required
                option={stateRow}
              />
            )}
          />
        </Grid>
      }
      <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
        <Controller
          name='invitationMessage'
          control={control}
          render={({ field }) => (
            <div>
              <CustomInput
                {...field}
                label={"What can we help you with?"}
                size="large"
                fullWidth
                placeholder={`Add Note`}
                multiline
                // rows={5}
                // rowsMax={7}
                value={invitationMessage}
                onChange={handleInvitationMessageChange}
              />
              <span>{remainingChars} characters remaining</span>
            </div>
          )}
        />
      </Grid>

      <Stack direction={'row'} justifyContent={'end'} gap={2}>
        <Button onClick={handleClose} variant="outlined">
          {'Cancel'}
        </Button>
        <Button type="submit" variant="contained">
          {data ? "Update" : "Send"}
        </Button>
      </Stack>
    </form>
  );
}
