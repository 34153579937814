import { Box, Button, Grid, IconButton, Typography, } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { KSPencilAltIcon, KSTrashIcon } from "../../../../icons/custome-icon";
import { TimeStampToMonthDateYear } from "../../../../helpers/time-formater";
import { deleteApiCallDynamic, postApiCallDynamicWithOutReturn } from "../../../../services/api-service";
import CustomReactMaterialTable from "../../../../helpers/table/react-material-table";
import ConfirmationModel from "../../../../helpers/modal/confirm-modal";
import { DialogCommonHooks } from "../../../../helpers/modal/modal";
import { CREATE, UPDATE } from "../../../../constants/constatnt-variable";
import MainLoyOut from "../../../../components/main-layout";
import LoanNoteForm from "./loan-note-form";
import { API } from "../../../../services/api-config";
import { useRecoilState } from "recoil";
import { globalSpinner } from "../../../../recoil/profile-atom";

export default function LoanNotes() {

  const [tableReload, setTableReload] = useState(() => false);

  const location = useLocation();

  let [spinner, setSpinner] = useRecoilState(globalSpinner);
  const formId =
    location.pathname.split("/")[location.pathname.split("/").length - 2];

  const [openModal, setOpenModal] = useState(false);

  const [action, setAction] = useState(CREATE);

  const [tableData, setTableData] = useState("");

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => {
    setOpenModal(false);
    setAction(CREATE);
    setTableData("");
    setSpinner(false)
  };

  const handleEdit = (cell, row) => {
    handleOpen();
    setTableData(row?.original);
    setAction(UPDATE);
  };

  const deleteNote = (row) => {
    let apiData = {
      id: row.original.id,
      formId: formId,
    };

    deleteApiCallDynamic(
      {
        refresh: () => {
          setTableReload((prev) => !prev);
        },
        path: API.loanNote,
        data: apiData
      }
    ).then(r => {

    })

  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "date",
        header: "Date.",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          return (
            TimeStampToMonthDateYear(cell.getValue())
          );
        },
      },
      //column definitions...
      {
        accessorKey: "note",
        header: "Note",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell, column, row, table }) => {
          console.log(" logg" + row.original); // Add this line to log the row.original object
          return (
            <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>{`${row.original.note}`}</Typography>
          );
        },
      },

      {
        accessorKey: "note",
        header: "Action",
        enableColumnActions: false,
        enableSorting: false,

        Cell: ({ cell, column, row, table }) => {
          return (
            <Box display={"flex"}>
              <IconButton onClick={() => {
                handleEdit(cell, row);
              }} >
                <KSPencilAltIcon />
              </IconButton>

              <ConfirmationModel
                body={
                  <IconButton>
                    <KSTrashIcon />
                  </IconButton>
                }
                handleSubmit={() => {
                  deleteNote(row);
                }}
                message={`Are you sure you want to delete the note?`}
                title={"Confirmation"}
              />
            </Box>
          );
        },
      },

      //end
    ],
    []
  );


  const handleNoteSubmit = async data => {
    setSpinner(true)
    let apiCallData = Object.assign(data, {
      formId: formId,
    });
    // console.log("data", data, apiCallData);
    await postApiCallDynamicWithOutReturn({
      data: apiCallData,
      path: API.loanNote,
      refresh: handleClose,
      header: action == UPDATE ? UPDATE : null
    })
  };

  return (
    <MainLoyOut>
      <Box mx={{ md: 15 }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <CustomReactMaterialTable
              endPoint={API.loanNote}
              params={{ formId: formId }}
              refetch={tableReload}
              columns={columns}
              enablePagination={false}
              enableToolbarInternalActions={false}
              renderTopToolbarCustomActions={(table) => (
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    onClick={handleOpen}
                  >
                    Add More
                  </Button>
                </Box>
              )}
            />
          </Grid>
          <DialogCommonHooks
            // title="Add Note"
            title={`${action === CREATE ? "Add" : "Edit"} Note`}
            show={openModal}
            handleClose={handleClose}
            body={
              <LoanNoteForm
                data={tableData}
                action={action}
                onSubmit={handleNoteSubmit}
              />
            }
          />
        </Grid>
      </Box>
    </MainLoyOut>
  );
}
