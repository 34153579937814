import {
  Box,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { kstheme } from "../../theme/theme";

const customStyle = makeStyles({
  ContentHeader: {
    fontFamily: "Inter-Medium !important",
    fontSize: "20px",
    color: "#221F1F",
  },
  ContentBody: {
    fontFamily: "DMSans-Regular !important",
    fontSize: "20px",
    color: "#000000",
    opacity: 0.8,
    textAlign: "justify",
  },
  ContentUnderline: {
    fontFamily: "DMSans-Regular !important",
    fontSize: "16px",
    color: "#000000",
    opacity: 0.8,
    textDecoration: "underline",
  },

  ContentHeader2: {
    fontFamily: "DMSans-Regular !important",
    fontSize: "20px !important",
    color: "#221F1F",
  },
});

const listText = [
  "Identifiers (For example, name and email address).",
  "Commercial information (For example, transaction data).",
  "Internet or other network or device activity (For example, browsing history and log data).",
  "Professional or employment-related information (For example, employment history when you apply for a job with us).",
  "Education information (For example, when you apply for a job with us).",
  "Location information (For example, general location information inferred from an IP address), and",
  "Other information that identifies or can be reasonably associated with you.",
];

const listText2 = [
  "To handle and react to your inquiries or to solicit feedback, including via our customer support services;",
  "To process and respond to your inquiries or to request your feedback, including through our customer support services;",
  "For marketing and advertising purposes, including contacting you with information or surveys we feel may be of interest to you about our Website, our goods and services, and third-party products and services;",
  "Internal research, analytics, and reporting, including but not limited to synthesizing and deriving insights from your usage of the Website;",
  "To enhance the content and features of the Website and our other goods or services; to develop or deliver new products or services;",
  "To customize the content you see on the Website;",
  "To manage and troubleshoot the website;",
  "To review and process your employment applications;",
  "To comply with laws, regulations, and other legal processes and procedures;",
  "To establish, exercise, or defend our legal rights; and",
  "To take steps that we reasonably believe are necessary to protect Kramasoft, its employees, service providers, and others safety, security, and rights.",
];

function PrivacyPolicy() {
  const classes = customStyle();
  const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));
  const searchParams = new URLSearchParams(window.location.href);
  let scrollId = null;
  for (const p of searchParams) {
    if (p[1] == "california-residents") {
      scrollId = p[1]
    }
  }
  // console.log(document.getElementById(scrollId), scrollId, 'resdident')
  useEffect(() => {
    if (scrollId) {
      document.getElementById(scrollId).scrollIntoView({ behavior: 'smooth' })
    }
  })
  return (
    <>
      <Box pt={4} id={"privacyPolicy"} style={{ backgroundColor: '#fff' }}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} md={12} px={{ xs: 2 }} mb={6}>
              <Typography variant="h2" color={"primary.main"} my={5} fontWeight={700}>
                Privacy Policy
              </Typography>

              <Box>
                <Typography className={classes.ContentBody}>
                  Effective/Updated date: December 1, 2022
                </Typography>
                <Typography className={classes.ContentBody}>
                  {`This Privacy Policy ("Policy") defines how Kramasoft LLC. ("Kramasoft," "we," "us," or "our") collects uses and discloses information about users of the Kramasoft website (https://Kramasoft.com/) ("Website"). We also explain your rights and options regarding our use of your information. "You" and "your" refer to the user of our Website for the purposes of these Terms. Your use of our Website is governed by this Policy and our Terms of Service.`}
                </Typography>
                <Typography className={classes.ContentBody}>
                  {`PLEASE CAREFULLY READ THIS PRIVACY POLICY TO UNDERSTAND HOW WE HANDLE YOUR INFORMATION. PLEASE DO NOT USE THE WEBSITE IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY. By using any section of the Website, you agree to the collection, use, and dissemination of the information you give in accordance with the terms of this Policy.`}
                </Typography>
                <Typography className={classes.ContentBody}>
                  {`This Policy does not apply to CliqLoan Platform users. The Kramasoft Platform Privacy Policy governs Kramasoft's usage of data given through the CliqLoan Platform.`}
                </Typography>
                <Typography className={classes.ContentBody} mb={2}>
                  {`If you are a California resident, please see our Notice at Collection for California Residents.`}
                </Typography>
              </Box>

              <Box>
                <Typography mt={6} className={classes.ContentHeader2} style={{ fontSize: "20px" }}>
                  {`(1) What Information Does Kramasoft Collect?`}
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  {`We acquire information in a variety of ways, including when
                  you supply it directly to us and when we passively collect
                  data from you, such as through your browser or device used to
                  access our Website.`}
                </Typography>
                <Typography className={classes.ContentHeader2}>
                  {`(A) Categories of Information`}
                </Typography>
                <Typography mb={2} className={classes.ContentBody}>
                  {`We may collect the following types of information about you or
                  your use of the Website, as detailed further below and
                  depending on how you use the Website and interact with us:`}
                </Typography>
                <Box>
                  <List
                    sx={{
                      listStyleType: "disc",
                      pl: 3,
                    }}
                  >
                    {listText.map((item) => (
                      <ListItem
                        className={classes.ContentBody}
                        style={{ fontSize: '16px' }}
                        key={`item-${listText}-${item}`}
                        sx={{
                          padding: 0,
                          textAlign: "justify",
                          marginBottom: 1,
                          display: "list-item",
                          "&.MuiListItem-root::marker": {
                          },
                        }}
                      >
                        {item}
                      </ListItem>
                    ))}
                  </List>
                </Box>

                <Typography className={classes.ContentHeader2} my={3}>
                  (B) Categories of Information Sources
                </Typography>
                <Typography className={classes.ContentHeader2}>
                  (i) Information You Provide to Us
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  We may gather information about you when using or accessing
                  the Website, such as when you:
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  · Request a demo of or ask about a Kramasoft product or
                  service; <br />
                  · Enter information on the Website (for example, to subscribe
                  to our blog, enter your email address); <br />· Contact us
                  (including via our customer support services or third-party
                  social media sites); or
                </Typography>
                <Typography className={classes.ContentHeader2}>
                  Apply for a job with Kramasoft.
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  We will gather whatever information you choose to provide us.
                  We normally gather your name, email address, phone number, and
                  information about your contacts with us.
                </Typography>
                <Typography className={classes.ContentHeader2}>
                  (ii) Information Collected Automatically
                </Typography>
                <Typography className={classes.ContentBody}>
                  Device/Usage Information: We may automatically collect certain
                  information about the computer or devices you use to access
                  the Website (including mobile devices or tablets). We may
                  collect and analyze information such as
                </Typography>
                <Typography className={classes.ContentBody}>
                  (a) IP addresses (including city and state information),
                  unique device identifiers, browser types, browser language,
                  operating system, and mobile device carrier information, as
                  further stated below. and
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  (b) information about how you interact with the Website, such
                  as referring and exiting web pages and URLs, platform type,
                  the number of clicks, domain names, pages, and content viewed
                  and the order in which those pages are viewed, statistical
                  information about how you use the Website, error logs, and
                  other similar information. We may utilize third-party
                  analytics providers and technologies, such as cookies and
                  similar techniques, to assist us in gathering this
                  information, as detailed further below.
                </Typography>
                <Typography className={classes.ContentBody}>
                  Cookies and Other Electronic Technologies: We may also collect
                  information about your use of the Website via internet server
                  logs, cookies, and/or tracking pixels. A web server log is a
                  file that records website activities. A cookie is a small text
                  file that is placed on your computer when you visit a website,
                  allowing us to:
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  (i) recognize your computer. <br />
                  (ii) store your preferences and settings. <br />
                  (iii) understand the web pages of the Website you have
                  visited. <br />
                  (iv) improve your user experience by delivering content
                  tailored to your interests <br />
                  (v) perform analytics; and (vi) assist with security
                  administrative functions.
                </Typography>
                <Typography className={classes.ContentBody}>
                  Some cookies are stored in the cache of your browser. Tracking
                  pixels (also known as web beacons or clear GIFs) are tiny
                  electronic tags with a unique identifier that are embedded in
                  websites, online advertisements, and/or email and are designed
                  to provide usage information such as page clicks, measure the
                  popularity of the Website and associated content, and access
                  user cookies. We may amend this Policy from time to time when
                  new technologies are developed to collect additional
                  information through different means.
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  {`Please keep in mind that you may alter your settings to be notified when a cookie is placed or modified, or to prevent cookies entirely. For further information, please see your browser's "Help" section (e.g., Internet Explorer; Google Chrome; Mozilla Firefox; or Apple Safari). Please keep in mind that if you disable some or all cookies, you may be unable to access certain Website features or products.`}
                </Typography>

                <Typography className={classes.ContentHeader2}>
                  (iii) Information from Third Parties
                </Typography>
                <Typography className={classes.ContentBody}>
                  Our Website may contain social networking elements such as
                  Facebook or Twitter buttons, as well as interactive
                  mini-programs that operate on our Website. These features may
                  gather your IP address and the page you are now viewing on our
                  Website, as well as establish a cookie to enable the feature
                  to work correctly. Social media tools and widgets are hosted
                  by either a third party or directly on our Website. The
                  privacy statement of the firm that provides these features
                  governs your interactions with them.
                </Typography>
                <Typography className={classes.ContentBody}>
                  We may also get additional information about you from other
                  parties, such as business partners, marketers, researchers,
                  analysts, and others, which we may combine with information
                  acquired directly from you or attribute to you based on your
                  classification into particular statistical groups.
                </Typography>
              </Box>

              <Box>
                <Typography mb={3} mt={6} className={classes.ContentHeader2}>
                  (2) How Will Kramasoft Use My Information?
                </Typography>
                <Typography className={classes.ContentBody}>
                  We and our service providers may use the information we
                  collect from and about you for the following business and
                  commercial reasons (as defined by relevant law):
                </Typography>
                <Box>
                  <List
                    sx={{
                      listStyleType: "disc",
                      pl: 3,
                    }}
                  >
                    {listText2.map((item) => (
                      <ListItem
                        className={classes.ContentBody}
                        style={{ fontSize: '16px' }}
                        key={`item-${listText2}-${item}`}
                        sx={{
                          padding: 0,
                          textAlign: "justify",
                          marginBottom: 1,
                          display: "list-item",
                          "&.MuiListItem-root::marker": {
                          },
                        }}
                      >
                        {item}
                      </ListItem>
                    ))}
                  </List>
                </Box>
                <Typography mb={3} className={classes.ContentBody}>
                  We may also aggregate, de-identify, and/or anonymize any
                  information gathered through the Website so that it cannot be
                  meaningfully linked to you or your device. We may use such
                  aggregated, de-identified, or anonymized data for any purpose,
                  including, but not limited to, research and marketing.
                </Typography>
              </Box>

              <Box>
                <Typography mb={3} mt={6} className={classes.ContentHeader2}>
                  (3) How Does Kramasoft Personalize Content to My Interests?
                </Typography>
                <Typography mb={3} className={classes.ContentHeader2}>
                  Online and Email Analytics
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  On the Website, we may utilize third-party web analytics
                  services such as Google Analytics. These service providers
                  collect information (including your IP address) to help us
                  analyze how users use the Website, including by noting the
                  third-party website from which you arrived, providing certain
                  features to you, improving and developing the Website,
                  monitoring and analyze use the Website, aid our technical
                  administration, assist in our troubleshooting and customizing.
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  Install the Google Analytics Opt-out Browser Add-on to prevent
                  Google Analytics from utilizing your information for
                  analytics. If you obtain a new computer, install a new
                  browser, delete or otherwise change your browser&apos;s cookie
                  file (including updating certain browsers), you may wipe the
                  opt-out cookies and must re-visit the applicable opt-out page.
                </Typography>
                <Typography mb={2} className={classes.ContentBody}>
                  If you receive an email from us, we may use certain tools,
                  such as clear GIFs, to collect data such as when you open our
                  message or click on any links or banners our email contains.
                  This information helps us make our communications with you
                  more interesting and improve our Website.
                </Typography>

                <Typography mb={3} className={classes.ContentHeader2}>
                  Advertising
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  We work with third-party companies to either show advertising on our website or manage our advertising on other websites. Cookies or similar technologies may be used by a third-party partner to provide you with advertising based on your browsing activities and interests. To opt-out of interest-based advertising, please click here. Please keep in mind that you will continue to get generic advertisements.
                </Typography>

                <Typography mb={3} className={classes.ContentHeader2}>
                  Notice Concerning Do Not Track Signals
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>

                  Do Not Track (&quot;DNT&quot;) is a privacy setting that users can enable or disable in specific web browsers. We are dedicated to giving you meaningful choices regarding the information gathered through the Website for third-party purposes, which is why we give Google Analytics options. We do not, however, presently recognize or respond to browser-initiated DNT signals. You can learn more about DNT at <Link href='https://fpf.org/thank-you-for-visiting-allaboutdnt-com/' variant="span" style={{ color: "#2F80ED", textDecoration: 'underline' }}>All About Do Not Track.</Link>
                </Typography>

                <Typography mb={3} className={classes.ContentHeader2}>
                  (4) How Will Kramasoft Share My Information
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  We and our service providers may share the above-mentioned categories of information with the third-party categories listed in this section.
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  Subsidiaries and affiliates
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  We may disclose information collected from you to current and
                  future affiliates and subsidiaries of the Kramasoft group of
                  companies.
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  Service Providers and Other Third-Parties.
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  We may give access to or share your information with selected
                  third parties that perform services on our behalf, such as
                  marketing, market research, customer service, data storage,
                  analysis, processing, and legal services.
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  Protection of Kramasoft and Others
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  By using the Website, you acknowledge, consent, and agree that
                  we may access, preserve, and disclose your information as
                  required by law or in the good faith belief that such access,
                  preservation, or disclosure is reasonably necessary to
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  (a) comply with legal process. <br />
                  (b) enforce our Terms of Use, this Policy, or other contracts
                  with you, including investigation of potential violations
                  thereof. or <br />
                  (c) respond to claims that any content violates the rights of
                  third parties; <br />
                  (d) respond to your requests for customer service; and/or{" "}
                  <br />
                  (e) protect the rights, property, or personal safety of
                  Kramasoft, its agents and affiliates, and/or its users and/or
                  the public. This involves exchanging information with other
                  businesses and organizations for the sake of fraud prevention,
                  spam/malware prevention, and other similar goals. <br />
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  Business Transfers
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  {`We may purchase or combine with other firms as our business grows. Your information may be among the transferred assets in such transactions (including in the course of such transactions, e.g., due diligence). If a portion or all of Kramasoft's assets are sold or transferred to a third party as part of a corporate purchase, sale, or merger, your information may be one of the transferred company assets.`}
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  Consent
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  We may also share your information with third parties with
                  your permission. Kramasoft may also share aggregated,
                  de-identified, and/or anonymized information with third
                  parties for any purpose, including, but not limited to,
                  advertising and promotional partners.
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  We use technological, administrative, and physical safeguards to secure your data against accidental or illegal destruction, loss, modification, unauthorized disclosure, or access. By using our Website, you recognize and agree that we cannot guarantee the security of any information you submit to, through, or on our Website, and that any such communication is done at your own risk. If you have any concerns concerning the security of your data, please contact us at hr@Kramasoft.com.
                </Typography>

                <Typography mt={6} className={classes.ContentHeader2}>
                  (5) What Am I Entitled To?
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  We and our service providers may share the above-mentioned
                  categories of information with the third-party categories
                  listed in this section.
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (A) Getting to, Updating, and Deleting Your Data.
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  In line with current legal requirements, you may be able to
                  view, update, or remove some information we store on you.
                  Please email us at hr@Kramasoft.com if you want to seek access
                  to or deletion of your information. We will react to your
                  inquiry as soon as possible.
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (B) Marketing Communications and Sharing.
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  By emailing us at hr@Kramasoft.com, you can instruct us not to
                  use your contact information to contact you with marketing
                  messages about products, services, promotions, and special
                  events that may be of interest to you. You may also opt out of
                  commercial email communications by following the instructions
                  at the bottom of such emails. Please keep in mind that,
                  regardless of your request, we may continue to use and
                  distribute some information in accordance with this Policy or
                  as required by applicable law. You cannot, for example, opt
                  out of some operational emails, such as those pertaining to
                  our relationship or transactions with you.
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (C) Nevada Residents.
                </Typography>
                <Typography mb={3} className={classes.ContentBody} id="california-residents">
                  {`Certain Nevada customers may opt out of the sale of "personally identifiable information" for a monetary benefit to a person in exchange for that person licensing or selling such information to further individuals under Nevada law. "Personally identifiable information" comprises a person's first and last name, address, email address, phone number, Social Security Number, or any other identifier that enables a specific individual to be reached physically or online. We do not engage in such activity; but, if you are a Nevada resident who has purchased or leased products or services from us, you may email hr@Kramasoft.com to seek to opt-out of any potential future sales under Nevada law. Please keep in mind that we will take reasonable efforts to verify your identity and the request's legitimacy. Once verified, we will maintain your request in the event our practices change.`}
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (D) California Residents.
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  {`This part of the Policy only applies to residents of the state of California ("consumers" or "you"). This statement is provided in accordance with the California Consumer Privacy Act of 2018 ("CCPA") and other California privacy laws. Any terminology used in this Notice that is not specified in the Notice is defined under the CCPA and its accompanying rules.`}
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (i) Notice at Collection for California Residents
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  {`(a) What Information We Collect: We collect the information categories listed above in the Policy's "What Information Does Kramasoft Collect?" section. The specific information we gather about you is determined by the products/services you use as well as how you use our Website and interact with us. The information in this declaration is based on our data-collecting activities from the preceding 12 months to the present.`}
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  {`(b) How We Utilize Your Information: We use your information for the business or commercial objectives indicated above in the part of the Policy titled "How Will Kramasoft Use My Information." What products/services you use, as well as how you use our Website and interact with us, determine how we utilize your information. The information in this declaration is based on our data-collecting activities from the preceding 12 months to the present.`}
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  (c) Kramasoft does not “sell” your personal information as
                  defined by the California Consumer Privacy Act and its
                  implementing regulations
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  {`(d) Privacy Policy: California law requires us to disclose the following information in our Privacy Policy, among other things: (1) the information we collect about you, (2) the categories of sources from which we collect information about you, and (3) the business or commercial purposes for which we use the information we collect about you. To access this information, please check the sections above in the Policy under "What Information Does Kramasoft Collect," "Categories of Information Sources," and "How Will Kramasoft Use My Information."`}
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (ii) Right to Know:
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  {`California law gives California residents the right to request that certain covered businesses provide them with I the categories of personal information we collect, use, disclose, or sell about you; (ii) the categories of sources of such information; (iii) the business or commercial purpose for collecting or selling your personal information (as applicable); and (iv) the categories of personal information we sell, have sold, or disclosed for a business purpose about the consumer and, (v) specific pieces of personal information that a business has collected about the consumer. Certain limitations apply to this "Right to Know." Furthermore, Kramasoft is not required to respond to requests for the Right to Know from users acting as an employee, owner, director, officer, or contractor of another entity or within the context of another entity in conducting due diligence regarding, or providing or receiving a product or service to or from such company.`}
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (iii) Right to Delete:
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  {`California law gives California residents the right to request that some covered entities remove personal information obtained from them. Certain caveats apply to this "Right to Delete." Furthermore, Kramasoft is not required to respond to requests for the Right to Know made by users acting as an employee, owner, director, officer, or contractor of another entity, or in the context of another entity conducting due diligence on, or providing or receiving a product or service from, such company. Please keep in mind that we require certain sorts of information in order to offer you our services. If you request that your information be deleted and we are forced to do so, you may no longer be able to access it or use the services we provide.`}
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (iv) Kramasoft does not sell your data:
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  {`While the CCPA gives California residents the right to opt-out of the "sale" of their personal information, Kramasoft has not "sold" consumer personal information in the preceding 12 months and does not "sell" your personal information as defined by the California Consumer Privacy Act and its implementing regulations. Furthermore, Kramasoft is unaware that it sells the personal information of kids under the age of 16.`}
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (v) Authorized Agent:
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  When a consumer employs an authorized agent to make a request
                  to know or a request to delete, a company may ask the consumer
                  to perform the following (this does not apply if the consumer
                  has granted the authorized agent power of attorney in
                  accordance with Probate Code sections 4000 to 4465):
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  i. Obtain signed authorization from the authorized agent.
                  <br />
                  ii. Directly verify their own identity with the firm.
                  <br />
                  iii. Confirm with the company that they gave the authorized
                  agent authorization to submit the request.
                  <br />
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  A firm may decline a request from an authorized agent who
                  fails to provide documentation that the customer has allowed
                  them to act on their behalf. To submit an authorized request
                  on behalf of another individual under California law, please
                  go to https://Kramasoft.com/policies/personal-data-requests/.
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (vi) Additional Rights:
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  California residents have the right, under California Civil
                  Code Sections 1798.83-1798.84, to request from certain covered
                  businesses a notice (a) identifying the categories of personal
                  information (as defined by California law) that are shared
                  with third parties for their own marketing purposes and (b)
                  providing contact information for such third parties.
                  Kramasoft, on the other hand, does not share such personal
                  information for these reasons, as stated in our Privacy
                  Policy.
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (vii) Financial Incentives:
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  We do not provide monetary compensation for the collection of
                  your data. If this changes, we will contact you and ask your
                  permission to participate in this program before enrolling
                  you.
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (viii) Non-discrimination Rights:
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  You also have the right not to face discrimination if you
                  exercise some of your CCPA rights. We will not penalize you if
                  you utilize your CCPA:
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  i. Refuse to provide you with products or services.
                  <br />
                  ii. Charge you various prices or rates for products or
                  services, including via the provision of discounts or other
                  advantages or the imposition of penalties.
                  <br />
                  iii. Offer you a higher or lower level of goods or services.
                  <br />
                  iv. Suggest that you may be charged a different price or rate
                  for products or services, or that the level or quality of
                  goods or services be different.
                  <br />
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (ix) How additional data privacy regulations may influence
                  your capacity to seek data access or deletion:
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  Please keep in mind that your rights and choices about your
                  personal information are frequently governed by different
                  laws. Personal information gathered under other statutes, such
                  as the Fair Credit Reporting Act, the Grammy-Leach-Bliley Act,
                  and the California Financial Information Privacy Act, may be
                  excluded from access or deletion requests.
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (x) Contact us:
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  To exercise any of these rights, please send an email to
                  privacy@Kramasoft.com or go to
                  https://Kramasoft.com/policies/personal-data-requests/. Before
                  we can complete your request, we will need to verify your
                  identity. You can also appoint an authorized representative to
                  make a request on your behalf. To do so, you must give us a
                  signed power of attorney or other consent for the agent to act
                  on your behalf. You will still need to verify your
                  identification with us directly. If you have any questions
                  about your legal rights in California, please contact us at
                  privacy@Kramasoft.com.
                </Typography>

                <Typography mb={1} mt={6} className={classes.ContentHeader2}>
                  {`(6) What Is Kramasoft's Obligation Regarding Third-Party Links on the Website?`}
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  The Website may include connections to or &quot;from&quot; third-party websites, programs, and other services that are accessible to support the operation and functioning of the Website. Please keep in mind that we are not responsible for the privacy policies of other websites or services. If you provide information to any of those websites, your information is subject to their privacy policies. We advise our users to be careful when they browse third-party websites and/or leave the Website and to read the privacy policies of each site they visit that collects information about them.
                </Typography>

                <Typography mb={1} mt={6} className={classes.ContentHeader2}>
                  (7) What Is Kramasoft’s Policy on Children?
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  We do not intentionally collect or solicit personal information from anyone under the age of 13 (as defined by the &quot;Children&apos;s Online Privacy Protection Act&quot;). If we discover that we have obtained personal information from a child under the age of 13, we will take reasonable efforts to erase the information as soon as feasible. If you feel a child under the age of 13 has supplied us with personal information, please email privacy@Kramasoft.com.
                </Typography>

                <Typography mb={1} mt={6} className={classes.ContentHeader2}>
                  (8) Retention of Your Information
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  We store your information for no longer than is necessary for
                  processing purposes and/or to comply with relevant laws. The
                  amount of time we keep information depends on the purposes for
                  which it was acquired and used.
                </Typography>

                <Typography mb={1} mt={6} className={classes.ContentHeader2}>
                  (9) Employment Applications
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  The Website may have career sections where you may apply for jobs with Kramasoft. These employment services are not hosted by Kramasoft and are instead managed by third parties such as Lever, Inc. These third parties acquire information such as your contact information and employment credentials. They may also use cookies and other methods of data collection, but this information is solely used for analytics and to allow you to finish your application. Any information collected by Kramasoft from these third parties in connection with your application for employment with Kramasoft is treated in accordance with this Policy. Kramasoft will only use and disclose your information for employment reasons, such as evaluating you for a job opportunity or engaging with you about your interests, or as required by law. If you have any queries about how your employment application data is being handled, please visit our careers website or email us at privacy@Kramasoft.com.
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  You may also apply for jobs at Kramasoft by connecting with LinkedIn. Any information collected by Kramasoft from LinkedIn in connection with your application for employment at Kramasoft is treated in accordance with this Policy. Kramasoft will only use and disclose your information for employment reasons, such as evaluating you for a job opportunity or engaging with you about your interests, or as required by law.
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  Please review our Collection for California Applicants Notice if you are a California employment candidate.
                </Typography>

                <Typography mb={1} mt={6} className={classes.ContentHeader2}>
                  (10) Will Kramasoft Modify This Policy?
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  We retain the right to modify this Policy at any time to
                  reflect changes in the law, our data collecting and use
                  policies, Website features, or technological improvements.
                  Please visit this page for updates on a regular basis. Your
                  continued use of the Website after modifications to this
                  Policy are posted indicates that you accept such changes. If
                  our data privacy practices change materially, we will notify
                  you in compliance with any legal requirements.
                </Typography>
                <Typography
                  mb={1}
                  fontSize={"20px"}
                  fontFamily={"DMSans-Italic"}
                  color={"#22222295"}
                  fontWeight={"bold"}
                >
                  What Should I Do If I Have Concerns About This Privacy
                  Statement?
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  If you have any questions or issues about our Policy, please
                  contact us at privacy@Kramasoft.com and we will do our best to
                  address them.
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  Kramasoft LLC.
                  <br />
                  San Francisco, CA
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default PrivacyPolicy;
