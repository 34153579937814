import { ArrowBackIos, ArrowForwardIos, NavigateNext } from '@mui/icons-material'
import { Box, Breadcrumbs, Button, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import UploadDocumentCard from './upload-document-card'
import { getApiCallDynamic, postApiCallDynamicWithOutReturn, uploadFile } from '../../../../../services/api-service'
import { API } from '../../../../../services/api-config'
import { useRecoilState } from 'recoil'
import { formState } from '../../../../../recoil/profile-atom'
import { toastNotify } from '../../../../../helpers/alert-msg'
import { UPDATE } from '../../../../../constants/constatnt-variable'
import { Link as MLink } from '@mui/material';

export default function LoanGuidedDocuments({ partnerInfoPath }) {

    let [formValue, setFormValue] = useRecoilState(formState)

    //console.log(" hhhhhrk " + JSON.stringify(formValue, null, 2))

    let primaryBorrowerName
    let primaryBorrowerPartnerName

    //console.log("formValue?.borrowerType" + formValue?.borrowerType)
    let borrowerType = formValue?.borrowerType

    if (borrowerType !== 'Primary') {
        let primaryBorrowerFirstName = formValue?.getStarted?.page?.coBorrower[0]?.firstName
        let primaryBorrowerLastName = formValue?.getStarted?.page?.coBorrower[0]?.lastName

        primaryBorrowerName = primaryBorrowerFirstName ? " (" + primaryBorrowerFirstName + " " + primaryBorrowerLastName + ")" : " (" + primaryBorrowerLastName + ")";


        let primaryBorrowerPartnerFirstName = formValue?.getStarted?.page?.coborrowersPartner[0]?.firstName
        let primaryBorrowerPartnerLastName = formValue?.getStarted?.page?.coborrowersPartner[0]?.lastName

        primaryBorrowerPartnerName = primaryBorrowerPartnerFirstName ? primaryBorrowerPartnerFirstName + " " + primaryBorrowerPartnerLastName : primaryBorrowerPartnerLastName;



    }
    else {

        let primaryBorrowerFirstName = formValue?.getStarted?.page?.primaryBorrower?.firstName
        let primaryBorrowerLastName = formValue?.getStarted?.page?.primaryBorrower?.lastName

        primaryBorrowerName = primaryBorrowerFirstName ? " (" + primaryBorrowerFirstName + " " + primaryBorrowerLastName + ")" : " (" + primaryBorrowerLastName + ")";


        let primaryBorrowerPartnerFirstName = formValue?.getStarted?.page?.primaryBorrowerPartner?.firstName
        let primaryBorrowerPartnerLastName = formValue?.getStarted?.page?.primaryBorrowerPartner?.lastName

        primaryBorrowerPartnerName = primaryBorrowerPartnerFirstName ? primaryBorrowerPartnerFirstName + " " + primaryBorrowerPartnerLastName : primaryBorrowerPartnerLastName;



    }

    const route = useNavigate()
    const location = useLocation();



    let [documents, setDocuments] = useState(() => null)
    let [updateState, setUpdateState] = useState(() => false)



    useEffect(() => {
        getApiCallDynamic({ param: { formId: formValue?.id }, path: API.guidedDocument }).then((res) => {
            if (res?.status === 200) {
                setDocuments(res.data)
            }
        });


    }, [updateState])



    let doc = []
    documents ? documents.map(i => i.documents.map(j => { return doc.push(j.isSkipped) })) : ""


    const { control, handleSubmit, formState: { errors }, watch, setValue } = useForm({
        defaultValues: {
            checkbox: true
        }

    })




    const onSubmit = async (data, event) => {

        if (event.target.id == "main-document" && !doc.includes(null)) {

            let apiCallData = {
                page: documents,
                formId: formValue.id,
                isComplete: true
            }
            const updateState = () => {
                setFormValue({
                    ...formValue,
                    documents: {
                        ...formValue.documents,
                        page: {
                            ...formValue.documents.page,
                            guidedDocument: {
                                ...formValue.documents.page.guidedDocument,
                                subPage: apiCallData.page,
                                isComplete: true
                            }
                        }
                    }
                })
                route(location.pathname.replace(/[^/]*$/, 'additional-document'));
            }
            await postApiCallDynamicWithOutReturn(
                {
                    data: apiCallData,
                    path: API.guidedDocument,
                    refresh: updateState,
                    header: UPDATE,
                }
            )


        }

    }

    // const fileUpload = (event) => {

    //     let file = event.target.files[0]
    //     if (event.target.files) {
    //         uploadFile({ docId: event.target.id, file: file, path: API.proxyDocumentUpload }).then((res) => {

    //             if (res.status === 201) {
    //                 toastNotify("success", res.data.message);
    //                 setUpdateState((prev) => !prev)

    //             } else {
    //                 console.log(" acheived target")
    //                 // toastNotify("fail", res.data.message);
    //                 // setUpdateState((prev) => !prev)
    //             }


    //         })
    //     } else {
    //         console.log("not found");
    //     }

    // }
    const fileUpload = (event) => {
        let file = event.target.files[0];
        if (event.target.files) {
            uploadFile({ docId: event.target.id, file: file, path: API.proxyDocumentUpload })
                .then((res) => {
                    console.log("api status " + res.status);
                    if (res.status === 201) {
                        toastNotify("success", res.data.message);
                        setUpdateState((prev) => !prev);
                    } else {

                        toastNotify("fail", res.data.message);
                    }
                })
                .catch((error) => {

                    console.error("Upload failed", error);
                    toastNotify("error", "Not supported Format.");
                });
        } else {
            console.log("not found");
        }
    };


    if (documents == null) {
        return <></>
    }


    return (
        <>
            <form id='main-document' key={"main-document"} onSubmit={handleSubmit(onSubmit)}>
                <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                    <Breadcrumbs aria-label="breadcrumb"
                        separator={<NavigateNext fontSize="small" />}
                    >
                        <MLink underline="hover" color="inherit" href="#">
                            {`Documents`}
                        </MLink>
                        <Typography color="primary.main">Guided Documents</Typography>
                    </Breadcrumbs>
                    <Box mt={2}>
                        <Typography variant='h3' mb={1} mt={1}>{`Guided Documents`}</Typography>
                        <Typography mb={2} >{`Upload these documents`}</Typography>
                        <Typography mb={2} fontSize={17} color={'#D94166'}>{`Note: At least one document is mandatory to upload`}</Typography>
                        {
                            documents.map((item, index) => (
                                <UploadDocumentCard
                                    key={item.title}
                                    title={item.title}
                                    primaryBorrowerName={primaryBorrowerName}
                                    primaryBorrowerPartnerName={primaryBorrowerPartnerName}
                                    control={control}
                                    errors={errors}
                                    data={item.documents}
                                    description={item.description}

                                    fileUpload={fileUpload}
                                    setUpdateState={setUpdateState}

                                />
                            ))
                        }

                    </Box>

                    <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                        <Stack direction={'row'} justifyContent={'space-between'} >
                            <Link to={'../../liabilities'}>
                                <Button startIcon={<ArrowBackIos />}>
                                    Previous
                                </Button>
                            </Link>

                            <Button form='main-document' type='submit' endIcon={<ArrowForwardIos />}>
                                Save and Next
                            </Button>

                        </Stack>
                    </Box>
                </Stack>
            </form>
        </>
    )
}

