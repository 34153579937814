import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import { kstheme } from "../../theme/theme";

const customStyle = makeStyles({
  ContentHeader: {
    fontFamily: "Inter-Medium !important",
    fontSize: "20px",
    color: "#221F1F",
  },
  ContentBody: {
    fontFamily: "DMSans-Regular !important",
    fontSize: "20px",
    color: "#000000",
    opacity: 0.8,
    textAlign: "justify",
  },
  ContentUnderline: {
    fontFamily: "DMSans-Regular !important",
    fontSize: "16px",
    color: "#000000",
    opacity: 0.8,
    textDecoration: "underline",
  },
});

const listText = [
  "Use the Website to harass, abuse, or threaten others.",
  "Provide information that is illegal, damaging, misleading, tortuous, defamatory, libelous, or invading of the privacy of another.",
  "Use the Website for commercial purposes, bench marking, or gathering information for a product or service.",
  "Copy, download (except for personal use or as expressly permitted by these Terms), modify, distribute, post, transmit, display, perform, reproduce, broadcast, duplicate, publish, republish, upload, license, reverse engineer, create derivative works from, or offer for sale any content or other information contained on or obtained from or through the Website by any means except as expressly permitted by these Terms or with Kramasoft's prior written consent.",
  "Scrape, access, monitor, index, frame, link, or copy any content or information on the Website by using any robot, spider, scraper, web crawler, or any other method of access other than manually accessing the publicly accessible portions of the Website through a browser or accessing the Website through any approved API.",
  "Violate any limitations in the Website's robot exclusion headers, if applicable, or bypass or circumvent any measures used to prohibit or limit access to the Website.",
  "Violate any limitations in the Website's robot exclusion headers, if applicable, or bypass or circumvent any measures used to prohibit or limit access to the Website.",
  "Upload or otherwise make available any material containing any software, device, instructions, computer code, files, programs, and/or other content or feature designed to disrupt, destroy, or limit the functionality of any computer software or hardware, or telecommunications equipment (including, without limitation, any time bomb, virus, software lock, worm, self-destruction, drop-device, malicious logic, Trojan horse, trap door, 'disabling,' 'lockout,' or 'metadata').",
  "Attempt to disable, overburden, or impair the Website's proper operation (including the use of any Mail list, Lister, or auto-responder or 'spam').",
  "Use the Website to disseminate any other party's intellectual property unless you have the authority to do so, or delete or modify any copyright, trademark, or other proprietary notice on the Website.",
  "Make available trade secrets or other confidential or proprietary information, or provide any material that you do not have a legal or contractual, or fiduciary obligation to make available, including but not limited to insider information or confidential or proprietary information learned or disclosed as part of employment relationships or under non-disclosure agreements.",
  "Falsify or misrepresent your association with a person or entity, or impersonate any person or entity.",
  "Violate these Terms or any guidelines or policies posted by Kramasoft; and/or",
  "Interfere with any other party’s use and enjoyment of the Website.",
];

function TermsOfUse() {
  const classes = customStyle();
  const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));

  return (
    <>
      <Box pt={4} id={"termsOfUse"} style={{ backgroundColor: '#fff' }}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} md={12} px={{ xs: 2 }} mb={6}>
              <Typography variant="h2" color={"primary.main"} my={5} fontWeight={700}>
                Terms of Use
              </Typography>
              <Box style={isMobile ? { fontSize: '15px' } : {}}>
                <Box>
                  <Typography className={classes.ContentBody}>
                    Effective date: December 1, 2022
                  </Typography>
                  <Typography className={classes.ContentBody} mb={3}>
                    {`Kramasoft LLC ("Kramasoft," "we," "us," or "our") welcomes you. 
                  Please continue reading to learn about the terms, rules, and limitations 
                  ("Terms of Use" or "Terms") that govern your use of our website (https://Kramasoft.com/) 
                  ("Website"). "You" and "your" refer to the user of our website for the purposes of these 
                  Terms.These Terms of Use are a legal agreement between you and Kramasoft. The Terms include 
                  the provisions below, as well as those in the Privacy Policy. By accessing or using the Website, 
                  you acknowledge and accept that you have read, understood, and agree to be governed by these Terms. 
                  You have no right to access or use the Website if you do not agree to these Terms.`}
                  </Typography>
                  <Typography
                    textAlign={"justify"}
                    my={4}
                    fontSize={"16px"}
                    fontFamily={"DMSans-Regular"}
                    fontWeight={"bold"}
                    color={"#22222299"}
                  >
                    {`IMPORTANT NOTICE: DISPUTES ABOUT THESE TERMS AND THE KRAMASOFT WEBSITE ARE SUBJECT TO BINDING 
                  ARBITRATION AND A WAIVER OF CLASS ACTION RIGHTS, AS DETAILED IN THE SECTION BELOW ENTITLED 
                  "MANDATORY ARBITRATION AND CLASS ACTION WAIVER."`}
                  </Typography>
                  <Typography mb={3} className={classes.ContentBody}>
                    {`We reserve the right to change these Terms at any moment. If this is the case, we shall change the Terms on the Website. 
                  If you do not agree with the amended Terms or are unable to comply with them, you must quit using the Website. 
                  The amended Terms will go into effect immediately after they are posted and will apply going forward unless otherwise 
                  specified in a notification to you and except as specified in the Mandatory Arbitration and Class Action Waiver portion 
                  of these Terms. Your continued use of the Website following any such update indicates your unconditional acceptance of such changes. `}
                  </Typography>
                  <Typography mb={3} className={classes.ContentBody}>
                    {`Please contact us at hr@kramasoft.com if you have any questions, comments, or concerns about these Terms or the Website.`}
                  </Typography>
                </Box>

                <Box>
                  <Typography mt={6} mb={2} className={classes.ContentHeader} style={{ fontSize: '20px' }}>
                    Eligibility and Scope
                  </Typography>
                  <Typography mb={2} className={classes.ContentHeader}>
                    1. Eligibility:
                  </Typography>
                  <Typography textAlign={"justify"} mb={2} className={classes.ContentBody}>
                    {`To use the Website, you must be of legal age and represent and
                    warrant that you are (18 years of age or older or otherwise of
                    legal age in your jurisdiction, or, if you have parental
                    consent, 13 years of age). If you are agreeing to these Terms
                    on behalf of an organization, entity, or additional borrower, you
                    represent and warrant that you are authorized to agree to
                    these Terms on their behalf and bind them to these Terms (in
                    which case, references to "you" and "your" in these Terms
                    refer to that organization, entity, or additional borrower, except in
                    this sentence). You do not have the authorization to access
                    the Website if Kramasoft has already restricted you from doing
                    so.`}
                  </Typography>
                  <Typography textAlign={"justify"} mb={2} className={classes.ContentBody}>
                    {`Restrictions: You specifically agree that if you are situated
                    in any European Economic Area member state or any member state
                    that has implemented the General Data Protection Regulation,
                    you are not entitled to use the Website and are not permitted
                    to send any personal data to us.`}
                  </Typography>
                  <Typography textAlign={"justify"} mb={2} className={classes.ContentBody}>
                    {`The content of, including materials and information contained
                  on, any website to which you link from this website is solely
                  the responsibility of the provider of that website. If you
                  access any of the third-party websites linked on the Kramasoft
                  website, you do so at your own risk. Contact the external
                  third-party website for answers to questions regarding its
                  content.`}
                  </Typography>
                </Box>

                <Box>
                  <Typography mt={6} mb={2} className={classes.ContentHeader}>
                    2. Our Proprietary Rights:
                  </Typography>
                  <Typography mb={3} className={classes.ContentBody}>
                    {`The Website is owned and administered by Kramasoft and
                  contains material obtained in whole or in part from material
                  given by Kramasoft and our partners, as well as other sources,
                  and is protected by copyright laws and other intellectual
                  property laws in the United States. You undertake to follow
                  all relevant copyright and other laws, as well as any other
                  copyright notices or limitations that may be posted on the
                  Website. You acknowledge that the Website was created,
                  compiled, prepared, revised, selected, and arranged by
                  Kramasoft and others using methods and standards of judgment
                  developed and applied with significant time, effort, and money
                  and that it is the valuable intellectual property of Kramasoft
                  and such others.`}
                  </Typography>
                  <Typography mb={3} className={classes.ContentBody}>
                    {`You undertake to promptly contact Kramasoft if you become
                  aware of any unauthorized access or use of the Website by any
                  individual or organization, or if you become aware of any
                  allegation that the Website infringes on any copyright,
                  trademark, or other contractual, statutory, or common law
                  rights. All existing and future rights in and to trade
                  secrets, patents, copyrights, trademarks, service marks,
                  know-how, and other intellectual rights will be and remain the
                  exclusive and exclusive property of Kramasoft, as between you
                  and Kramasoft.`}
                  </Typography>
                </Box>

                <Box>
                  <Typography mt={6} mb={2} className={classes.ContentHeader}>
                    3. License and Prohibited Behaviour:
                  </Typography>
                  <Typography mb={3} className={classes.ContentBody}>
                    <Typography
                      variant="span"
                      style={isMobile ? { fontSize: '14px', color: '#221F1F', opacity: 10, fontWeight: 600 } : {}}
                      className={classes.ContentUnderline}
                    >
                      Your License:
                    </Typography>
                    {` We provide you a limited, non-exclusive, non-sublicensable, non-transferable, and revocable license to access and use the Website solely for your own internal, personal, or non-commercial use, and only in accordance with all relevant laws. If your use of the Website is forbidden by relevant legislation, you do not have permission to use it.`}
                  </Typography>
                  <Typography mb={3} className={classes.ContentBody}>
                    <Typography
                      variant="span"
                      style={isMobile ? { fontSize: '14px', color: '#221F1F', opacity: 10, fontWeight: 600 } : {}}
                      className={classes.ContentUnderline}
                    >
                      Feedback:
                    </Typography>
                    {` Any feedback you provide to us about your user experience with the Website, whether via email or other mediums (including our interactive chat function), excluding information about you, will be deemed non-confidential, and we will have no obligation to keep such information confidential or compensate you. The transmission of such information to us shall in no way preclude us from purchasing, manufacturing, or using similar items, services, plans, and ideas for any purpose, and we shall be free to do so. reproduce, use, disclose, and distribute the information to others without restriction.`}
                  </Typography>
                  <Typography mb={3} className={classes.ContentBody}>
                    <Typography
                      variant="span"
                      style={isMobile ? { fontSize: '14px', color: '#221F1F', opacity: 10, fontWeight: 600 } : {}}
                      className={classes.ContentUnderline}
                    >
                      License You Grant Kramasoft:
                    </Typography>
                    {` Anything you publish, save, upload, or otherwise contribute (apart from your feedback, which is addressed above) is material that you supply. You grant us certain rights in relation to the material. As a result, you hereby grant Kramasoft a license to translate, modify (for technical purposes, such as making your content viewable on a mobile device as well as a computer), reproduce, and otherwise act with respect to such content, all in order for us to improve the operation of the Website or to provide products or services to you through or in connection with the Website. This is only a license; your ownership of the content you supply, with the exception of the feedback specified above, remains unaffected. You agree that the licenses you give are royalty-free, permanent, sublicensable, irrevocable, and globally applicable. Please keep in mind that the preceding licenses are subject to our Privacy Policy.`}
                  </Typography>
                </Box>

                <Box>
                  <Typography mt={6} mb={2} className={classes.ContentHeader}>
                    1. Prohibited Uses
                  </Typography>
                  <Typography mb={2} className={classes.ContentBody}>
                    The use of the Website for any unlawful or unauthorized
                    purpose, or for any other purpose not specifically permitted
                    in these Terms, is strictly forbidden. You will not, without
                    exception
                  </Typography>
                  <Box>
                    <List
                      sx={{
                        listStyleType: "disc",
                        pl: 3,
                      }}
                    >
                      {listText.map((item) => (
                        <ListItem
                          key={`item-${listText}-${item}`}
                          sx={{
                            padding: 0,
                            textAlign: "justify",
                            fontSize: isMobile ? "14px" : "16px",
                            color: "#000000",
                            opacity: 0.8,
                            marginBottom: 2,
                            display: "list-item",
                            "&.MuiListItem-root::marker": {
                              color: "#000000",
                              opacity: 0.8,
                              fontSize: "20px",
                            },
                          }}
                        >
                          {item}
                        </ListItem>
                      ))}
                    </List>
                  </Box>

                  <Typography mb={3} className={classes.ContentBody}>
                    Please keep in mind that the terms and conditions outlined in
                    these Terms apply to any content you may be able to copy or
                    download from the Website (e.g., guides and white papers).
                  </Typography>

                  <Typography mb={3} className={classes.ContentBody}>
                    Kramasoft maintains the right, but not the responsibility, to
                    delete any information given by you and/or prohibit access to
                    the Website in our sole and absolute discretion.
                  </Typography>
                </Box>

                <Box>
                  <Typography mb={2} mt={6} className={classes.ContentHeader}>
                    2. Legal Compliance
                  </Typography>
                  <Typography
                    textAlign={"justify"}
                    className={classes.ContentBody}
                  >
                    You acknowledge, consent, and agree that Kramasoft may access,
                    preserve, and disclose content you provide us if required by
                    law or if we have a good faith belief that such access,
                    preservation, or disclosure is permitted by our Privacy Policy
                    or is reasonably necessary or appropriate for any of the
                    following reasons:
                  </Typography>
                  <Typography
                    mb={2}
                    textAlign={"justify"}
                    className={classes.ContentBody}
                  >
                    (1) to comply with legal process (e.g., a subpoena, court
                    order, or warrant). <br />
                    (2) to enforce these Terms.
                    <br />
                    (3) to respond to claims that any content violates the rights
                    of third parties.
                    <br />
                    {`(4) to protect Kramasoft, our agents and affiliates, our
                    users, and the public's rights, property, or personal safety.`}
                    <br />
                    (5) to respond to your requests.
                  </Typography>
                  <Typography
                    mb={2}
                    textAlign={"justify"}
                    className={classes.ContentBody}
                  >
                    Furthermore, please keep in mind that Kramasoft is not in the
                    business of offering professional services or legal, tax, or
                    other advice through or in connection with this Website.
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    mb={2}
                    mt={6}
                    textTransform={"uppercase"}
                    className={classes.ContentHeader}
                  >
                    3. WARRANTIES AND DISCLAIMERS
                  </Typography>
                  <Typography
                    mb={3}
                    textTransform={"uppercase"}
                    className={classes.ContentBody}
                  >
                    {`YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE
                    WEBSITE IS AT YOUR SOLE RISK. KRAMASOFT MAKES NO
                    REPRESENTATIONS OR WARRANTIES ABOUT ANY CONTENT IN OR ACCESSED
                    THROUGH THE WEBSITE, AND WE WILL NOT BE RESPONSIBLE OR LIABLE
                    FOR THE ACCURACY, COPYRIGHT COMPLIANCE, LEGALITY, OR DECENCY
                    OF MATERIAL IN OR ACCESSED THROUGH THE WEBSITE. THE WEBSITE IS
                    PROVIDED "AS IS," WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR
                    IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                    MERCHANT ABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                    NON-INFRINGEMENT, OR THAT USE OF THE WEBSITE WILL BE
                    UNINTERRUPTED OR ERROR-FREE.`}
                  </Typography>
                  <Typography
                    mb={3}
                    textTransform={"uppercase"}
                    className={classes.ContentBody}
                  >
                    {`ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE
                    WEBSITE IS DOWNLOADED AND USED SOLELY AT YOUR OWN DISCRETION
                    AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO
                    YOUR COMPUTER SYSTEM, MOBILE DEVICE, SOFTWARE, TECHNOLOGY, OR
                    LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR USE OF ANY SUCH
                    CONTENT.`}
                  </Typography>

                  <Typography
                    mb={3}
                    textTransform={"uppercase"}
                    className={classes.ContentBody}
                  >
                    NO ADVICE OR INFORMATION OBTAINED BY YOU FROM KRAMASOFT OR
                    THROUGH OR FROM THE WEBSITE, WHETHER ORAL OR WRITTEN, SHALL
                    CREATE ANY WARRANTY. KRAMASOFT MAKES NO REPRESENTATION,
                    WARRANTY, GUARANTEE, OR PROMISE THAT THE PRODUCTS, SERVICES,
                    OR WEBSITE WILL MEET YOUR REQUIREMENTS OR PROVIDE SPECIFIC
                    RESULTS.
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    mb={2}
                    mt={6}
                    textTransform={"uppercase"}
                    className={classes.ContentHeader}
                  >
                    4. LIMITATION OF LIABILITY
                  </Typography>
                  <Typography
                    mb={3}
                    textTransform={"uppercase"}
                    className={classes.ContentBody}
                  >
                    {`TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAW, KRAMASOFT
                    SHALL NOT BE LIABLE TO YOU OR ANY OTHER PERSON FOR (INCLUDING,
                    WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR
                    OTHERWISE) (A) ANY INDIRECT, SPECIAL, INCIDENTAL, OR
                    CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST
                    PROFITS, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS,
                    OR COMPUTER FAILURE OR MALFUNCTION, OR (B) ANY AMOUNT, IN THE
                    AGGREGATE, IN EXCESS OF THE GREATER OF (I) $100 OR (II) THE
                    AMOUNTS PAID BY YOU TO KRAMASOFT IN CONNECTION WITH THE
                    WEBSITE IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS
                    APPLICABLE CLAIM.`}
                  </Typography>
                  <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                    {`If you have a disagreement with a third party, you accept that
                    Kramasoft is under no duty to intervene on your behalf. You
                    release Kramasoft and its officers, employees, agents, and
                    successors from any and all claims, demands, and damages,
                    known or unknown, suspected or unsuspected, disclosed or
                    undisclosed, arising out of or in any way related to such
                    disputes and/or the Website. If you live in California, you
                    agree to waive California Civil Code §1542, which states that
                    "a general release does not extend to claims which the
                    creditor does not know or suspect to exist in his favor at the
                    time of executing the release, which if known by him must have
                    materially affected his settlement with the debtor." Others'
                    criminal deeds are included in this publication.`}
                  </Typography>
                </Box>

                <Box>
                  <Typography mb={2} mt={6} className={classes.ContentHeader}>
                    5. Exclusions and Limitations
                  </Typography>
                  <Typography mb={3} className={classes.ContentBody}>
                    Some jurisdictions prohibit the elimination of certain
                    guarantees, as well as the restriction or exclusion of
                    responsibility for incidental or consequential damages, as
                    described in Sections 5 and 6. As a result, some of the
                    restrictions listed above may not apply to you. If you live in
                    New Jersey or another state that allows the exclusion of these
                    warranties and responsibilities, the limits in Sections 5 and
                    6 particularly apply to you.
                  </Typography>
                </Box>

                <Box>
                  <Typography mb={2} mt={6} className={classes.ContentHeader}>
                    6. Indemnity
                  </Typography>
                  <Typography
                    mb={3}
                    textTransform={"uppercase"}
                    className={classes.ContentBody}
                  >
                    YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD KRAMASOFT AND OUR
                    RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, MEMBERS,
                    SHAREHOLDERS, AND REPRESENTATIVES (AND ALL SUCCESSORS AND
                    ASSIGNS OF ANY OF THE FOREGOING) HARMLESS FROM AND AGAINST ANY
                    CLAIM OR DEMAND, INCLUDING, WITHOUT LIMITATION, REASONABLE
                    ATTORNEYS’ FEES AND DISBURSEMENTS, MADE IN CONNECTION WITH OR
                    ARISING OUT OF YOUR VIOLATION OF THESE TERMS OR OUR PRIVACY
                    POLICY, AND/OR YOUR SUBMISSION, POSTING, OR TRANSMISSION OF
                    CONTENT TO THE WEBSITE. WE RESERVE THE RIGHT, AT OUR OWN
                    EXPENSE, TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF SUCH
                    DISPUTES, AND IN ANY EVENT, YOU WILL COOPERATE WITH US IN
                    ASSERTING ANY AVAILABLE DEFENSES.
                  </Typography>
                </Box>

                <Box>
                  <Typography mb={2} mt={6} className={classes.ContentHeader}>
                    7. Third Party Links and Websites
                  </Typography>
                  <Typography mb={3} className={classes.ContentBody}>
                    Kramasoft has no control over, and accepts no responsibility
                    for, the content, accuracy, privacy policies, or practices of,
                    or opinions expressed in, any third-party websites or by any
                    third party with whom you interact through or on the Website,
                    and assumes no responsibility for such content, accuracy,
                    privacy policies, or practices. Furthermore, Kramasoft will
                    not, and will not be able to, monitor, verify, censor, or
                    change the content of any third-party website or service. By
                    using the Website, you release and hold us harmless from any
                    and all liability arising from your use of any third-party
                    website or service, and you acknowledge and agree that your
                    interactions with and use of such websites and services are
                    governed by the terms and privacy policies of such third
                    parties.
                  </Typography>
                </Box>

                <Box>
                  <Typography mb={2} mt={6} className={classes.ContentHeader}>
                    8. Modification and Termination
                  </Typography>
                  <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                    <span className={classes.ContentUnderline}>Modification of Website:</span> Kramasoft maintains the right, with
                    or without notice, to alter or discontinue the Website (or any
                    portion of it) at any time. You agree that Kramasoft will not
                    be responsible to you or any third party if the Website is
                    modified, suspended, or discontinued.
                  </Typography>
                  <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                    <span className={classes.ContentUnderline}>Termination:</span>
                    {` These Terms are effective unless and until
                    terminated by you or us. We may, in our sole and absolute
                    discretion, deny you access to all or part of the Website at
                    any time for any or no reason at all, with or without notice
                    to you. Grounds for such termination shall include, but not be
                    limited to, (a) breaches or violations of these Terms or other
                    agreements, (b) requests by law enforcement or government
                    agencies, (c) discontinuance or material modification of the
                    Website (or any part thereof), (d) unexpected technical or
                    security issues or problems, (e) extended periods of
                    inactivity, (f) activities related to protecting the rights,
                    property, or safety of Kramasoft, our agents and affiliates,
                    or our users and the public, or (g) if you provide any
                    information that is false, inaccurate, out-of-date, or
                    incomplete. If we terminate your right to access the Website,
                    these terms will terminate and all rights you have to access
                    the Website will immediately terminate; however, certain
                    provisions of these Terms will still apply post-termination,
                    including without limitation, the Mandatory Arbitration, and
                    Class Action Waiver provisions.`}
                  </Typography>
                </Box>

                <Box>
                  <Typography mt={6} className={classes.ContentHeader}>
                    9. MANDATORY ARBITRATION AND CLASS ACTION WAIVER
                  </Typography>
                  <Typography fontWeight={"bold"} mb={2}>
                    {`(PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR LEGAL
                    RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT)`}
                  </Typography>
                  <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                    <span className={classes.ContentUnderline}>Application:</span>
                    {` You and Kramasoft agree that these Terms impact
                    interstate commerce and that the interpretation and
                    enforcement of these arbitration clauses are governed by the
                    Federal Arbitration Act. This Section 11 is intended to be
                    broadly interpreted and governs any and all disputes between
                    us, including but not limited to claims arising out of or
                    relating to any aspect of our relationship, whether based on
                    contract, tort, statute, fraud, misrepresentation, or any
                    other legal theory; claims that arose prior to these Terms or
                    any prior agreement (including, but not limited to, claims
                    related to advertising); and claims that may arise after the
                    termination of these Terms or any prior agreement. The only
                    issues exempt from this wide restriction are those involving
                    intellectual property and modest court claims, as specified
                    below.`}
                  </Typography>
                  <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                    <span className={classes.ContentUnderline}>Initial Dispute Resolution:</span>
                    {` Most disagreements may be settled
                    without resorting to arbitration. If you have an issue with
                    us, you agree to contact us at hr@Kramasoft.com and provide a
                    brief written summary of the dispute as well as your contact
                    information before initiating any official action (including
                    your username, if your dispute relates to a Platform account).
                    Except for intellectual property and small claims court
                    claims, the parties agree to use their best efforts to resolve
                    any dispute, claim, question, or disagreement directly through
                    consultation with Kramasoft, and good faith negotiations shall
                    be a condition precedent to either party filing a lawsuit or
                    initiating the arbitration.`}
                  </Typography>
                  <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                    <span className={classes.ContentUnderline}>Binding Arbitration:</span>
                    {` If the parties do not reach an
                    agreed-upon solution within a period of thirty (30) days from
                    the time informal dispute resolution is initiated under the
                    Initial Dispute Resolution provision above, then either party
                    may initiate binding arbitration as the sole means to resolve
                    claims, (except as provided in the “Exception: Litigation of
                    Intellectual Property and Small Claims Court Claims”
                    subsection below) subject to the terms set forth below.
                    Specifically, all claims arising out of or relating to these
                    Terms (including the Terms’ formation, performance, and
                    breach), the parties' relationship with each other, and/or
                    your use of Kramasoft shall be finally settled by binding
                    arbitration administered by JAMS in accordance with the JAMS
                    Streamlined Arbitration Procedure Rules for claims that do not
                    exceed $250,000 and the JAMS Comprehensive Arbitration Rules
                    and Procedures for claims exceeding $250,000 in effect at the
                    time the arbitration is initiated, excluding any rules or
                    procedures governing or permitting class actions.`}
                  </Typography>
                  <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                    <span className={classes.ContentUnderline}>{`Arbitrator’s Powers:`}</span>
                    {` The arbitrator, rather than any federal,
                    state, or local court or agency, shall have sole authority to
                    resolve all disputes arising out of or relating to the
                    interpretation, applicability, enforceability, or formation of
                    these Terms, including, but not limited to, any claim that all
                    or any part of these Terms is void or voidable, whether a
                    claim is subject to arbitration, or the question of waiver by
                    litigation conduct. The arbitrator shall have the authority to
                    give any relief available in a court of law or equity. The
                    arbitrator's award must be in writing, binding on the parties,
                    and may be entered as a judgment in any court of competent
                    jurisdiction.`}
                  </Typography>
                  <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                    <span className={classes.ContentUnderline}>Filing a Demand:</span>
                    {` To start an arbitration, you must do the
                    following: (a) Write a Demand for Arbitration that includes a
                    description of the claim and the number of damages you seek to
                    recover (you may find a copy of a Demand for Arbitration at
                    www.jamsadr.com); (b) Send three copies of the Demand for
                    Arbitration, plus the appropriate filing fee, to JAMS, 2
                    Embarcadero Center, Suite 1500, San Francisco, California
                    94111; and (c) send one copy of the Demand for Arbitration to
                    us at: ATTN: Legal, Kramasoft LLC. If the filing fee for the
                    arbitration exceeds the cost of bringing a case, Kramasoft
                    will pay the difference. If the arbitrator rules that the
                    arbitration was not frivolous, Kramasoft will pay the JAMS
                    fees, including filing fees, arbitrator, and hearing expenses.
                    Unless the arbitration rules and/or relevant legislation
                    permit otherwise, you are liable for your own attorneys'
                    expenses.`}
                  </Typography>
                  <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                    <span className={classes.ContentUnderline}>Class Action Waiver:</span>
                    {` The parties further agree that the
                    arbitration will take place only in the parties' individual
                    capacities, rather than as a class or other representative
                    action, and the parties explicitly waive their right to file a
                    class action or seek relief on a class basis. YOU AND
                    KRAMASOFT AGREE THAT YOU AND KRAMASOFT MAY BRING CLAIMS
                    AGAINST EACH OTHER ONLY IN YOUR OR OUR INDIVIDUAL CAPACITY,
                    AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS
                    OR REPRESENTATIVE PROCEEDING. If a court or arbitrator rules
                    that the class action waiver set forth in this paragraph is
                    void or unenforceable for any reason, or that arbitration can
                    proceed on a class basis, then the arbitration provisions set
                    forth above are null and void in their entirety, and the
                    parties are deemed not to have agreed to arbitrate disputes.`}
                  </Typography>
                  <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                    <span className={classes.ContentUnderline}>Exception:</span>
                    {` Litigation of Intellectual Property and Small
                    Claims Court Claims: Regardless of the parties' desire to
                    settle all issues through arbitration, either party may pursue
                    enforcement proceedings, validity decisions, or claims
                    originating from or pertaining to intellectual property theft,
                    piracy, or unauthorized use in state or federal court or in
                    the U.S. To defend its intellectual property rights, the
                    Patent and Trademark Office ("intellectual property rights"
                    include patents, copyrights, moral rights, trademarks, and
                    trade secrets, but not privacy or publicity rights). Either
                    party may also seek remedy in a small claims court for
                    disputes or claims that are within the jurisdiction of that
                    court.`}
                  </Typography>
                  <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                    <span className={classes.ContentUnderline}>30 - Day Right to Opt Out:</span>
                    {` You may opt-out of the arbitration
                    and class action waiver provisions set out above by giving
                    written notification of your choice to hr@Kramasoft.com with
                    the subject line, "ARBITRATION AND CLASS ACTION WAIVER
                    OPT-OUT." The notification must be delivered within thirty
                    (30) days of the later of (a) the Effective Date of these
                    Terms or (b) your first date of use of the Website that
                    contained any versions of the Terms that included this version
                    of the mandatory arbitration and class action waiver.
                    Otherwise, you must arbitrate conflicts in line with the
                    requirements of these paragraphs. Kramasoft will not be bound
                    by these arbitration terms if you choose not to use them.`}
                  </Typography>
                  <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                    <span className={classes.ContentUnderline}>Changes to This Section:</span>
                    {` Any modifications to this section
                    will be posted on the Website thirty (30) days in advance.
                    Amendments shall take effect thirty (30) days after being
                    posted on the Website. Otherwise, changes to this provision
                    will apply prospectively only to claims filed after the
                    thirty-first (30th) day. If a court or arbitrator rules that
                    this subsection on "Changes to This Section" is unenforceable
                    or invalid, it will be severed from the section entitled
                    "Mandatory Arbitration and Class Action Waiver," and the court
                    or arbitrator will apply the first Mandatory Arbitration and
                    Class Action Waiver section in existence after you started
                    using the Website.`}
                  </Typography>
                  <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                    Survival. This Mandatory Arbitration and Class Action Waiver
                    section shall survive any termination of your use of the
                    Website.
                  </Typography>
                </Box>

                <Box>
                  <Typography mb={2} mt={6} className={classes.ContentHeader}>
                    10. Controlling Law and Severability
                  </Typography>
                  <Typography mb={3} className={classes.ContentBody}>
                    These Terms shall be read in accordance with California law,
                    without regard to any conflict-of-law provisions. The parties
                    consent to exclusive jurisdiction and venue in the state or
                    federal courts situated in San Francisco County, California,
                    or the Northern District of California, for all purposes of
                    these Terms. If any portion of these Terms is deemed invalid,
                    it will be enforced as effectively as feasible while the
                    remaining sections will continue in full force and effect.
                  </Typography>
                </Box>

                <Box>
                  <Typography mb={2} className={classes.ContentHeader}>
                    11. General Terms
                  </Typography>
                  <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                    <span className={classes.ContentUnderline}>Force Majeure:</span>
                    {` In no event shall Kramasoft or our licensor or
                    supplier be liable for any delay or failure in performance
                    caused directly or indirectly by an event beyond our
                    reasonable control.`}
                  </Typography>
                  <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                    <span className={classes.ContentUnderline}>No Waiver; Severability:</span>
                    {` No waiver of any provision of these
                    Terms will be construed as a subsequent or continuing waiver
                    of such term or any other term, and Kramasoft's omission to
                    assert or enforce any right or remedy under these Terms does
                    not waive such right or remedy. If an arbitrator or a court of
                    competent jurisdiction rules that any provision of these Terms
                    is invalid, the parties agree that the court should endeavor
                    to give effect to the parties' intentions as reflected in the
                    provision, to the maximum extent permitted by law, and the
                    other provisions of these Terms will remain in full force and
                    effect.`}
                  </Typography>
                  <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                    <span className={classes.ContentUnderline}>Miscellaneous:</span>
                    {` These Terms (and all terms and conditions
                    incorporated herein) are the entire agreement between you and
                    Kramasoft and govern your use of the Website and the products
                    and services provided by Kramasoft through and in connection
                    with the Website, superseding any prior agreements between you
                    and Kramasoft on the subject matter. You may not assign or
                    delegate these Terms or any rights or licenses granted herein.
                    Kramasoft may freely assign or delegate these Terms and any
                    rights or licenses granted herein. These Terms bind and
                    benefit each party, as well as the party's successors and
                    allowed assignee. A spoken remark by a Kramasoft
                    representative does not modify these Terms These Terms do not
                    intend or create any agency, partnership, joint venture, or
                    employee-employer relationship. You agree that any agreements
                    formed by and between you and us in electronic form are
                    legally binding in the same way that physical paper agreements
                    are. The drafter will not be held liable under these Terms.
                    The section headings in these Terms are just for convenience
                    and have no legal or contractual significance. Except for New
                    Jersey residents, you agree that, regardless of any statute or
                    law to the contrary, any claim or cause of action arising out
                    of or related to the use of the Website and/or these Terms
                    must be filed within one (1) year of the occurrence of such
                    claim or cause of action or be forever barred.`}
                  </Typography>
                  <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                    <span className={classes.ContentUnderline}>Notices:</span>
                    {` We may provide you notice by posting a notice on the
                  Website or in any other way we deem appropriate, and such
                  notice will be effective upon delivery. If you provide us with
                  notice, it will be effective when received, and you must send
                  it to hr@Kramasoft.com.`}
                  </Typography>
                </Box>

                <Box>
                  <Typography mb={2} mt={6} className={classes.ContentHeader}>
                    12. Questions
                  </Typography>
                  <Typography className={classes.ContentBody}>
                    If you have any questions about these Terms, please contact us
                    by email
                  </Typography>
                  <Typography
                    mb={2}
                    className={classes.ContentBody}
                    color={"error"}
                  >
                    at hr@Kramasoft.com, or by mail at Kramasoft LLC ny Street,
                    San Francisco, CA 94108. <br /> © Kramasoft 2022
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box >
    </>
  );
}

export default TermsOfUse;
