import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import autoSaveImage from '../../../../../assets/autoSaveImage.png'


export function ThatsGreat({ handleClose }) {
    return (
        <Box width={'100%'} my={2} textAlign={'center'}>
            <Box py={3} my={'auto'}>
                <img src={autoSaveImage} width={"50%"} />
            </Box>
            <Typography mb={2} variant={'inherit'}>
                {
                    `Your progress will be `
                }<Typography component={'span'} color={'primary'} >Autosaved.</Typography>
            </Typography>
            <Typography mb={2} variant={'inherit'}>
                {
                    `We value your time & effort in filling the form. You do have the luxury to resume later. All your progress will be autosaved.`
                }
            </Typography>
            <Button variant='contained' onClick={handleClose}>That’s Great!</Button>
        </Box >
    )
}