import React, { useState } from "react";
import { EditOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import CustomInput from "../../../helpers/custom-input";
import { useRecoilValue } from "recoil";
import { profileState } from "../../../recoil/profile-atom";
import { CREDCO_INFO, ROLE_LIST } from "../../../constants/constatnt-variable";
import RadioButtonGroup from "../../../helpers/radio-button-group";

export default function CredcoCredentialsView({ data, setCredcoEditSection }) {
  const [showPassword, setShowPassword] = useState(false);
  let profileInfo = useRecoilValue(profileState);

  return (
    <>
      <Box position={"relative"} my={2}>
        <Button
          startIcon={<EditOutlined />}
          variant="outlined"
          onClick={() => {
            setCredcoEditSection(true);
          }}
          size="small"
          sx={{
            position: { xs: "relative", lg: "absolute" },
            right: 0,
            zIndex: 50,
          }}
        >
          Edit
        </Button>

        <CustomInput
          required
          boxProps={{ my: 2 }}
          size="small"
          fullWidth
          label={"Internal Account Identifier"}
          description={data?.internalAccountIdentifier}
          placeholder={"122"}
          disabled
        />
        <CustomInput
          required
          boxProps={{ my: 2 }}
          size="small"
          fullWidth
          label={"Requested By Company Name"}
          description={data?.requestedByName}
          placeholder={"Thomos Jone"}
          disabled
        />
        <CustomInput
          required
          boxProps={{ my: 2 }}
          size="small"
          fullWidth
          label={"Requesting Party Branch Identifier"}
          description={data?.requestingPartyBranchIdentifier}
          placeholder={"34343"}
          disabled
        />
        <CustomInput
          required
          boxProps={{ my: 2 }}
          size="small"
          fullWidth
          label={"Login Account Identifier"}
          description={data?.loginAccountIdentifier}
          placeholder={"4004107"}
          disabled
        />
        <CustomInput
          required
          boxProps={{ my: 2 }}
          size="small"
          fullWidth
          disabled
          label={"Login Account Password"}
          description={data?.loginAccountPassword}
          placeholder={"*******"}
          type={showPassword ? "text" : "password"}
          eicon={
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          }
        />
      </Box>
    </>
  );
}
