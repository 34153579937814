import { ErrorOutlineOutlined } from '@mui/icons-material'
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Tooltip } from '@mui/material'
import React from 'react'

export default function PreferenceForm({ creditReportData, handleChange, value }) {
    return (
        <FormControl>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={creditReportData}
                name="radio-buttons-group"
                value={value}
                onChange={handleChange}
            >
                <Grid item container direction={"column"}>
                    <Grid item>
                        <FormControlLabel
                            value="All"
                            control={<Radio />}
                            label="All Borrowers"
                        />
                        <Tooltip
                            title="Pull for all borrowers"
                            arrow
                            placement="right-start"
                        >
                            <ErrorOutlineOutlined
                                sx={{ height: "10px", width: "10px" }}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            value="Some"
                            control={<Radio />}
                            label="Some Borrowers"
                        />
                        <Tooltip
                            title={
                                <>
                                    1. Purchase: Owns More Than one real estate
                                    <br />
                                    2. Refinance: Owns More Than 2 real estate
                                </>
                            }
                            arrow
                        >
                            <ErrorOutlineOutlined
                                sx={{ height: "10px", width: "10px" }}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            value="None"
                            control={<Radio />}
                            label="None"
                        />
                        <Tooltip
                            title="Credco credentials are optional"
                            arrow
                            placement="right-start"
                        >
                            <ErrorOutlineOutlined
                                sx={{ height: "10px", width: "10px" }}
                            />
                        </Tooltip>
                    </Grid>
                </Grid>
            </RadioGroup>
        </FormControl>
    )
}
