import axios from "axios";
import { APIAUTH, configureAxios } from "./config";
import { AlertError, toastNotify } from "../helpers/alert-msg";
import { SERVICE_TYPE } from "../constants/constatnt-variable";
import { API } from "../services/api-config";
import { setRecoil } from "recoil-nexus";
import { GlobalAlertSeverity } from "../recoil/profile-atom";

class Authentication {
  constructor(props) {
    this.result = {
      data: null,
      access_token: null,
      refresh_token: null,
      error: null,
      roleList: [],
      isPasswordExpired: null,
    };

    this.session = props.session;
    this.authCallback = null;
  }

  onSetResult(data = null, error = null) {
    if (data) {
      this.result.data = data;
      this.result.error = null;
      localStorage.setItem(this.session, JSON.stringify(this.result));
      localStorage.setItem("time", new Date());
      localStorage.setItem(process.env.REACT_APP_USER_SESSION, "login");

      configureAxios({
        authToken: data.access_token,
        authCallback: this.authCallback,
      });

      return [true, this.result];
    }

    localStorage.removeItem(this.session);

    this.result.data = null;
    this.result.access_token = null;

    if (
      error?.response?.data?.errorMessage
    ) {
      this.result.error = {
        errorMessage: error.response.data.errorMessage,
        errorCode: error.response.data.errorCode,
      };
    }

    return [false, this.result];
  }

  setAuthCallback(authCallback) {
    this.authCallback = authCallback;
  }

  currentSession() {
    return JSON.parse(localStorage.getItem(this.session));
  }

  async signin(username, password) {
    return axios
      .post(APIAUTH.login, {
        email: username,

        password: password,
      })
      .then((response) => this.onSetResult(response.data))
      .catch((error) => this.onSetResult("", error));
  }

  async tokenRequest(data) {
    return axios
      .post(APIAUTH.signinOtp, data)
      .then((response) => this.onSetResult(response.data))
      .catch((error) => this.onSetResult("", error));
  }

  async signout() {
    return axios
      .post(APIAUTH.logout)
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem(this.session);
          setRecoil(GlobalAlertSeverity, {
            state: true,
            data: {
              severity: "success",
              msg: res.data.message
            }
          })
        }
        localStorage.removeItem(this.session);
      })
      .catch((err) => {
        localStorage.removeItem(this.session);
        setRecoil(GlobalAlertSeverity, {
          state: true,
          data: {
            severity: "success",
            msg: "Signed Out Successfully"
          }
        })
      });
  }

  async changePassword(data, from) {
    let path = APIAUTH.changePassword;
    if (from == SERVICE_TYPE.forget) {
      path = APIAUTH.forgetChangePassword;
    }
    if (from === SERVICE_TYPE.changePassword) {
      path = APIAUTH.passChange;
    }
    if (
      from === SERVICE_TYPE.invitation ||
      from === SERVICE_TYPE.teamInvitation ||
      from === SERVICE_TYPE.coBorrowerInvitation
    ) {
      path = API.signup;
    }
    return axios
      .post(path, data)
      .then((response) => {
        return this.onSetResult(response.data);
      })
      .catch((error) => {
        return [false, { error: error.response.data }];
      });
  }

  async expirePassword(data) {
    return axios
      .put(APIAUTH.expirePassword, data)
      .then((response) => this.onSetResult(response.data))
      .catch((error) => this.onSetResult("", error));
  }

  async setPassword(verifyCode, password) {
    return axios
      .post(APIAUTH.setPassword, {
        action: "create",
        payload: {
          verifyCode: verifyCode,
          password: password,
        },
      })
      .then((response) => this.onSetResult(response.data))
      .catch((error) => this.onSetResult("", error));
  }

  async verifyForgetCode(verifyCode) {
    return axios
      .get(APIAUTH.setPassword, {
        params: { verifyCode: verifyCode },
      })
      .catch((error) => {
        return error.response;
      });
  }

  async forgetPassword(data) {
    return axios
      .get(APIAUTH.forgetPassword, {
        params: { email: data },
      })
      .catch((error) => AlertError(error));
  }

  hasAnyRole(roles) {
    const userSession = this.currentSession();


    let role = userSession?.data?.role;


    // Check if the user is logged in and has role information
    if (userSession && role) {
      // Check if the user has at least one of the specified roles
      return roles.includes(role);
    }

    return false;
  }


}

export const Auth = new Authentication({
  session: process.env.REACT_APP_SESSION,
});
