
// export default CustomTooltip;

// import React from 'react';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import { styled } from '@mui/material/styles';

// const CustomTooltip = styled(({ className, ...props }) => (
//     <Tooltip {...props} classes={{ popper: className }} arrow />
// ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: '#2f80ed',
//         color: 'white',
//         boxShadow: theme.shadows[1],
//         fontSize: 15,
//     },
//     [`& .${tooltipClasses.arrow}`]: {
//         color: '#2f80ed',
//     },
// }));

// export default CustomTooltip;

// import React from 'react';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import { styled } from '@mui/material/styles';

// const CustomTooltip = styled(({ className, ...props }) => (
//     <Tooltip {...props} classes={{ popper: className }} arrow PopperProps={{
//         modifiers: [
//             {
//                 name: 'offset',
//                 options: {
//                     offset: [0, 10],
//                 },
//             },
//         ],
//     }} />
// ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: '#2f80ed',
//         color: 'white',
//         boxShadow: theme.shadows[1],
//         fontSize: 15,
//     },
//     [`& .${tooltipClasses.arrow}`]: {
//         color: '#2f80ed',
//     },
// }));

// export default CustomTooltip;


import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
        arrow
        PopperProps={{
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 10],
                    },
                },
            ],
        }}
        disableInteractive={false} // Ensures tooltip can be interacted with on touch devices
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#2f80ed',
        color: 'white',
        boxShadow: theme.shadows[1],
        fontSize: 15,
        padding: '8px 12px', // Increase padding for better touch interaction
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#2f80ed',
    },
}));

export default CustomTooltip;
