import React, { useMemo, useState } from "react";

import { createTheme, responsiveFontSizes, styled } from "@mui/material/styles";
import { IconButton, Stack, Tooltip, useMediaQuery, } from "@mui/material";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";

import "../../../styles/admin.css";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import AddIcon from "@mui/icons-material/Add";

import InvitationForm from "./invitation-form";
import { DialogCommonHooks } from "../../../helpers/modal/modal";
import DialogSuccesMsg from "../../../helpers/modal/dialog-succes-msg";
import MainLoyOut from "../../../components/main-layout";
import CustomReactMaterialTable from "../../../helpers/table/react-material-table";
import { API } from "../../../services/api-config";
import { deleteApiCallDynamic, getApiCallDynamic } from "../../../services/api-service";
import { ClientSideMsg } from "../../../helpers/alert-msg";
import ConfirmationModel from "../../../helpers/modal/confirm-modal";
import { KSMailIcon, KSPencilAltIcon, KSTrashIcon } from "../../../icons/custome-icon";
import { useRecoilValue } from "recoil";
import { GlobalAlertSeverity, profileState } from "../../../recoil/profile-atom";
import { NMLS_STATUS_VALID, ROLE_LIST, SERVICE_TYPE } from "../../../constants/constatnt-variable";
import { MRT_GlobalFilterTextField } from "material-react-table";
import { kstheme } from "../../../theme/theme";
import { setRecoil } from "recoil-nexus";


let theme = createTheme();
theme = responsiveFontSizes(theme);


export default function InvitationPage(props) {
  let profileInfo = useRecoilValue(profileState);
  const [openModal, setModalOpen] = useState(false);
  const [openSucess, setSucessModalOpen] = useState(false);

  const [edit, setEdit] = useState(false);

  const [data, setData] = useState(false);



  const isTab = useMediaQuery(kstheme.breakpoints.down("md"));



  const handleClickOpen = () => {
    let temp = false;
    profileInfo.stateLicenses.forEach((item) => {
      if (item.status === 'valid') {
        setModalOpen(true);
        temp = true;
        return;
      }
    })
    if (!temp) {
      setRecoil(GlobalAlertSeverity, {
        state: true,
        data: {
          severity: "error",
          msg: "Your NMLS ID is pending validation by admin; expect confirmation within 4 hours post-registration. Upon validation, you'll receive an email and notification. Meanwhile, ensure your licenses are updated in the Settings NMLS section."
        }
      })
    }
  };


  const handleSucessOpen = (data) => {
    setData(data)
    setreload(prev => !prev)
    setModalOpen(false);
    setSucessModalOpen(true);
  };

  //all modal close
  const handleClose = () => {
    setModalOpen(false);
    setSucessModalOpen(false);
    setreload(prev => !prev)
  };


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));



  const columns = useMemo(
    () => [
      {
        accessorKey: "id",

        header: "ID",
        enableColumnActions: false,
      },

      {
        accessorKey: "borrowerInfo",
        header: "Borrower Info",
        enableColumnActions: false,
        size: 150,
        Cell: ({ cell, row }) => (
          <>
            <Typography>
              {`${row.original["firstName"]} ${row.original["lastName"]}`}
            </Typography>
            <Typography py={1}>{`${row.original["email"]}`}</Typography>
            <Typography>{`${row.original["phone"]}`}</Typography>
          </>
        ),
      },
      {
        accessorKey: "mloName",
        header: "MLO",
        enableColumnActions: false,
      },
      {
        accessorKey: "status",
        header: "Invitation Status",
        enableColumnActions: false,
      },

      //end
    ],
    []
  );
  const handleEmail = (id) => {
    getApiCallDynamic({ param: { id: id, service: SERVICE_TYPE.invitation }, path: API.loanInvitaionEmail, serverMsg: true })
  }
  const handleDelete = (id) => {
    deleteApiCallDynamic({ path: API.loanInvitaion, data: { id: id } });
    setreload(prev => !prev)
  };
  const [reload, setreload] = useState(false)

  return (
    <>

      <MainLoyOut>

        <CustomReactMaterialTable
          enableToolbarInternalActions={false}
          columnVisibility={{
            mloName: props.user.data.role == ROLE_LIST.mlo ? false : true,
          }}
          enablePagination={false}
          enableColumnFilters={false}
          refetch={reload}
          endPoint={API.loanInvitaion}
          columns={columns}
          showGlobalFilter={true}
          enableGlobalFilter={false}
          muiSearchTextFieldProps={{
            placeholder: isTab ? 'Search' : "Search for application number, MLO",
            variant: "outlined",
            sx: {
              width: isTab ? null : '25rem',
            }
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <Stack justifyContent={'space-between'} direction={{ xs: 'column', sm: 'row' }} width={'100%'} alignItems={{ xs: 'initial', sm: 'center' }} gap={2}>
              <Box>
                <Button
                  sx={{ display: profileInfo.role == ROLE_LIST.mlp ? "none" : null }}
                  variant="outlined"
                  onClick={() => {
                    handleClickOpen();
                    setEdit(false);
                  }}
                >
                  <AddIcon /> NEW BORROWER INVITE
                </Button>
              </Box>
              <MRT_GlobalFilterTextField table={table} />
            </Stack>
          )}
          enableRowActions={true}
          renderRowActions={({ cell, row, table }) => {
            return (
              <Box display="flex" direction="row" justifyContent="start">
                {(row.original.status !== 'Declined') &&
                  <>
                    <Tooltip title={'Edit Invitation'} arrow>
                      <IconButton onClick={() => {
                        handleClickOpen();
                        setEdit(true);
                        setData(row?.original);
                      }}>
                        <KSPencilAltIcon />
                      </IconButton>
                    </Tooltip>
                    <ConfirmationModel
                      body={
                        <Tooltip title={'Send Invitation'} arrow>
                          <IconButton>
                            <KSMailIcon />
                          </IconButton>
                        </Tooltip>
                      }
                      handleSubmit={() => {
                        handleEmail(row?.original?.id);
                      }}
                      message={
                        `Are you sure you want to send the invitation link ${row?.original?.firstName} ${row?.original?.lastName}   for Borrower role?`
                      }
                      title={"Confirmation"}
                    />
                  </>
                }
                <ConfirmationModel
                  body={
                    <Tooltip title={'Delete'} arrow>
                      <IconButton>
                        <KSTrashIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  handleSubmit={() => {
                    handleDelete(row?.original?.id);
                  }}
                  message={
                    `Are you sure you want to delete the invitation link sent to ${row?.original?.firstName} ${row?.original?.lastName}   for Borrower role?`
                  }
                  title={"Confirmation"}
                />

              </Box>
            );
          }}
        />
      </MainLoyOut >

      {/*new invitation modal */}
      < DialogCommonHooks
        title={edit ? "Edit Invitation" : "New Invitation"}
        show={openModal}
        handleClose={handleClose}
        body={
          < InvitationForm
            handleSubmit={handleSucessOpen}
            handleClose={handleClose}
            update={edit}
            data={data}
          />
        }
      />


      <DialogCommonHooks
        show={openSucess}
        handleClose={handleClose}
        body={
          <DialogSuccesMsg
            descSize={'25px'}
            description={edit ? "Your invitation has been successfully updated" : "Congratulations!!"}
            subDesc={edit ? "" : `Invitation has been sent to the Borrower ${data.firstName} ${data.lastName}`}
            handleSubmit={handleSucessOpen}
            handleClose={handleClose}
          />
        }
      />
    </>
  );
}
