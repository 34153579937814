import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import React, { useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { Path } from "../../routes/route-config";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomInput from "../../helpers/custom-input";
import useInput from "../../helpers/use-input";
import { postApiCallDynamic } from "../../services/api-service";
import { API } from "../../services/api-config";
import { useFormik } from "formik";
import { MAXLength, ValidationConstant } from "../../constants/validation";
import { SERVICE_TYPE } from "../../constants/constatnt-variable";
import AuthSideUi from "../../components/auth-side-ui";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBack";
import { AlertSeverity } from "../../helpers/alert-msg";

export default function SignIn(props) {
  const router = useNavigate();
  const location = useLocation();
  const website = location?.state?.website;

  let [email] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  let [password] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  let [shouldRemember, setshouldRemember] = useState(false);
  let [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (event) => {
    event?.preventDefault();

    let data = {
      shouldRemember: shouldRemember,
      email: email.value.trim(),
      password: password.value,
      qa: process.env.REACT_APP_QA_MODE,
      ...(website && { website: website }),
    };
    preSignIn(data);
  };

  const preSignIn = (data) => {
    postApiCallDynamic({
      data: data,
      refresh: (res) => {
        return res;
      },
      path: API.preSignin,
    }).then((res) => {
      if (
        res &&
        (res.status === 200 || res.status === 201 || res.status === 202)
      ) {
        router(Path.otp, {
          state: {
            ...location.state,
            ...res.data,
            ...res.requestData,
            from: SERVICE_TYPE.signin,
          },
        });
      }
    });
  };

  const handleValidation = (values) => {
    let errors = {};

    const re = ValidationConstant.emailRegx;

    if (re.test(String(email.value.trim()).toLowerCase()) === false) {
      errors.email = "Please enter yout valid Email ID";
    }

    if (password.value.trim() == "") {
      errors.password = "Please enter your Password";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: email.value,
      password: password.value,
    },
    validate: (values) => handleValidation(values),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box position={"relative"}>
        {/* Showing the alert notification on top of main layout */}
        <AlertSeverity />
        <Box position={"relative"}>
          <Grid container>
            {/* side background */}
            <AuthSideUi />
            {/* login form */}
            <Grid item md={6} sm={6} xs={12} height={"100vh"} overflow={"auto"}>
              <Box p={2}>
                <Grid container md={12} justifyContent="space-between">
                  <Grid item>
                    <Stack direction={"row"} spacing={2}>
                      <Button
                        onClick={() => {
                          router(-1);
                        }}
                      >
                        <ArrowBackOutlinedIcon />
                      </Button>
                    </Stack>
                  </Grid>
                  <Grid item sx={{ textAlign: "right" }}>
                    <Typography>
                      New here? &nbsp;
                      <Link to={Path.signup} style={{ color: "#2f80ed" }}>
                        Sign Up
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box
                px={{ xs: 2, md: 20 }}
                pb={{ xs: 2, md: 3 }}
                minHeight="85vh"
                justifyContent="center"
                flexDirection="column"
                display="flex"
              >
                <Typography variant="h4" fontWeight="bold">
                  Sign In
                </Typography>

                {/* For Every Input Field */}
                {/* Kindly follow the 1st component for all options */}
                <Box mt={2} width={'368px'}>
                  <CustomInput
                    required
                    change={email}
                    // style={{ borderRadius: '8px', border: '1px solid #999' }}
                    label={"Email ID"}
                    error={formik.errors.email}
                    maxLength={MAXLength.email}
                    placeholder={"Please enter your Email ID"}
                  />
                </Box>

                <Box width={'368px'}>
                  <Link
                    style={{
                      color: "#2F80ED",
                      float: "right",
                      fontFamily: "DMSans-Medium",
                    }}
                    to={"/forget"}
                  >
                    Forgot?
                  </Link>

                  <CustomInput
                    // style={{ borderRadius: '8px', border: '1px solid #999' }}
                    required
                    type={showPassword ? "text" : "password"}
                    eicon={
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    }
                    maxLength={MAXLength.password}
                    id="password"
                    label={"Password"}
                    change={password}
                    error={formik.errors.password}
                    placeholder={"Please enter your Password"}
                  />
                </Box>

                <Box mb={2}>
                  <FormControlLabel
                    control={<Checkbox checked={shouldRemember} />}
                    label="Remember me"
                    onClick={() => {
                      setshouldRemember((prev) => !prev);
                    }}
                  />
                </Box>

                <Box mb={2} width={'368px'}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    fullWidth
                  >
                    Sign In
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </form>
  );
}
