import React from 'react'
import { AttachMoney } from '@mui/icons-material'
import { Button, Checkbox, FormControlLabel, Stack, Typography, Box } from '@mui/material'
import CustomInput from '../../../../../../helpers/custom-input'

import { Controller, useForm, useWatch } from 'react-hook-form'
import { PropertyAddress } from '../../../../../../components/loan-application/property-address'
import { usPhoneNumberFormatDefaultValue } from '../../../../../../constants/validation'
import { TimeStampToMonthDateYearForInput } from '../../../../../../helpers/time-formater'
import { AdditionalIncomeValidation } from '../../../../../../constants/validation-rules'
import CustomLargeLabel from '../../../../../../helpers/large-label'
import CustomRadioChip from '../../../../../../helpers/radio-chip'
import { ExtraIncomeValidation } from '../../../../../../constants/validation-rules'
import {
    PAY_FREQUENCY, ADDITIONAL_INCOME_TYPE
} from "../../../../../../constants/constatnt-variable";
import CustomDropDown from "../../../../../../helpers/dropdown/custom-dropdown";
import { ADDITIONAL_INCOME_STATUS } from '../../../../../../constants/constatnt-variable'
import { formatNumericValue, parseNumericValue } from '../../../../../../helpers/numeric-format'

export default function AdditionalIncomeForm({ onSubmit, editItem }) {


    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            incomeSource: editItem?.incomeSource,
            monthIncome: editItem?.monthIncome,

        }
    })
    return (
        <form id='additionalIncome' onSubmit={handleSubmit(onSubmit)}>
            <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>

                <Box width={'100%'} my={2} >
                    <Box>
                        <Controller
                            name='incomeSource'
                            control={control}
                            rules={AdditionalIncomeValidation.incomeSource}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    label={'Account Type'}
                                    required
                                    option={ADDITIONAL_INCOME_TYPE}
                                    error={errors?.incomeSource?.message}
                                />
                            )}
                        />

                        <Controller
                            name="monthIncome"
                            control={control}
                            rules={AdditionalIncomeValidation.monthIncome}
                            render={({ field }) => (
                                <CustomInput {...field}
                                    type={'text'}
                                    label={'Monthly Income'}
                                    required
                                    sicon={<AttachMoney />}
                                    error={errors?.monthIncome?.message}
                                    value={formatNumericValue(field.value)}
                                    onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                />
                            )}
                        />


                    </Box>
                </Box>






                <Stack direction={'row-reverse'} spacing={2}>
                    <Button form='additionalIncome' variant='contained' type='submit'>
                        {editItem.length != 0 ? 'Update' : 'Add'}
                    </Button>
                </Stack>
            </Stack>











        </form >
    )
}
