import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Path } from "../routes/route-config";
import React from 'react'


const RequireAuth = ({ allowedRoles, ...props }) => {

  const location = useLocation();

  let role = props?.user?.data?.role;

  const Checker = () => {

    if (allowedRoles.includes(role)) {
      return <Outlet />
    }
    else {
      if (props?.user) {
        return <Navigate to="/unauthorized" state={{ initialLocation: "/" }} replace />

      }
      else {
        return <Navigate to={Path.signin} state={{ initialLocation: location }} replace />

      }
    }
  }

  // console.log("Role:", role);
  // console.log("Allowed Roles:", allowedRoles);


  return Checker()
};

export default RequireAuth;
