import React from 'react';
import { Grid, Typography, Tooltip, IconButton, useMediaQuery } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CustomTooltip from "../../helpers/custom-tooltip";

const AddressHelper = ({ loanDetails, DTIValues }) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const fullAddress = `${loanDetails?.page?.addressLine1}, ${loanDetails?.page?.addressLine2}, ${loanDetails?.page?.city}, ${loanDetails?.page?.state} ${loanDetails?.page?.zip}`;
    const fullDTI = `Calculated DTI: ${DTIValues.frontendDTI} / ${DTIValues.backendDTI} %`;
    return (
        <Grid ml={6}>
            <Grid
                item
                xs={12}
                borderRight={2}
                pr={{ xs: 1, md: 0 }}
                borderColor={"#C4C4C420"}
                minWidth={'100%'}
                mt={1}
            >
                {isMobile ? (
                    <CustomTooltip title={fullAddress}>
                        <Typography variant="p">
                            Address: {`${fullAddress.substring(0, 21)}...`}
                            <IconButton size="small" sx={{ color: '#2f80ed' }}>
                                <InfoIcon fontSize="small" />
                            </IconButton>
                        </Typography>
                    </CustomTooltip>
                ) : (
                    <Typography variant="p">
                        Address: {fullAddress}
                    </Typography>
                )}
            </Grid>

            {/* <Grid>
                {isNaN(DTIValues.frontendDTI) || isNaN(DTIValues.backendDTI) ? (
                    <Typography variant="p">Calculated DTI: - </Typography>
                ) : (
                    <Typography variant="p">
                        Calculated DTI: {DTIValues.frontendDTI} / {DTIValues.backendDTI} %
                    </Typography>
                )}
            </Grid>  */}
            <Grid>
                {isNaN(DTIValues.frontendDTI) || isNaN(DTIValues.backendDTI) ? (
                    <Typography variant="p">Calculated DTI: - </Typography>
                ) : (

                    <CustomTooltip title={fullDTI}>
                        <Typography variant="p">
                            Calculated DTI: {`${DTIValues.frontendDTI.toString().substring(0, 5)} / ${DTIValues.backendDTI.toString().substring(0, 21)}...`}
                            <IconButton size="small" sx={{ color: '#2f80ed' }}>
                                <InfoIcon fontSize="small" />
                            </IconButton>
                        </Typography>
                    </CustomTooltip>
                )
                }
            </Grid>
        </Grid>

    );
};

export default AddressHelper;
