import {Card, CardContent, Grid, Typography} from '@mui/material'
import React from 'react'

export default function LoanCard({ title, data }) {
    return (
        <Card sx={{ margin: "24px 0px 24px 24px ", padding: 1, minWidth: 90, maxWidth: 300, borderRadius: 2, }} variant='outlined' >
            <CardContent >
                <Grid container justifyContent={'center'} alignItems={'center'} >
                    <Grid item md={6} xs={6}>
                        <Typography color='primary' variant='body3'>{title}</Typography>
                    </Grid>
                    <Grid item md={6} xs={6}>
                        <Typography variant='h2' textAlign={'center'}>{data}</Typography>


                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    )
}
