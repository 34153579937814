import {Box, Button, Stack, Typography} from '@mui/material'
import React from 'react'

export default function ContactConfirmation({ handleClose, setPage }) {
    return (
        <Box width={'100%'} my={2} >
            <Typography>
                {`We need minimum of 2 years of living history, Please enter your previous address.`}
            </Typography>
            <Stack direction={'row-reverse'} gap={2} >
                <Button type='button' onClick={() => { setPage("additional-contact"); handleClose() }} variant='contained' >Ok</Button>
                <Button variant='text' onClick={handleClose}>Cancel</Button>
            </Stack>
        </Box>
    )
}
