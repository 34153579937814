import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, Typography, } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function CustomDropDown({
  option,
  defaultValue = null,
  rowData = null,
  changeCallBack = () => {
    // Nothing
  },
  disabled,
  id,
  justifyContent = 'center',
  state,
  style,
  label,
  required = false,
  field,
  colorDisabled,
  limitWidth,
  labelComponent,
  backgroundColor,
  labelRightComponent,
  error = null
}) {
  const [status, setStatus] = useState(() => {
    return defaultValue ? defaultValue : null;
  });

  useEffect(() => {
    if (!status) setStatus(defaultValue ? defaultValue : null)
  }, [defaultValue])

  const handleChange = (event) => {
    state?.saveValue(event.target.value)
    changeCallBack({ id: id, value: event.target.value });
    setStatus(event.target.value);
  };
  return (
    <Box justifyContent={justifyContent} mt={.5} mb={2} >
      {labelComponent ? labelComponent :
        <Stack direction={'row'} justifyContent={'space-between'} >
          <InputLabel>
            {label}
            {required ?
              <Typography component={'span'} color={'error.main'} >*</Typography>
              : null
            }
          </InputLabel>
          <InputLabel>
            {labelRightComponent}
          </InputLabel>
        </Stack>
      }
      <FormControl style={limitWidth ? { minWidth: '50%' } : {}} fullWidth={limitWidth ? false : true}>

        <Select
          style={style}
          disabled={disabled}
          displayEmpty
          value={status}
          // onChange={handleChange}
          defaultValue={null}
          {...(field ? field : { onChange: handleChange })}>
          <MenuItem color="Primary" style={{ display: "none" }} name={null} value={null} disabled>
            <InputLabel htmlFor='Select'>
              Select Option
            </InputLabel>
          </MenuItem>
          {option?.map((item) => {
            return (
              <MenuItem key={item.value} name={item.label} value={item.value} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                {item.label}
              </MenuItem>

            )
          })}
        </Select>
      </FormControl>
      {/* {(error && <FormHelperText sx={{ pl: 2 }} error>{error}</FormHelperText>)
      } */}
      {(error && (
        <FormHelperText sx={{ pl: 2, fontSize: 14 }} error>
          {error}
        </FormHelperText>
      ))}

    </Box >
  );
}
