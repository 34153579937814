import { ROLE_LIST } from "./constatnt-variable"

const AccessableAbleRoleSettings = [
    ROLE_LIST.mloOwner,
    ROLE_LIST.broker,
    ROLE_LIST.mlo,
]

const AccessableRoleForProfile = [
    ROLE_LIST.broker,
    ROLE_LIST.mloOwner,
    ROLE_LIST.mlp,
    ROLE_LIST.mlo,
]

const AccessableRoleForProfileEntityName = [
    ROLE_LIST.broker,
    ROLE_LIST.mloOwner,

]
const AccessableRoleForAvatarAndSignature = [
    ROLE_LIST.broker,
    ROLE_LIST.mloOwner,
    ROLE_LIST.borrower,
    ROLE_LIST.mlp,
    ROLE_LIST.mlo,
]



export { AccessableAbleRoleSettings, AccessableRoleForProfile, AccessableRoleForAvatarAndSignature, AccessableRoleForProfileEntityName }