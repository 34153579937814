import React, { useEffect, useState } from 'react'
import { AttachMoney } from '@mui/icons-material'
import { Button, Checkbox, FormControlLabel, Stack, Typography, Box, useMediaQuery } from '@mui/material'
import CustomInput from '../../../../../../../helpers/custom-input'

import { Controller, useForm, useWatch } from 'react-hook-form'
import { PropertyAddress } from '../../../../../../../components/loan-application/property-address'
import { usPhoneNumberFormatDefaultValue } from '../../../../../../../constants/validation'
import { TimeStampToMonthDateYearForInput } from '../../../../../../../helpers/time-formater'
import { EmployedValidation } from '../../../../../../../constants/validation-rules'
import CustomLargeLabel from '../../../../../../../helpers/large-label'
import CustomRadioChip from '../../../../../../../helpers/radio-chip'
import { ExtraIncomeValidation } from '../../../../../../../constants/validation-rules'
import {
    PAY_FREQUENCY,
} from "../../../../../../../constants/constatnt-variable";
import CustomDropDown from "../../../../../../../helpers/dropdown/custom-dropdown";
import { kstheme } from "../../../../../../../theme/theme";
import { formatNumericValue, parseNumericValue } from '../../../../../../../helpers/numeric-format'

export default function EmployedForm({ onSubmit, editItem }) {
    const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"))

    const { control, setValue, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            employmentStatus: editItem?.employmentStatus,
            businessName: editItem?.businessName,
            employNumber: usPhoneNumberFormatDefaultValue(editItem?.employNumber),
            positionOfTitle: editItem?.positionOfTitle,
            startDate: TimeStampToMonthDateYearForInput(editItem?.startDate),
            endDate: TimeStampToMonthDateYearForInput(editItem?.endDate),
            profession: editItem?.profession,
            addressLine1: editItem?.addressLine1,
            addressLine2: editItem?.addressLine2,
            unit: editItem?.unit,
            city: editItem?.city,
            stateOfCountry: editItem?.stateOfCountry ? editItem?.stateOfCountry : null,
            zip: editItem?.zip,
            isStillWorking: editItem?.isStillWorking == "Yes" ? true : false,


            monthlyIncome: editItem?.monthlyIncome,
            payFrequency: editItem?.payFrequency ? editItem?.payFrequency : null,
            country: editItem?.country ? editItem?.country : null,
            isSideIncomeAvailable: editItem?.isSideIncomeAvailable,
            avgMonthlyIncome: editItem?.avgMonthlyIncome,
            avgMonthlyOvertime: editItem?.avgMonthlyOvertime,
            militaryEntitlement: editItem?.militaryEntitlement,
            avgMonthlyComission: editItem?.avgMonthlyComission,
            employedByOther: editItem?.employedByOther,
        }
    })

    const { stateofCountry = null,
        isStillWorking,
        employedByOther,
        payFrequency = null,
        isSideIncomeAvailable,
        country = null
    } = useWatch({ control })


    useEffect(() => {
        if (isStillWorking === true) {
            setValue('endDate', null);
        }
    }, [isStillWorking]);


    const [formatWorkPhone, setFormatWorkPhone] = useState(formatPhoneNumber(editItem?.employNumber));
    const [unformatWorkPhone, setUnformatWorkPhone] = useState(editItem?.employNumber || '');


    function formatPhoneNumber(phoneNumber) {
        if (!phoneNumber) return '';
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return phoneNumber;
    }

    function handleWorkPhoneChange(event) {
        let unformattedValue = event.target.value.replace(/\D/g, '');

        if (unformattedValue.length > 10) {
            unformattedValue = unformattedValue.slice(0, 10);
        }

        setUnformatWorkPhone(unformattedValue);
        setFormatWorkPhone(formatPhoneNumber(unformattedValue));
    }

    function handleWorkPhoneChangeDB() {
        setValue('employNumber', unformatWorkPhone)
    }


    return (
        <form id='employed' onSubmit={handleSubmit(onSubmit)}>


            <Controller
                name='businessName'
                control={control}
                rules={EmployedValidation.businessName}
                render={({ field }) => (
                    <CustomInput {...field} {...field} type={'text'} label={`Employer or Business Name`} required error={errors?.businessName?.message} />
                )}
            />
            <Controller
                name='employNumber'
                control={control}
                rules={EmployedValidation.employNumber}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={`Employer Phone Number`} required error={errors?.employNumber?.message}
                        value={formatWorkPhone}
                        onChange={handleWorkPhoneChange}
                        onBlur={handleWorkPhoneChangeDB}
                    />
                )}
            />

            <Controller
                name='positionOfTitle'
                control={control}
                rules={EmployedValidation.positionOfTitle}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={`Position or Title`} required error={errors?.positionOfTitle?.message} />
                )}
            />


            {/* <Controller
                name='startDate'
                control={control}
                rules={EmployedValidation.startDate}
                render={({ field }) => (
                    <CustomInput
                        {...field}
                        onNextLine={isMobile}
                        type={'date'}
                        label={`Start Date`}
                        labelRightComponent={
                            <FormControlLabel
                                control={
                                    <Controller
                                        name='isStillWorking'
                                        control={control}
                                        render={({ field }) => (
                                            <Checkbox checked={isStillWorking}  {...field} />
                                        )}
                                    />
                                }
                                label=
                                {`Are you still working there?`}
                            />
                        }
                        required
                        error={errors?.startDate?.message}
                    />
                )}
            /> */}
            <Controller
                name='startDate'
                control={control}
                rules={EmployedValidation.startDate}
                render={({ field }) => (
                    <>
                        <FormControlLabel
                            control={
                                <Controller
                                    name='isStillWorking'
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox checked={isStillWorking} {...field} />
                                    )}
                                />
                            }
                            label={`Are you still working there?`}
                        />
                        <CustomInput
                            style={{ display: 'block' }}
                            {...field}
                            // onNextLine={isMobile}
                            type={'date'}
                            label={`Start Date`}
                            required
                            error={errors?.startDate?.message}
                        />
                    </>
                )}
            />


            {isStillWorking ? null : <Controller
                name='endDate'
                control={control}
                rules={EmployedValidation.endDate}
                render={({ field }) => (
                    <CustomInput {...field} type={'date'} label={`End Date`} required error={errors?.endDate?.message} />
                )}
            />}



            <Controller
                name='profession'
                control={control}
                rules={EmployedValidation.profession}
                render={({ field }) => (
                    <CustomInput {...field} type={'number'} label={`Years in this line of profession `} required error={errors?.profession?.message} />
                )}
            />
            <Controller
                name='monthlyIncome'
                control={control}
                rules={EmployedValidation.monthlyIncome}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={`Base Monthly Income `} sicon={<AttachMoney />} required error={errors?.monthlyIncome?.message}
                        value={formatNumericValue(field.value)}
                        onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                    />
                )}
            />


            <Controller
                name="payFrequency"
                control={control}
                rules={EmployedValidation.payFrequency}
                render={({ field }) => (
                    <CustomDropDown
                        {...field}
                        field={field}
                        label={'Pay Frequency'}
                        required
                        option={PAY_FREQUENCY}
                        error={errors?.payFrequency?.message}
                    />

                )}
            />

            <Typography variant='h6'>{`Employer or Business Address`}</Typography>
            <PropertyAddress control={control} Controller={Controller} errors={errors} stateOfCountry={stateofCountry} country={country} />


            <Stack direction={'column'} justifyContent={'space-between'} py={2} height={'inherit'} overflow={'auto'}>
                <Box>


                    <Box maxWidth={{ xs: '100%', md: '100%' }} mt={2}>

                        <Controller
                            name='isSideIncomeAvailable'
                            control={control}
                            rules={ExtraIncomeValidation.isSideIncomeAvailable}
                            render={({ field }) => (
                                <CustomRadioChip
                                    labelComponent={<CustomLargeLabel value={`Do you receive bonus, military entitlement or commission income?`} required />}
                                    {...field}
                                    field={field}
                                    state={isSideIncomeAvailable}
                                    data={[
                                        { id: 'yes', value: "Yes", label: "Yes" },
                                        { id: 'no', value: "No", label: "No" },
                                    ]}
                                    error={errors?.isSideIncomeAvailable?.message}
                                />
                            )}
                        />
                        {isSideIncomeAvailable == 'Yes' ?
                            <>
                                <Controller
                                    name='avgMonthlyIncome'
                                    control={control}
                                    rules={ExtraIncomeValidation.avgMonthlyIncome}
                                    render={({ field }) => (
                                        <CustomInput {...field} type={'text'} label={`Average monthly bonus`} sicon={<AttachMoney />}
                                            value={formatNumericValue(field.value)}
                                            onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                            error={errors?.avgMonthlyIncome?.message} />
                                    )}
                                />

                                <Controller
                                    name='avgMonthlyOvertime'
                                    control={control}
                                    rules={ExtraIncomeValidation.avgMonthlyOvertime}
                                    render={({ field }) => (
                                        <CustomInput {...field} type={'text'} label={`Average monthly overtime`} sicon={<AttachMoney />}
                                            value={formatNumericValue(field.value)}
                                            onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                            error={errors?.avgMonthlyOvertime?.message} />
                                    )}
                                />

                                <Controller
                                    name='militaryEntitlement'
                                    control={control}
                                    rules={ExtraIncomeValidation.militaryEntitlement}
                                    render={({ field }) => (
                                        <CustomInput {...field} type={'text'} label={`Military entitlement `} sicon={<AttachMoney />}
                                            value={formatNumericValue(field.value)}
                                            onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                            error={errors?.militaryEntitlement?.message} />
                                    )}
                                />

                                <Controller
                                    name='avgMonthlyComission'
                                    control={control}
                                    rules={ExtraIncomeValidation.avgMonthlyComission}
                                    render={({ field }) => (
                                        <CustomInput {...field} type={'text'} label={`Average monthly commission`} sicon={<AttachMoney />}
                                            value={formatNumericValue(field.value)}
                                            onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                            error={errors?.avgMonthlyComission?.message} />
                                    )}
                                />
                            </>
                            : null
                        }

                    </Box>

                </Box>
            </Stack>















            <FormControlLabel
                control={
                    <Controller
                        name='employedByOther'
                        control={control}
                        render={({ field }) => (
                            <Checkbox checked={employedByOther}  {...field} />
                        )}
                    />
                }
                label=
                {`I am employed by a family member, property seller, real estate agent, or other party to the transaction. `}
            />






            <Stack direction={'row-reverse'} spacing={2}>
                <Button form='employed' variant='contained' type='submit'>
                    {editItem.length != 0 ? 'Update' : 'Add'}
                </Button>
            </Stack>










        </form >
    )
}
