import { CheckCircle } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Typography,
  Link as MuLink,
  Stack,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { kstheme } from "../../theme/theme";

export default function LoanStatusTab({
  tabName,
  tabComplete,
  isComplete,
  pageHref,
  tabProgress = -1,
  tabChild,
  tabActive,
  inProgress = false,
  currentFormPanel,
  isLocked = false,
  role,
  mobileNvbarClose,
}) {
  const router = useNavigate();

  const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));

  const excludedPageRef = ["borrower-info", "documents", "partner-info"];

  const tabColor = () => {
    if (tabActive || isComplete) return "Black";
    else return "A8.main";
  };

  const tabProgressIcon = () => {
    if (tabProgress == 100) return <CheckCircle color="success" />;

    if (inProgress)
      return (
        <Box position={"relative"}>
          <CircularProgress
            variant="determinate"
            value={tabProgress}
            size={22}
            thickness={7}
          />
          <CircularProgress
            variant="determinate"
            value={100}
            size={22}
            thickness={7}
            color={"A1"}
            sx={{ position: "absolute", left: 0 }}
          />
        </Box>
      );
    else
      return (
        <Box>
          <CircularProgress
            variant="determinate"
            value={0}
            size={22}
            thickness={7}
            color={"A1"}
          />
        </Box>
      );
  };

  //return ture/false if exist
  const assert_excludedPageRef = (pageref) => {
    return excludedPageRef.includes(pageref);
  };

  const handleNavigation = () => {
    if (role == "Borrower") {
      if (isLocked) {
        return;
      }
    }
    if (isComplete) {
      if (!assert_excludedPageRef(pageHref)) {
        router(pageHref);
      }
    } else if (currentFormPanel) {
      router(currentFormPanel);
    }

    isMobile && mobileNvbarClose();
  };
  return (
    <>
      <MuLink
        onClick={handleNavigation}
        style={{ textDecoration: "none", color: "#000" }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          ml={tabChild ? 2 : 0}
          p={1}
          my={0.5}
          gap={2}
          borderLeft={tabActive ? 2 : null}
          borderColor={tabActive ? "primary.main" : null}
          bgcolor={tabActive ? "rgba(47, 128, 237, 0.3)" : null}
          sx={{ borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}
        >
          <Typography
            color={tabColor}
            variant={"body1"}
            minWidth={"max-content"}
          >
            {tabName}
          </Typography>

          {tabProgressIcon()}
        </Stack>
      </MuLink>
    </>
  );
}
