import { Box, Container, Grid, Typography, colors } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

const customStyle = makeStyles({

  ContentHeader: {
    fontFamily: "Inter-Medium !important",
    fontSize: "24px !important",
    color: "#221F1F",
  },
  ContentBody: {
    fontFamily: "DMSans-Regular !important",
    fontSize: "20px !important",
    color: "#000000",
    opacity: 0.8,
    textAlign: "justify",
  },
});

function Disclaimer() {
  const classes = customStyle();
  return (
    <>
      <Box pt={4} id={"disclaimer"} style={{ backgroundColor: '#fff' }}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} md={12} px={{ xs: 2 }} mb={6}>
              <Typography variant="h2" color={"primary.main"} my={5} fontWeight={700}>
                {`Disclaimers`}
              </Typography>
              <Box>
                <Typography mb={2} className={classes.ContentHeader} style={{ fontSize: '20px !important' }}>
                  {`Forward-Looking Statements`}
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  {`The `}<Typography variant="span" sx={{ color: "#2F80ED" }}>{`Kramasoft`}</Typography> {`website contains forward-looking
                  statements within the meaning of Section 27A of the Securities Act of 1933, as amended, and Section 21E of the Securities
                  Exchange Act of 1934, as amended. These statements generally relate to future events, future performance or expectations and
                  involve substantial risks and uncertainties. Forward-looking statements on the Kramasoft website may include, but are not limited
                  to, our expectations regarding our product roadmap, future products/features, the timing of new product/feature introductions,
                  quotations of management, expectations of future results of operations or financial performance of Kramasoft LLC.
                  (“Kramasoft,” the “Company,” “we,” “us,” or similar terms), market size and growth opportunities, plans for future operations,
                  competitive position, technological capabilities, and strategic relationships, as well as assumptions relating to the foregoing.`}
                </Typography>
                <Typography className={classes.ContentBody} mb={3}>
                  {`The forward-looking statements contained on the Kramasoft website are subject to risks and uncertainties that could cause actual
                  outcomes to differ materially from the outcomes predicted. Further information on these risks and uncertainties are set forth in our
                  filings with the Securities and Exchange Commission.`}
                </Typography>
                <Typography className={classes.ContentBody} mb={6}>
                  {`All forward-looking statements on the Kramasoft website are based on information available to Kramasoft and assumptions and
                  beliefs as of the date hereof. Except as required by law, Kramasoft does not undertake any obligation to publicly update or revise
                  any forward-looking statement, whether as a result of new information, future developments, or otherwise.`}
                </Typography>
              </Box>
              <Box>
                <Typography
                  mb={2}
                  mt={6}
                  className={classes.ContentHeader}
                >
                  {`Third-Party Disclosure`}
                </Typography>
                <Typography className={classes.ContentBody} mb={3}>
                  {`The Kramasoft website makes information of third parties available, including articles, news reports, surveys, industry data, blog posts,
                  and company information. Links that may be accessed via this website and any other third-party content are being provided as a convenience
                  and for informational purposes only.`}
                </Typography>
                <Typography className={classes.ContentBody} mb={3}>
                  {`Kramasoft has no control over these third-party websites and is not responsible for any such third-party content that may be accessed via
                  this website. Links that may be accessed via this website and any other third-party content do not constitute an endorsement or an approval
                  by Kramasoft of any of the products, services or opinions or other content of the third-party contained in these websites, nor the
                  organizations publishing these websites, and hereby disclaims any responsibility for such content. Kramasoft bears no responsibility
                  for the accuracy, legality or content of the external site or for that of subsequent links.`}
                </Typography>
                <Typography className={classes.ContentBody} mb={3}>
                  {`The content of, including materials and information contained on, any website to which you link from this website is solely the responsibility
                  of the provider of that website. If you access any of the third-party websites linked on the Kramasoft website, you do so at your own risk.
                  Contact the external third-party website for answers to questions regarding its content.`}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default Disclaimer;
