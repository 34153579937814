import {
    Box,
    Chip,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import CustomTooltip from './custom-tooltip';

export default function CustomRadioChipWithTooltipOnHover({
    data,
    state,
    label,
    field,
    required = false,
    radioWidth,
    labelStyle,
    error = null,
    labelComponent,
    tooltipTitle,
    chipValue,
    onOptionHover
}) {
    const [hoveredOption, setHoveredOption] = useState(null);

    const handleMouseEnter = (value) => {
        setHoveredOption(value);
        if (onOptionHover) {
            onOptionHover(value);
        }
    };

    const handleClick = (value) => {
        setHoveredOption(value);
        if (onOptionHover) {
            onOptionHover(value);
        }
    };

    return (
        <Box my={2}>
            <FormControl>
                {labelComponent ? (
                    labelComponent
                ) : (
                    <FormLabel style={labelStyle}>
                        {label}
                        {required ? (
                            <Typography component={"span"} color={"error.main"}>
                                *
                            </Typography>
                        ) : null}
                    </FormLabel>
                )}

                <RadioGroup row style={radioWidth ? { width: radioWidth } : {}}>
                    {data.map((dataItem) => (
                        <React.Fragment key={dataItem.value}>
                            <FormControlLabel
                                sx={{ margin: 0 }}
                                control={
                                    <Radio
                                        id={dataItem.value}
                                        value={dataItem.value}
                                        sx={{ display: "none" }}
                                    />
                                }
                                label={
                                    <CustomTooltip
                                        title={tooltipTitle}
                                        arrow
                                    >
                                        <Chip
                                            label={dataItem.label}
                                            color={
                                                field?.value == dataItem?.value || state?.value == dataItem?.value
                                                    ? "primary"
                                                    : "default"
                                            }
                                            clickable={true}
                                            variant="outlined"
                                            sx={{ marginRight: 1.5, marginY: 1.5 }}
                                            onMouseEnter={() => handleMouseEnter(dataItem.value)}
                                            onMouseLeave={() => setHoveredOption(null)}
                                            onClick={() => handleClick(dataItem.value)} // Added this line
                                        />
                                    </CustomTooltip>
                                }
                                {...(field ? field : { onClick: handleClick })}
                            />
                        </React.Fragment>
                    ))}
                </RadioGroup>
                {error && (
                    <FormHelperText sx={{ pl: 0, fontSize: 14 }} error>
                        {error}
                    </FormHelperText>
                )}
            </FormControl>
        </Box>
    );
}
