// noinspection EqualityComparisonWithCoercionJS

import axios from "axios";
import { AlertError, ServerSuccessMsg, SuccessMsg } from "../helpers/alert-msg";
import { UPDATE } from "../constants/constatnt-variable";
import { setRecoil } from "recoil-nexus";
import { globalSpinner } from "../recoil/profile-atom";

// if api call is successfull then refresh function will be called
export function postApiCallDynamic({ data = {}, refresh = () => {
  // Nothing

}, path = "", header = "" }) {
  setRecoil(globalSpinner, true)
  if (header === UPDATE) {
    return axios
      .put(path, data, {
        withCredentials: true,
        credentials: 'include',

        headers: { "XTR-API-Action": header }
      })
      .then((res) => {
        if (
          res &&
          (res.status === 200 ||
            res.status === 201 ||
            res.status === 202 ||
            res.status === 203 ||
            res.status === 204)
        ) {
          // SuccessMsg(res.status);
          return refresh({ ...res, requestData: data });
        }
      })
      .catch((error) => AlertError(error))
      .finally(() => {
        setRecoil(globalSpinner, false); // Set the spinner to false after the API call is done
      });
  }
  return axios
    .post(path, data, {
      withCredentials: true,
      credentials: 'include',
      headers: { "XTR-API-Action": header }
    })
    .then((res) => {
      if (
        res &&
        (res.status === 200 ||
          res.status === 201 ||
          res.status === 202 ||
          res.status === 203 ||
          res.status === 204)
      ) {
        // SuccessMsg(res.status);
        return refresh({ ...res, requestData: data });
      }
    })
    .catch((error) => AlertError(error))
    .finally(() => {
      setRecoil(globalSpinner, false); // Set the spinner to false after the API call is done
    });
}
export function getApiCallDynamic({ path = "", param = {}, serverMsg = false }) {
  return axios
    .get(path, {
      withCredentials: true,
      credentials: 'include',

      params: param, headers: { "XTR-API-Action": "read" }
    })
    .then(res => {

      serverMsg && ServerSuccessMsg(res)

      return res;
    })
    .catch((error) => AlertError(error));
}

export function postApiCallDynamicWithOutReturn(
  {
    data = {},
    refresh = () => {
      // Nothing

    },
    path = "",
    header = ""
  }
) {
  setRecoil(globalSpinner, true)
  if (header === UPDATE) {
    return axios
      .put(path, data, {
        withCredentials: true,
        credentials: 'include',

        headers: { "XTR-API-Action": header }
      })
      .then((res) => {
        if (
          res &&
          (res.status === 200 ||
            res.status === 201 ||
            res.status === 202 ||
            res.status === 203 ||
            res.status === 204)
        ) {
          SuccessMsg(res.status);
          refresh();
        }
      })
      .catch((error) => AlertError(error))
      .finally(() => {
        setRecoil(globalSpinner, false); // Set the spinner to false after the API call is done
      });
  }
  return axios
    .post(path, data, {
      withCredentials: true,
      credentials: 'include',

      headers: { "XTR-API-Action": header }
    })
    .then((res) => {
      if (
        res &&
        (res.status === 200 ||
          res.status === 201 ||
          res.status === 202 ||
          res.status === 203 ||
          res.status === 204)
      ) {
        SuccessMsg(res.status);
        refresh();
      }
    })
    .catch((error) => AlertError(error))
    .finally(() => {
      setRecoil(globalSpinner, false); // Set the spinner to false after the API call is done
    });
}

export function deleteApiCallDynamic({ path = "", param = {}, data = {}, refresh = () => {
  // Nothing

} }) {
  setRecoil(globalSpinner, true);
  return axios
    .delete(path, {
      withCredentials: true,
      credentials: 'include',
      params: param,
      data: data,
    })
    .then((res) => {
      if (
        res &&
        (res.status === 200 ||
          res.status === 201 ||
          res.status === 202 ||
          res.status === 203 ||
          res.status === 204)
      ) {
        SuccessMsg(res.status);
        return refresh({ ...res, requestData: data });
      }
    })
    .catch((error) => AlertError(error))
    .finally(() => {
      setRecoil(globalSpinner, false); // Set the spinner to false after the API call is done
    });
}


export function uploadFile({ docId, formId, file, serviceType, path }) {
  let formData = new FormData();

  formData.append("file", file);
  serviceType && formData.append("serviceType", serviceType);
  docId && formData.append("docId", docId);
  formId && formData.append("formId", formId);

  return axios.post(path, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}


export function postApiCallDynamicWithStatusReturnOnly({
  data = {},
  refresh = () => {
    // Nothing
  },
  path = "",
  header = ""
}) {
  setRecoil(globalSpinner, true);
  if (header === UPDATE) {
    return axios
      .put(path, data, {
        withCredentials: true,
        credentials: 'include',
        headers: { "XTR-API-Action": header }
      })
      .then((res) => handleResponse(res, refresh))
      .catch((error) => handleErrorResponse(error))
      .finally(() => {
        setRecoil(globalSpinner, false);
      });
  } else {
    return axios
      .post(path, data, {
        withCredentials: true,
        credentials: 'include',
        headers: { "XTR-API-Action": header }
      })
      .then((res) => handleResponse(res, refresh))
      .catch((error) => handleErrorResponse(error))
      .finally(() => {
        setRecoil(globalSpinner, false);
      });
  }
}

function handleResponse(res, refresh) {
  if (
    res &&
    (res.status === 200 ||
      res.status === 201 ||
      res.status === 202 ||
      res.status === 203 ||
      res.status === 204)
  ) {
    SuccessMsg(res.status);
    refresh();
    return res; // Resolve with the response object
  } else {
    throw new Error(`HTTP error! Status: ${res.status}`);
  }
}

function handleErrorResponse(error) {
  AlertError(error);
  throw error; // Rethrow the error to propagate it to the caller
}



export function deleteApiCallDynamicWithStatus({ path = "", param = {}, data = {}, refresh = () => {
  // Nothing
} }) {
  setRecoil(globalSpinner, true);
  return axios
    .delete(path, {
      withCredentials: true,
      credentials: 'include',
      params: param,
      data: data,
    })
    .then((res) => {
      if (
        res &&
        (res.status === 200 ||
          res.status === 201 ||
          res.status === 202 ||
          res.status === 203 ||
          res.status === 204)
      ) {
        SuccessMsg(res.status);
        refresh({ ...res, requestData: data });
        return res.status; // Return the status
      }
    })
    .catch((error) => {
      AlertError(error);
      throw error; // Rethrow the error to propagate it to the caller
    })
    .finally(() => {
      setRecoil(globalSpinner, false); // Set the spinner to false after the API call is done
    });
}
