import { ArrowBackIos, ArrowForwardIos, CheckCircleOutline, CloudUpload, Lock } from '@mui/icons-material'
import { Box, Button, Chip, Grid, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import CustomCheckboxFileUpload from '../../../../../helpers/custom-checkbox-file-upload'
import { Controller, useForm } from 'react-hook-form'
import CustomDropDown from '../../../../../helpers/dropdown/custom-dropdown'
import CustomInput from '../../../../../helpers/custom-input'
import useInput from '../../../../../helpers/use-input'
import UploadDocumentCard from './upload-document-card'
import DocumentStatusCard from './document-status-card'
import { getApiCallDynamic, postApiCallDynamicWithOutReturn, uploadFile } from '../../../../../services/api-service'
import { API } from '../../../../../services/api-config'
import { useRecoilState } from 'recoil'
import { formState } from '../../../../../recoil/profile-atom'
import { toastNotify } from '../../../../../helpers/alert-msg'
import { UPDATE } from '../../../../../constants/constatnt-variable'


export default function LoanDocument() {
    let [formValue, setFormValue] = useRecoilState(formState)

    const route = useNavigate()
    const location = useLocation();

    let [documents, setDocuments] = useState(() => null)
    let [updateState, setUpdateState] = useState(() => false)

    useEffect(() => {

        getApiCallDynamic({ param: { formId: formValue?.id }, path: API.mloLoanDocument }).then((res) => {
            if (res?.status === 200) {
                setDocuments(res.data)
                setUpdateState(false)
            }
        });
        // }

    }, [updateState])



    const { control, handleSubmit, formState: { errors }, watch, setValue } = useForm({
        defaultValues: {

        }
    })






    const onSubmit = async data => {
        // console.log("data", data);

    }

    const fileUpload = (event) => {
        let file = event.target.files[0]
        if (event.target.files) {
            uploadFile({ docId: event.target.id, formId: formValue?.id, file: file, serviceType: 'loan', path: API.proxyDocumentOthersUpload }).then((res) => {
                if (res.status === 201) {
                    toastNotify("success", res.data.message);
                    setUpdateState(true)
                }
            })
        } else {
            console.log("not found");
        }

    }


    if (documents == null) {
        return <></>
    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                    <Box>
                        <Typography variant='h2' mb={2} >{`Loan Documents`}</Typography>
                        <Typography mb={2}>{`The purpose of collecting this information is to make sure that every individual will be treated fairly`}</Typography>

                        {
                            documents.map((item, index) => (
                                <>
                                    <UploadDocumentCard
                                        key={item.id}
                                        control={control}
                                        data={item}

                                        fileUpload={fileUpload}
                                    />


                                </>
                            ))
                        }

                    </Box>
                </Stack>
            </form>
        </>
    )
}

