import React, { createContext } from "react";
import { useRecoilValue } from "recoil";
import { profileState } from "../../../recoil/profile-atom";
import LoanSummaryLayout from "./loan-summary-layout";

export const LoanSummaryParentData = createContext();

export default function LoanSummaryVisiblity({ ...props }) {
  const { role: userRole } = useRecoilValue(profileState);

  const roleConfig = [
    {
      id: 1,
      role: ["Admin", "Broker", "MLO", "MLO Owner"],
      visiblityAccess: "All",
    },
    { id: 2, role: ["MLP"], visiblityAccess: "Partial" },
    {
      id: 3,
      role: ["Borrower"],
      visiblityAccess: null,
    },
  ];

  const visibleComponents = roleConfig.filter((field) =>
    field.role.includes(userRole)
  );

  // console.log('visibleComponents', visibleComponents);

  const editVisiblity = () => (
    <>
      {visibleComponents.map((permission) => {
        return (
          <LoanSummaryParentData.Provider
            key={permission.id}
            value={{
              visiblity: permission.visiblityAccess,
              role: permission.role,
            }}
          >
            <LoanSummaryLayout />
          </LoanSummaryParentData.Provider>
        );
      })}
    </>
  );

  return editVisiblity();
}
