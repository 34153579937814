import { Box, Button, Stack } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  UPDATE,
} from "../../../../constants/constatnt-variable";
import { API } from "../../../../services/api-config";
import CustomDropDown from "../../../../helpers/dropdown/custom-dropdown";
import { postApiCallDynamicWithOutReturn } from "../../../../services/api-service";
import { MLO_MLP_CHANGE_VALIDATION } from "../../../../constants/validation-rules";

export default function LoanSummaryMloMlpChangeForm({
  currentUser,
  mloMlpListData,
  type,
  loanCode,
  handleClose,
  reload,
}) {

  const getCurrentUser = () => {
    currentUser.userCode
    let res = null;
    mloMlpListData.map((data, i) => {
      if (data.userCode === currentUser.userCode) {
        res = data.userCode;
      }
    })
    return res;
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      changeMLOMLP: currentUser ? getCurrentUser() : null,
    },
  });

  const formOnSubmit = async (data) => {
    let apiCallData = {
      userCode: data.changeMLOMLP,
      type: type,
      loanCode: loanCode,
    };
    postApiCallDynamicWithOutReturn({
      data: apiCallData,
      path: API.loanChangeMloMlp,
      header: UPDATE,
      refresh: reload,
    });
    handleClose();
  };

  return (
    <>
      <form onSubmit={handleSubmit(formOnSubmit)}>
        <Box my={3}>
          <Controller
            name="changeMLOMLP"
            control={control}
            rules={MLO_MLP_CHANGE_VALIDATION.changeMLOMLP}
            render={({ field }) => (
              <CustomDropDown
                field={field}
                option={mloMlpListData.map((i) => {
                  return {
                    value: i.userCode,
                    label: i.firstName + " " + i.lastName,
                  };
                })}
                error={errors?.changeMLOMLP?.message}
              />
            )}
          />
        </Box>

        <Stack my={2} direction={"row-reverse"} spacing={2}>
          <Button variant="contained" type="submit">
            {`Update`}
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            {`Cancel`}
          </Button>
        </Stack>
      </form>
    </>
  );
}
