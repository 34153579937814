
import React, { useState } from 'react';
import { LocalPhone } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ValidationConstant } from '../../constants/validation';
import CustomInput from '../../helpers/custom-input';
import useInput from '../../helpers/use-input';
import { API } from '../../services/api-config';
import { DialogCommonHooks } from "../../helpers/modal/modal";
import { postApiCallDynamicWithOutReturn } from '../../services/api-service';
import SucessDemoImage from "../../assets/sucess.png";

export default function DemoScheduleFormWithMsg() {

    // State for controlling the demo success modal
    const [openDemoSuccessModal, setDemoSuccessModalOpen] = useState(false);

    // Function to open the demo success modal
    const handleDemoSuccessModalOpen = () => {
        setDemoSuccessModalOpen(true);
    };

    // Function to close the demo success modal
    const handleDemoSuccessModalClose = () => {
        setDemoSuccessModalOpen(false);
    };

    let [firstName] = useInput({
        data: "",
        status: false,
        initial: "",
    });

    let [lastName] = useInput({
        data: "",
        status: false,
        initial: "",
    });

    let [email] = useInput({
        data: "",
        status: false,
        initial: "",
    });

    let [phone] = useInput({
        data: "",
        status: false,
        initial: "",
    });

    let [message] = useInput({
        data: "",
        status: false,
        initial: "",
    });

    const [invitationMessage, setInvitationMessage] = useState('');

    const handleValidation = (values) => {
        let errors = {};
        if (!firstName.value) {
            errors.firstName = "Please enter First Name";
        }
        if (!lastName.value) {
            errors.lastName = "Please enter Last Name";
        }
        if (!ValidationConstant.emailRegx.test(email.value)) {
            errors.email = "Please enter a valid Email";
        }
        if (!ValidationConstant.usPhoneNumber.test(phone.value)) {
            errors.phone = "Please enter valid Phone";
        }
        if (!invitationMessage) {
            errors.message = "Please enter message";
        }
        return errors;
    };

    const handleDemoSchedule = async () => {
        const apiCallData = {
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value,
            phone: unformatPhoneNumber(phone.value),
            message: invitationMessage,
        };

        try {
            await postApiCallDynamicWithOutReturn({ data: apiCallData, path: API.demoSchedule });
            toast.success("Request for demo sent successfully!");
            handleDemoSuccessModalOpen();
        } catch (error) {
            console.error("API call failed:", error);
        }
    }

    const formik = useFormik({
        initialValues: {
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value,
            phone: phone.value,
            message: invitationMessage,
        },
        validate: handleValidation,
        onSubmit: () => {
            handleDemoSchedule();
        },
    });

    const handleInvitationMessageChange = (event) => {
        setInvitationMessage(event.target.value);
    }

    const remainingChars = 500 - invitationMessage.length;

    const unformatPhoneNumber = (phoneNumber) => {
        return phoneNumber.replace(/\D/g, '');
    };

    const formatPhoneNumber = (phoneNumber) => {
        return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    };

    const handlePhoneChange = (event) => {
        let inputPhone = event.target.value.replace(/\D/g, '');
        inputPhone = inputPhone.substring(0, 10);
        const formattedPhone = formatPhoneNumber(inputPhone);

        phone.onChange({ target: { value: formattedPhone } });
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Box>
                    <CustomInput
                        size="small"
                        fullWidth
                        boxProps={{ my: 2 }}
                        label={"First Name"}
                        required={true}
                        placeholder={"Jacob"}
                        error={formik.errors.firstName}
                        change={firstName}
                    />
                </Box>
                <Box>
                    <CustomInput
                        size="small"
                        fullWidth
                        boxProps={{ my: 2 }}
                        label={"Last Name"}
                        required={true}
                        placeholder={"Lee"}
                        error={formik.errors.lastName}
                        change={lastName}
                    />
                </Box>
                <Box>
                    <CustomInput
                        size="small"
                        fullWidth
                        boxProps={{ my: 2 }}
                        label={"Email id"}
                        required={true}
                        placeholder={"Jacob@gmail.com"}
                        error={formik.errors.email}
                        change={email}
                    />
                </Box>
                <Box>
                    <CustomInput
                        size="small"
                        fullWidth
                        boxProps={{ my: 2 }}
                        label={"Phone Number"}
                        required={true}
                        placeholder={"(466)788-9807"}
                        sicon={<LocalPhone />}
                        error={formik.errors.phone}
                        type={"text"}
                        value={formatPhoneNumber(phone.value)}
                        onChange={handlePhoneChange}
                    />
                </Box>
                <Box>
                    <div>
                        <CustomInput
                            size="small"
                            required={true}
                            fullWidth
                            error={formik.errors.message}
                            label={"Message"}
                            placeholder={"Enter Description"}
                            multiline
                            value={invitationMessage}
                            onChange={handleInvitationMessageChange}
                        />
                        <span>{remainingChars} characters remaining</span>
                    </div>
                </Box>
                <Stack
                    my={2}
                    direction={"row"}
                    spacing={2}
                    justifyContent={"flex-end"}
                >
                    <Button variant="outlined" onClick={handleDemoSuccessModalClose}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained">
                        Send
                    </Button>
                </Stack>
            </form>
            <ToastContainer /> {/* Toast container for toastify messages */}
            <DialogCommonHooks
                title={"Successful"}
                show={openDemoSuccessModal}
                handleClose={handleDemoSuccessModalClose}
                body={
                    <>
                        <Box textAlign={"center"} my={3}>
                            <img src={SucessDemoImage} width={"50%"} />
                        </Box>
                        <Box textAlign={"center"} margin={"auto"} p={3}>
                            <Typography variant="h3">
                                Thank you! <br />
                                Your request for a demo has been sent.
                            </Typography>
                        </Box>
                        <Stack my={3} direction={"row"} justifyContent={"center"}>
                            <Button variant="contained" onClick={handleDemoSuccessModalClose}>
                                Done
                            </Button>
                        </Stack>
                    </>
                }
            />
        </>
    );
}
