import { Grid, Typography } from "@mui/material";
import React from "react";
import SupportImg from "../../../assets/support.png";

export default function SupportEmpty() {
  return (
    <Grid container direction={"column"} spacing={2} alignItems="center">
      <Grid item>
        <img src={SupportImg} width={'100%'} />
      </Grid>
      <Grid item>
        <Typography variant="body1" textAlign={"center"}>
          {`Search and verify the person's details during the call`}
        </Typography>
      </Grid>
    </Grid>

  );
}
