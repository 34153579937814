import { Box, Button, Divider, Grid, Stack, Typography, Chip } from "@mui/material";
import React, { useContext, useState } from "react";
import { DialogCommonHooks } from "../../../../helpers/modal/modal";
import LoanSummaryLoanStatisticsForm from "../loan-summary-forms/loan-summary-loan-statistics-form";
import { KSPencilIcon } from "../../../../icons/custome-icon";
import { LoanSummaryParentData } from "../loan-summary-visiblity";
import { Lock } from "@mui/icons-material";
import {
  TimeStampToMonthDateYear,
  TimeStampToMonthDateYearTime,
} from "../../../../helpers/time-formater";
import { Unlock } from "feather-icons-react/build/IconComponents";

export default function LoanSummaryLoanStatistics({
  loanSummaryLoanStatisticsData,
  statisticsReloader,
}) {
  const { visiblity } = useContext(LoanSummaryParentData);
  const [loanSummaryModal, setLoanSummaryModal] = useState(false);

  const loanSummaryModalOpen = () => {
    setLoanSummaryModal(true);
  };

  const loanSummaryModalClose = () => {
    setLoanSummaryModal(false);
  };

  return (
    <>
      <Box bgcolor={"white"} borderRadius={3} p={2}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={1}
        >
          <Typography variant="h3">{`Loan Summary`}</Typography>
          <Typography color={"warning.main"} variant="body2">
            {loanSummaryLoanStatisticsData.loanStatus}
          </Typography>
          {/* {visiblity ? (
            <Button
              variant="outlined"
              size="small"
              startIcon={<KSPencilIcon />}
              onClick={loanSummaryModalOpen}
            >{`Edit`}</Button>
          ) : null} */}
        </Stack>

        <Divider
          style={{ marginTop: 8, marginBottom: 8 }}
          variant="fullWidth"
        />

        {/* Loan Summary Data */}
        <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <Typography variant="body2" style={{ color: 'grey' }}>{`Loan Number`}</Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              {loanSummaryLoanStatisticsData.loanCode
                ? loanSummaryLoanStatisticsData.loanCode
                : "Not available"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography variant="body2" style={{ color: 'grey' }}>{`Amount`}</Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              {loanSummaryLoanStatisticsData.loanAmount
                ? `$ ${loanSummaryLoanStatisticsData.loanAmount.toLocaleString(
                  undefined,
                  { maximumFractionDigits: 2 }
                )}`
                : "Not available"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography variant="body2" style={{ color: 'grey' }}>{`Term`}</Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              {loanSummaryLoanStatisticsData.loanTerm
                ? loanSummaryLoanStatisticsData.loanTerm
                : "Not available"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography variant="body2" style={{ color: 'grey' }}>{`Loan Type`}</Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              {loanSummaryLoanStatisticsData.loanType
                ? loanSummaryLoanStatisticsData.loanType
                : "Not available"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography variant="body2" style={{ color: 'grey' }}>{`Interest Rate`}</Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              {loanSummaryLoanStatisticsData.interestRate
                ? `${loanSummaryLoanStatisticsData.interestRate} %`
                : "Not available"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {/* Lock Status Start */}
            <Stack
              direction={"column"}
              gap={1}
              my={{ xs: 2, md: 0 }}
              alignItems={"start"}
              pl={{ xs: 0, md: 0 }}
            >
              {loanSummaryLoanStatisticsData.rateLockStatus == true ? (
                // <Chip
                //   icon={<Lock />}
                //   label="LOCKED"
                //   variant="outlined"
                //   color="warning"
                //   sx={{
                //     backgroundColor: "warninglight.main",
                //     border: "none",
                //     fontWeight: "bold",
                //     letterSpacing: 1.5,
                //   }}
                // />
                <Chip
                  //icon={<Typography component="span" color="green"><Lock /></Typography>}
                  icon={<Lock />}
                  label="LOCKED"
                  sx={{
                    backgroundColor: "success.main",
                    border: "none",
                    fontWeight: "bold",
                    letterSpacing: 1.5,
                  }}
                />

              ) : (
                <Chip
                  icon={<Unlock />}
                  label="NOT LOCKED"
                  variant="outlined"
                  color="warning"
                  sx={{
                    backgroundColor: "warninglight.main",
                    border: "none",
                    fontWeight: "bold",
                    letterSpacing: 1.5,
                  }}
                />
              )}
              {loanSummaryLoanStatisticsData.rateLockStatus ? (
                loanSummaryLoanStatisticsData.rateLockDate ? (
                  <Box>
                    <Typography
                      variant="overline"
                      fontStyle={"italic"}

                      sx={{ textTransform: "initial", lineHeight: 1, fontSize: "0.75rem" }}
                    >
                      {`(Locked on ${TimeStampToMonthDateYear(
                        loanSummaryLoanStatisticsData.rateLockDate
                      )})`}
                    </Typography>
                  </Box>
                ) : null
              ) : null}
            </Stack>
            {/* Lock Status End */}
          </Grid>


          <Grid item xs={6} md={6}>
            <Typography variant="body2" style={{ color: 'grey' }}>{`Estimated Monthly Payment`}</Typography>
            <Typography
              variant="body1"
              fontWeight={"bold"}
              color={"primary.main"}
            >
              {loanSummaryLoanStatisticsData.empAmount
                ? `$ ${loanSummaryLoanStatisticsData.empAmount.toLocaleString(
                  undefined,
                  { maximumFractionDigits: 2 }
                )}`
                : "Not available"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography variant="body2" style={{ color: 'grey' }}>{`Estimated Monthly Escrow`}</Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              {loanSummaryLoanStatisticsData.emeAmount
                ? `$ ${loanSummaryLoanStatisticsData.emeAmount.toLocaleString(
                  undefined,
                  { maximumFractionDigits: 2 }
                )}`
                : "Not available"}
            </Typography>
          </Grid>







          {/* <Grid item xs={6} md={6}>
            <Typography variant="body2" style={{ color: 'grey' }}>{`Appraisal Status`}</Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              {loanSummaryLoanStatisticsData.appraisalStatus
                ? loanSummaryLoanStatisticsData.appraisalStatus
                : "Not available"}
            </Typography>
          </Grid> */}
        </Grid>
      </Box>

      {/* Edit Modal of Loan Summaray */}
      {visiblity ? (
        <DialogCommonHooks
          title={`Edit Loan Summary`}
          show={loanSummaryModal}
          handleClose={loanSummaryModalClose}
          body={
            <LoanSummaryLoanStatisticsForm
              statisticsData={loanSummaryLoanStatisticsData}
              statisticsReloader={statisticsReloader}
              handleClose={loanSummaryModalClose}
            />
          }
        />
      ) : null}
    </>
  );
}
