import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";
import { Path } from "../../routes/route-config";
import { Container, InputLabel, Stack, TextField } from "@mui/material";
import { DialogCommonHooks } from "../../helpers/modal/modal";
import CustomInput from "../../helpers/custom-input";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import SuccessImage from "../../assets/sucessImage.png";
import SucessDemoImage from "../../assets/sucess.png";
import { makeStyles } from "@mui/styles";
import DemoScheduleForm from "./demo-schedule-form";
import Logo from "../../assets/Logo Black.svg";
import { postApiCallDynamicWithOutReturn } from "../../services/api-service";
import { API } from "../../services/api-config";

const customStyle = makeStyles({
  logoAll: {
    alignItems: "center",
    textDecoration: "none",
  },
  menuIcon: {
    color: "#001A40",
  },
  menuColor: {
    color: "#222222",
  },
  activeLinkAdd: {
    color: "#2F80ED",
    textDecoration: "underline"
  },
  activeLinkRemove: {
    color: "#2F80ED",
    textDecoration: "underline"
  }
});

export default function LandingAppBar({ setPage }) {
  const classes = customStyle();
  const [contactUsData, setContactUsData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  })

  const [contactUsError, setContactUsError] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  })

  // Contact us modal
  const [openContactModal, setContactModalOpen] = useState(false);
  const [invitationMessage, setInvitationMessage] = useState('');

  // const handleContactUsFormChange = (type, value) => {
  //   setContactUsData({ ...contactUsData, [type]: value })
  //   if (type === 'message')
  //     setInvitationMessage(value);
  //   else
  //     setInvitationMessage("");
  // }

  const handleContactUsFormChange = (type, value) => {
    let formattedValue = value;
    if (type === 'phone') {
      formattedValue = value.replace(/\D/g, '');
      formattedValue = formattedValue.slice(0, 10);
      formattedValue = formatPhoneNumber(formattedValue);
    }



    setContactUsData({ ...contactUsData, [type]: formattedValue });

    if (type === 'message')
      setInvitationMessage(formattedValue);
    else
      setInvitationMessage("");
  }

  const handleContactUsFormBlur = (type, value) => {
    if (type === 'phone') {
      const phoneNumberWithoutFormat = value.replace(/\D/g, '');
      setContactUsData({ ...contactUsData, [type]: phoneNumberWithoutFormat });
    }
  };

  const remainingChars = 500 - invitationMessage.length;
  const handleContactModalOpen = () => {
    setContactModalOpen(true);
  };

  const handleContactModalClose = () => {
    setContactModalOpen(false);
  };

  // sucess modal
  const [openSuccessModal, setSuccessModalOpen] = useState(false);

  const handleSuccessModalOpen = () => {
    let hasError = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: ''
    };
    let errorIs = false
    if (contactUsData.firstName === '') {
      hasError = { ...hasError, firstName: 'Enter valid first name' }
      errorIs = true;
    }
    if (contactUsData.lastName === '') {
      hasError = { ...hasError, lastName: 'Enter valid last name' }
      errorIs = true;
    }
    if (contactUsData.email === '') {
      hasError = { ...hasError, email: 'Enter valid email' }
      errorIs = true;
    }
    if (contactUsData.phone === '' || contactUsData.phone.length < 10) {
      hasError = { ...hasError, phone: 'Enter valid phone number' }
      errorIs = true;
    }
    if (errorIs) {
      setContactUsError(hasError)
    } else {
      postApiCallDynamicWithOutReturn({ data: contactUsData, path: API.adminContactUs })
      setSuccessModalOpen(true);
      setContactModalOpen(false);
    }

  };

  const handleSuccessModalClose = () => {
    setSuccessModalOpen(false);
  };

  // Schedule demo state

  const [openScheduleModal, setScheduleModalOpen] = useState(false);

  const handleScheduleModalOpen = () => {
    setScheduleModalOpen(true);
  };

  const handleScheduleModalClose = () => {
    setScheduleModalOpen(false);
  };

  // Demo sucess modal
  const [openDemoSuccessModal, setDemoSuccessModalOpen] = useState(false);

  const handleDemoSuccessModalOpen = () => {
    setDemoSuccessModalOpen(true);
  };

  const handleDemoSuccessModalClose = () => {
    setDemoSuccessModalOpen(false);
  };

  // Menu state

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const router = useNavigate();

  const handleNavigation = (e) => {

    router(e.target.id);
    handleCloseNavMenu();
  };

  const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  };

  return (
    <>
      <AppBar position="fixed" sx={{ bgcolor: "white" }}>
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Box
              display={"flex"}
              flexGrow={0}
              component={"a"}
              href="/"
              className={classes.logoAll}
            >
              <img src={Logo} width={"50%"} />
            </Box>

            {/* menu for mobile */}
            <Box
              justifyContent={"flex-end"}
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon className={classes.menuIcon} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleContactModalOpen();
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign={"center"}>Contact us</Typography>
                </MenuItem>
                <MenuItem
                  component={Link}
                  to={`#product`}
                  onClick={handleCloseNavMenu}
                >
                  <Typography textAlign={"center"}>Product</Typography>
                </MenuItem>
                <MenuItem
                  component={Link}
                  to={`#about-us`}
                  onClick={handleCloseNavMenu}
                >
                  <Typography textAlign={"center"}>About us</Typography>
                </MenuItem>
                <MenuItem
                  component={Link}
                  to={`/signin`}
                  onClick={handleCloseNavMenu}
                >
                  <Typography textAlign={"center"}>Sign in</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleScheduleModalOpen();
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign={"center"}>Schedule a demo</Typography>
                </MenuItem>
                <MenuItem
                  component={Link}
                  to={`/signup`}
                  onClick={handleCloseNavMenu}
                >
                  <Typography textAlign={"center"}>Sign up</Typography>
                </MenuItem>
              </Menu>
            </Box>

            {/* menu web */}
            <Box
              justifyContent={"flex-end"}
              sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
            >
              <Button></Button>
              <MenuItem onClick={handleContactModalOpen}>
                <Typography className={classes.menuColor}>
                  Contact Us
                </Typography>
              </MenuItem>
              <MenuItem component={Link} to={`#product`}>
                <Typography onClick={() => { setPage("landing"); }} className={classes.menuColor} >Product</Typography>
              </MenuItem>
              <MenuItem component={Link} to={`#about-us`}>
                <Typography onClick={() => { setPage("landing"); }} className={classes.menuColor} >About Us</Typography>
              </MenuItem>
              <MenuItem component={Link} to={`/signin`}>
                <Typography className={classes.menuColor}>Sign In</Typography>
              </MenuItem>
              <Stack direction={"row"} spacing={2} ml={1}>
                <Button onClick={handleScheduleModalOpen} variant="outlined">
                  Schedule a demo
                </Button>
                <Button
                  variant="contained"
                  id={Path.signup}
                  onClick={handleNavigation}
                >
                  Sign Up
                </Button>
              </Stack>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />

      {/* Contact Modal */}
      <DialogCommonHooks
        title="Contact us"
        show={openContactModal}
        handleClose={handleContactModalClose}
        body={
          <>
            <Box>
              <CustomInput
                error={contactUsError.firstName}
                onChange={(e) => handleContactUsFormChange('firstName', e.target.value)}
                size="small"
                fullWidth
                boxProps={{ my: 2 }}
                label={"First Name"}
                required={true}
                placeholder={"Sylvia"}
              />
            </Box>
            <Box>
              <CustomInput
                error={contactUsError.lastName}
                onChange={(e) => handleContactUsFormChange('lastName', e.target.value)}
                size="small"
                fullWidth
                boxProps={{ my: 2 }}
                label={"Last Name"}
                required={true}
                placeholder={"June"}
              />
            </Box>
            <Box>
              <CustomInput
                error={contactUsError.email}
                onChange={(e) => handleContactUsFormChange('email', e.target.value)}
                size="small"
                fullWidth
                boxProps={{ my: 2 }}
                label={"Email id"}
                required={true}
                placeholder="sylvia@gmail.com"
              />
            </Box>
            <Box>
              <CustomInput
                value={formatPhoneNumber(contactUsData.phone)}
                error={contactUsError.phone}
                onChange={(e) => handleContactUsFormChange('phone', e.target.value)}
                onBlur={(e) => handleContactUsFormBlur('phone', e.target.value)}
                size="small"
                fullWidth
                boxProps={{ my: 2 }}
                label={"Phone Number"}
                required={true}
                placeholder="(466)788-9807"
                sicon={<LocalPhoneIcon />}
              />
            </Box>
            <Box>
              <div>
                <InputLabel>What can we help you with ?</InputLabel>
                <CustomInput
                  onChange={(e) => handleContactUsFormChange('message', e.target.value)}
                  size="small"
                  fullWidth
                  placeholder="Enter Description"
                  multiline

                //  rows={5}
                // rowsMax={7}
                />
                <span>{remainingChars} characters remaining</span>
              </div>

            </Box>
            <Stack
              my={2}
              direction={"row"}
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Button variant="outlined" onClick={handleContactModalClose}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSuccessModalOpen}>
                Send
              </Button>
            </Stack>
          </>
        }
      />

      {/* Success Modal */}

      <DialogCommonHooks
        title={"Successfull"}
        show={openSuccessModal}
        handleClose={handleSuccessModalClose}
        body={
          <>
            <Box textAlign={"center"} overflow={'hidden'}>
              <img src={SuccessImage} width={'50%'} />
            </Box>
            <Box textAlign={"center"} margin={"auto"}>
              <Typography variant="h4">
                Thank you! <br />
                Your request has been submitted, <br />
                our team will contact you soon.
              </Typography>
            </Box>
            <Stack my={3} direction={"row"} justifyContent={"center"}>
              <Button variant="contained" onClick={handleSuccessModalClose}>
                Done
              </Button>
            </Stack>
          </>
        }
      />

      {/* Schedule demo modal */}

      <DialogCommonHooks
        title={"Schedule a demo"}
        show={openScheduleModal}
        handleClose={handleScheduleModalClose}
        body={
          <DemoScheduleForm
            handleScheduleModalClose={handleScheduleModalClose}
            handleDemoSuccessModalOpen={handleDemoSuccessModalOpen}
          />
        }
      />

      {/* Demo Sucess Modal */}
      <DialogCommonHooks
        title={"Successfull"}
        show={openDemoSuccessModal}
        handleClose={handleDemoSuccessModalClose}
        body={
          <>
            <Box textAlign={"center"} my={3}>
              <img src={SucessDemoImage} width={"50%"} />
            </Box>
            <Box textAlign={"center"} margin={"auto"} p={3}>
              <Typography variant="h3" fontFamily={'Inter-Medium'} fontSize={'1.25rem'}>
                Thank you! <br />
                Your request for a demo has been sent.
              </Typography>
            </Box>
            <Stack my={3} direction={"row"} justifyContent={"center"}>
              <Button variant="contained" onClick={handleDemoSuccessModalClose}>
                Done
              </Button>
            </Stack>
          </>
        }
      />
    </>
  );
}
