import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { formState } from "../../../../../recoil/profile-atom";
import { UPDATE } from "../../../../../constants/constatnt-variable";
import { API } from "../../../../../services/api-config";
import { postApiCallDynamicWithOutReturn } from "../../../../../services/api-service";
import LoanDetailsForm from "./loan-details-form";
import { DateToTimestapmFormater } from "../../../../../helpers/time-formater";
import { getApiCallDynamic } from "../../../../../services/api-service";

export default function LoanDetails() {
  let [formValue, setFormValue] = useRecoilState(formState);

  const route = useNavigate();
  const location = useLocation();

  let [loanDetails, setLoanDetails] = useState(() => null);
  const [fetchedStates, setFetchedStates] = useState([]);
  useEffect(() => {
    setLoanDetails(formValue?.loanDetails.page);
  }, [formValue?.loanDetails.page]);


  useEffect(() => {


    getApiCallDynamic({ path: API.validStates, param: { userCode: formValue?.getStarted?.page?.brokerCode } }).then(
      (res) => {
        if (res?.status === 200) {
          const mappedStates = res.data.map((state) => ({
            value: state.stateCode,
            label: `${state.stateName} (${state.stateCode})`,
          }));

          setFetchedStates(mappedStates);
        }
        else {
          console.log(" Error fetching data" + res?.status)
        }

      }
    );

  }, []);


  const handleSubmit = async (data) => {
    if (data?.statusOfRealtor == "Yes") {
      data = Object.assign(data, {
        realtor: {
          name: data?.name,
          companyName: data?.companyName,
          phone: data?.phone,
          email: data?.email,
        },
      });
    } else {
      data = Object.assign(data, { realtor: null });
    }

    data = Object.assign(
      data,
      { closingDate: DateToTimestapmFormater(data.closingDate) },
      { datePropertyAcquired: DateToTimestapmFormater(data.datePropertyAcquired) },
      { lastFinancedDate: DateToTimestapmFormater(data.lastFinancedDate) },
      { propertyBuiltDate: DateToTimestapmFormater(data.propertyBuiltDate) }
    );

    const apiCallData = {
      page: data,
      formId: formValue?.id,
      isComplete: true,
    };
    const updateState = () => {
      setFormValue({
        ...formValue,
        loanDetails: {
          ...formValue.loanDetails,
          page: data,
          isComplete: true,
        },
      });

      route(
        location.pathname.replace(
          /[^/]*$/,
          "borrower-info/personal-information"
        )
      );
    };

    await postApiCallDynamicWithOutReturn({
      data: apiCallData,

      path: API.loanDetails,
      header: UPDATE,
      refresh: updateState,
    });

  };

  return loanDetails == null ? (
    <></>
  ) : (
    <LoanDetailsForm data={loanDetails} fetchedStates={fetchedStates} brokerCode={formValue?.getStarted?.page?.brokerCode} onSubmit={handleSubmit} />
  );

}
