// import { ArrowBackIos, ArrowForwardIos, NavigateNext } from '@mui/icons-material'
// import { Box, Breadcrumbs, Button, Stack, Typography, TextField, useMediaQuery } from '@mui/material'
// import { Link as MLink } from '@mui/material';
// import { React, useEffect, useState } from 'react'
// import { TimeStampToMonthDateYear } from '../../../../../helpers/time-formater'
// import CustomLargeLabel from '../../../../../helpers/large-label';
// import { Auth } from "../../../../../auth/auth";
// import Divider from "@mui/material/Divider";
// import DownlaodCreditReport from './download-credit-report';
// import { useRecoilState } from 'recoil';
// import { kstheme } from "../../../../../theme/theme"
// import { profileState } from '../../../../../recoil/profile-atom';
// import CustomTooltip from '../../../../../helpers/custom-tooltip';

// export default function LoanCreditReportStatus({
//     creditData,
//     handleCreditSubmit,
//     handlePrevious,
//     partnerInfoPath,
//     creditPull,
//     onReissueSubmit,
//     creditReferenceNumber,
// }) {
//     let [profileData, setProfileData] = useRecoilState(profileState);
//     const [reissueCreditReferenceNumber, setReissueCreditReferenceNumber] = useState(creditReferenceNumber);
//     const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));

//     const [pullClicked, setPullClicked] = useState('');
//     const [reissueClicked, setReissueClicked] = useState('');
//     const [errorMessage, setErrorMessage] = useState('');

//     let generateText;

//     if (creditData?.isPulled) {
//         if (!creditData?.creditReportStatus) {
//             generateText = `The credit report was pulled on ${TimeStampToMonthDateYear(creditData?.pullDate)} with qualifying credit score of ${creditData?.creditScore}.`
//         } else {
//             generateText = `Credit consent was given on ${TimeStampToMonthDateYear(creditData?.consentDate)}! ${profileData?.role !== 'Borrower' ? 'Credit report pull is unsuccessfull please contact Credco at (800) 637-2422. ' : 'Credit pull is failed please report to your loan officer. '}
//                             You still can continue the application and submit.`
//         }
//     } else {
//         generateText = profileData?.role === 'Broker' ? `The credit consent was given on ${TimeStampToMonthDateYear(creditData?.consentDate)}!` : `Broker has not given the permission to pull the credit report.`
//     }

//     const handleInputChange = (event) => {
//         setReissueCreditReferenceNumber(event.target.value);
//     };

//     // comment

//     useEffect(() => {
//         if (Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) && reissueClicked) {
//             setPullClicked(false);
//         }
//     }, [reissueClicked]);
//     useEffect(() => {
//         if (Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) && pullClicked) {
//             setReissueClicked(false);
//         }
//     }, [pullClicked]);

//     console.log("error test " + creditData?.errorMessage + " creditData?.errorMessage === null " + (creditData?.errorMessage === null))

//     const handleReissueSubmit = () => {
//         if (!reissueCreditReferenceNumber.trim()) {
//             setErrorMessage('Credit reference number cannot be blank.');
//         } else {
//             setErrorMessage('');
//             onReissueSubmit({ creditReferenceNumber: reissueCreditReferenceNumber });
//             setReissueClicked(true);
//         }
//     };

//     return (
//         <>
//             <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'} >
//                 <Box >
//                     <Typography variant='h3' mb={2} mt={2}>{'Credit Report'}</Typography>

//                     <Typography variant='body1' mb={2} color={'#222222BF'}>
//                         {generateText}
//                     </Typography>


//                 </Box>
//                 <Box ml={2} mb={4}>
//                     {creditData?.creditReferenceNumber && (profileData?.role !== 'Borrower' || (creditData?.creditReportStatus && creditData?.isPulled)) &&
//                         <Typography variant='body1' mb={2} color={'#222222BF'}>
//                             Credit Reference #: {creditData.creditReferenceNumber}
//                         </Typography>
//                     }

//                     {Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) &&
//                         //(creditData?.errorMessage === null) &&

//                         <>
//                             <Box xs={10} mr={2}>
//                                 {(!creditData?.creditReportStatus && creditData?.isPulled) && <DownlaodCreditReport creditReportId={creditData.id} />}
//                             </Box>
//                         </>

//                     }
//                 </Box>
//                 {creditData?.creditReferenceNumber && (profileData?.role !== 'Borrower' || (creditData?.creditReportStatus && creditData?.isPulled)) &&
//                     <Divider />}
//                 {creditData?.creditReferenceNumber && (profileData?.role !== 'Borrower' || (creditData?.creditReportStatus && creditData?.isPulled)) &&
//                     <Divider />}
//                 <Box ml={2} mt={4} mb={4} >
//                     {Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) && (
//                         <Typography variant='h3' mb={2} mt={2}>
//                             {'Re-issue Credit Report'}
//                         </Typography>
//                     )}

//                     {Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) && (
//                         <>
//                             <Box xs={12}>
//                                 <Typography variant="body1">Enter Credit Reference Number:</Typography>
//                                 <Box mb={4} mt={1}>
//                                     <TextField
//                                         id="creditReferenceNumber"
//                                         variant="outlined"
//                                         fullWidth
//                                         sx={{ width: isMobile ? '70%' : '40%' }}
//                                         value={reissueCreditReferenceNumber}
//                                         onChange={handleInputChange}
//                                         placeholder='Enter credit reference number'
//                                         // error={!errorMessage}
//                                         helperText={errorMessage}
//                                     />
//                                 </Box>
//                                 <Box mb={3}>
//                                     <CustomTooltip title='Please be aware that re-issue of a credit report is free.'>
//                                         {/* <Button onClick={() => 

//                                         onReissueSubmit({ creditReferenceNumber: reissueCreditReferenceNumber })} 
//                                         variant="contained" sx={{

//                                             fontWeight: 500,
//                                             fontSize: '16px',
//                                             //  lineHeight: '16px',
//                                         }}>
//                                             {creditData?.isPulled ? 'Reissue Credit Report' : 'Reissue Credit Report'}
//                                         </Button> */}
//                                         <Button
//                                             onClick={() => {
//                                                 setReissueClicked(true);
//                                                 // onReissueSubmit({ creditReferenceNumber: reissueCreditReferenceNumber });
//                                                 handleReissueSubmit
//                                             }}
//                                             variant="contained"
//                                             sx={{
//                                                 fontWeight: 500,
//                                                 fontSize: '16px',
//                                                 // lineHeight: '16px',
//                                             }}
//                                         >
//                                             {creditData?.isPulled ? 'Reissue Credit Report' : 'Reissue Credit Report'}
//                                         </Button>

//                                     </CustomTooltip>
//                                 </Box>
//                                 <Box ml={2} mb={4} mr={2}>


//                                     {Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) && reissueClicked &&

//                                         <>
//                                             <Box mr={2}>

//                                                 <Typography variant='body1' mb={2} color={'#D94166'}>
//                                                     {creditData?.errorMessage}
//                                                 </Typography>

//                                             </Box>
//                                         </>

//                                     }
//                                 </Box>
//                             </Box>
//                         </>
//                     )}

//                 </Box>
//                 {creditData?.creditReferenceNumber && (profileData?.role !== 'Borrower' || (creditData?.creditReportStatus && creditData?.isPulled)) &&
//                     <Divider />}
//                 <Box ml={2} mb={4} mt={4}>
//                     <Box mb={4}>
//                         {Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) && (
//                             <Typography variant='h3' mb={2} mt={2}>{'Pull/Re-pull Credit Report'}</Typography>
//                         )}
//                         {/* {creditData?.creditReferenceNumber && (profileData?.role !== 'Borrower' || (creditData?.creditReportStatus && creditData?.isPulled)) &&
//                         <Typography variant='body1' mb={2} color={'#222222BF'}>
//                             Credit Reference #: {creditData.creditReferenceNumber}
//                         </Typography>
//                     } */}
//                     </Box>
//                     {Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) &&

//                         <>
//                             <Box mb={4}>
//                                 {/* {(!creditData?.creditReportStatus && creditData?.isPulled) && <DownlaodCreditReport creditReportId={creditData.id} />} */}
//                                 <CustomTooltip title="Please be aware that pulling/re-pulling a credit report will incur a fee.">
//                                     {/* < Button onClick={creditPull} variant='contained' sx={{

//                                         fontWeight: 500,
//                                         fontSize: '16px',
//                                         //lineHeight: '16px',
//                                     }}>
//                                         {creditData?.isPulled ? 'Repull Credit Report' : 'Pull Credit Report'}
//                                     </Button> */}
//                                     <Button
//                                         onClick={() => {
//                                             setPullClicked(true);
//                                             creditPull();
//                                         }}
//                                         variant='contained'
//                                         sx={{
//                                             fontWeight: 500,
//                                             fontSize: '16px',
//                                             // lineHeight: '16px',
//                                         }}
//                                     >
//                                         {creditData?.isPulled ? 'Repull Credit Report' : 'Pull Credit Report'}
//                                     </Button>

//                                 </CustomTooltip>
//                             </Box>
//                             <Box ml={2} mb={4} mr={2}>


//                                 {Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) && pullClicked &&

//                                     <>
//                                         <Box mr={2}>

//                                             <Typography variant='body1' mb={2} color={'#D94166'}>
//                                                 {creditData?.errorMessage}
//                                             </Typography>

//                                         </Box>
//                                     </>

//                                 }
//                             </Box>
//                         </>

//                     }
//                 </Box>
//                 <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
//                     <Stack direction={'row'} justifyContent={'space-between'}>
//                         <Button onClick={handlePrevious} startIcon={<ArrowBackIos />}>
//                             Previous
//                         </Button>
//                         <Button onClick={handleCreditSubmit} endIcon={<ArrowForwardIos />} >
//                             Save and Next
//                         </Button>
//                     </Stack>
//                 </Box>
//             </Stack >
//         </>
//     )
// }
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, Typography, TextField, Button, Stack, Divider, useMediaQuery, FormHelperText } from '@mui/material';
import { React, useEffect, useState } from 'react';
import { TimeStampToMonthDateYear, TimeStampToMonthDateYearWithTime } from '../../../../../helpers/time-formater';
import DownlaodCreditReport from './download-credit-report';
import { Auth } from '../../../../../auth/auth';
import { useRecoilState } from 'recoil';
import { kstheme } from '../../../../../theme/theme';
import { profileState } from '../../../../../recoil/profile-atom';
import CustomTooltip from '../../../../../helpers/custom-tooltip';

export default function LoanCreditReportStatus({
    creditData,
    handleCreditSubmit,
    handlePrevious,
    partnerInfoPath,
    creditPull,
    onReissueSubmit,
    creditReferenceNumber,
}) {
    let [profileData, setProfileData] = useRecoilState(profileState);
    const [reissueCreditReferenceNumber, setReissueCreditReferenceNumber] = useState(creditReferenceNumber);
    const [reissueClicked, setReissueClicked] = useState(false);
    const [pullClicked, setPullClicked] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const isMobile = useMediaQuery(kstheme.breakpoints.down('sm'));

    let generateText;
    if (creditData?.isPulled) {
        if (!creditData?.creditReportStatus) {
            generateText = `The credit report was pulled on ${TimeStampToMonthDateYearWithTime(creditData?.pullDate)} with qualifying credit score of ${creditData?.creditScore}.`;
        } else {
            generateText = `Credit consent was given on ${TimeStampToMonthDateYearWithTime(creditData?.consentDate)}! ${profileData?.role !== 'Borrower' ? 'Credit report pull is unsuccessful, please contact Credco at (800) 637-2422. ' : 'Credit pull failed, please report to your loan officer. '} You still can continue the application and submit.`;
        }
    } else {
        generateText = profileData?.role === 'Broker' ? `The credit consent was given on ${TimeStampToMonthDateYear(creditData?.consentDate)}!` : `Broker has not given the permission to pull the credit report.`;
    }

    const handleInputChange = (event) => {
        setReissueCreditReferenceNumber(event.target.value);
    };

    const handleReissueSubmit = () => {
        if (!reissueCreditReferenceNumber.trim()) {
            setErrorMessage('Credit reference number is required');
        } else {
            setErrorMessage('');
            onReissueSubmit({ creditReferenceNumber: reissueCreditReferenceNumber });
            setReissueClicked(true);
        }
    };

    useEffect(() => {
        if (Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) && reissueClicked) {
            setPullClicked(false);
        }
        //if (creditData?.creditReportStatus && reissueClicked)


    }, [reissueClicked]);

    useEffect(() => {
        if (Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) && pullClicked) {
            setReissueClicked(false);
        }
    }, [pullClicked]);

    return (
        <>
            <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                <Box>
                    <Typography variant='h3' mb={2} mt={2}>Credit Report</Typography>
                    <Typography variant='body1' mb={2} color={'#222222BF'}>
                        {generateText}
                    </Typography>
                </Box>

                <Box ml={2} mb={4}>
                    {creditData?.creditReferenceNumber && (profileData?.role !== 'Borrower' || (creditData?.creditReportStatus && creditData?.isPulled)) &&
                        <Typography variant='body1' mb={2} color={'#222222BF'}>
                            Credit Reference #: {creditData.creditReferenceNumber}
                        </Typography>
                    }

                    {Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) && (
                        <>
                            <Box xs={10} mr={2}>
                                {(!creditData?.creditReportStatus && creditData?.isPulled && creditData?.creditReferenceNumber
                                    //&& creditData?.errorMessage === null
                                ) && <DownlaodCreditReport creditReportId={creditData.id} />}
                            </Box>
                        </>
                    )}
                </Box>

                {creditData?.creditReferenceNumber && (profileData?.role !== 'Borrower' || (creditData?.creditReportStatus && creditData?.isPulled)) &&
                    <Divider />}


                <Box ml={2} mt={4} mb={4}>
                    {Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) && (
                        <Typography variant='h3' mb={2} mt={2}>Re-issue Credit Report</Typography>
                    )}

                    {Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) && (
                        <>
                            <Box xs={12}>
                                <Typography variant="body1">Enter Credit Reference Number:</Typography>
                                <Box mb={4} mt={1}>
                                    <TextField
                                        id="creditReferenceNumber"
                                        variant="outlined"
                                        fullWidth
                                        sx={{ width: isMobile ? '70%' : '40%' }}
                                        value={reissueCreditReferenceNumber}
                                        onChange={handleInputChange}
                                        placeholder='Enter credit reference number'
                                        required
                                    //  error={!!errorMessage}
                                    // helperText=

                                    />
                                    <Box mb={4} mt={1} ml={0.5}>
                                        {errorMessage && (
                                            <FormHelperText sx={{ color: '#D94166', fontSize: '1rem' }}>
                                                {errorMessage}
                                            </FormHelperText>
                                        )}

                                    </Box>

                                </Box>
                                <Box mb={3}>
                                    <CustomTooltip title='Please be aware that re-issue of a credit report is free.'>
                                        <Button
                                            onClick={handleReissueSubmit}
                                            variant="contained"
                                            sx={{ fontWeight: 500, fontSize: '16px' }}
                                        >
                                            {creditData?.isPulled ? 'Reissue Credit Report' : 'Reissue Credit Report'}
                                        </Button>
                                    </CustomTooltip>
                                </Box>
                                <Box ml={2} mb={4} mr={2}>
                                    {Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) && reissueClicked &&
                                        <Box mr={2}>
                                            <Typography variant='body1' mb={2} color={'#D94166'}>
                                                {creditData?.errorMessage}
                                            </Typography>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>

                {creditData?.creditReferenceNumber && (profileData?.role !== 'Borrower' || (creditData?.creditReportStatus && creditData?.isPulled)) &&
                    <Divider />}

                <Box ml={2} mb={4} mt={4}>
                    <Box mb={4}>
                        {Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) && (
                            <Typography variant='h3' mb={2} mt={2}>Pull/Re-pull Credit Report</Typography>
                        )}
                    </Box>
                    {Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) && (
                        <>
                            <Box mb={4}>
                                <CustomTooltip title="Please be aware that pulling/re-pulling a credit report will incur a fee.">
                                    <Button
                                        onClick={() => {
                                            setPullClicked(true);
                                            setErrorMessage(null)
                                            creditPull();
                                        }}
                                        variant='contained'
                                        sx={{ fontWeight: 500, fontSize: '16px' }}
                                    >
                                        {creditData?.isPulled ? 'Repull Credit Report' : 'Pull Credit Report'}
                                    </Button>
                                </CustomTooltip>
                            </Box>
                            <Box ml={2} mb={4} mr={2}>
                                {Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) && pullClicked &&
                                    <Box mr={2}>
                                        <Typography variant='body1' mb={2} color={'#D94166'}>
                                            {creditData?.errorMessage}
                                        </Typography>
                                    </Box>
                                }
                            </Box>
                        </>
                    )}
                </Box>

                <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Button onClick={handlePrevious} startIcon={<ArrowBackIos />}>
                            Previous
                        </Button>
                        <Button onClick={handleCreditSubmit} endIcon={<ArrowForwardIos />}>
                            Save and Next
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        </>
    );
}
