import { CheckCircleOutline } from '@mui/icons-material'
import { Chip, Stack, Typography } from '@mui/material'
import React from 'react'
import { KSCheckCircleIcon, KSDocumentSearchIcon, KSXCircleIcon } from '../../../../../icons/custome-icon'

export default function DocumentStatus({ matchStatus, tremarks }) {

    let chipStatus

    switch (matchStatus) {
        case "Matched":
            chipStatus = <Chip
                icon={<CheckCircleOutline color='success' />}
                label="Matched"
                variant="filled"
                size='small'
                title={tremarks}
                sx={{
                    textTransform: 'uppercase',
                    marginY: .5,
                    backgroundColor: '#30B08515',
                    color: 'success.main',
                    fontWeight: 'bold',
                }}
            />
            break;
        case "Unmatched":
            chipStatus = <Chip
                icon={<KSXCircleIcon color='A4' />}
                label="Not matched"
                variant="filled"
                size='small'
                title={tremarks}
                sx={{
                    textTransform: 'uppercase',
                    marginY: .5,
                    backgroundColor: '#FC6F2015',
                    color: 'A4.main',
                    fontWeight: 'bold'
                }}
            />
            break;

        case "Overridden":
            chipStatus = <Stack direction={'row'} gap={1} alignSelf={'center'}>
                <KSDocumentSearchIcon />
                <Typography color={'error.main'}>{`Overridden`}</Typography>
            </Stack>
            break;
        case "Invalid":
            chipStatus = <Chip
                icon={<KSXCircleIcon />}
                label="Not valid"
                variant="filled"
                size='small'
                sx={{
                    width: "30%",
                    textTransform: 'uppercase',
                    marginY: .5,
                    fontWeight: 'bold'
                }}
            />
            break;
        case "Valid":
            chipStatus = <Chip
                icon={<KSCheckCircleIcon color='primary' />}
                label="valid"
                variant="filled"
                size='small'
                sx={{
                    width: "30%",
                    textTransform: 'uppercase',
                    marginY: .5,
                    fontWeight: 'bold',
                    backgroundColor: '#2F80ED15',
                    color: 'primary.main'
                }}
            />
            break;
        default:
            chipStatus = ""
    }

    return (
        <Stack direction={'column'} gap={1} style={{ alignItems: 'center' }}>
            {chipStatus}
        </Stack>
    )
}
