import { ArrowBackIos, ArrowForwardIos, NavigateNext } from '@mui/icons-material'
import { Box, Breadcrumbs, Button, IconButton, Stack, Typography } from '@mui/material'
import { Link as MLink } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import CustomLargeLabel from '../../../../../helpers/large-label'
import { DialogCommonHooks } from '../../../../../helpers/modal/modal'
import CustomReactMaterialTable from '../../../../../helpers/table/react-material-table'
import { useRecoilState } from 'recoil'
import { formState } from '../../../../../recoil/profile-atom'
import { postApiCallDynamicWithOutReturn } from '../../../../../services/api-service'
import { API } from '../../../../../services/api-config'
import { CONSENT_STATUS, LOAN_CO_BORROWER_STATUS, UPDATE } from '../../../../../constants/constatnt-variable'
import { KSPencilAltIcon, KSTrashIcon } from '../../../../../icons/custome-icon'
import ConfirmationModel from '../../../../../helpers/modal/confirm-modal'
import CustomRadioChip from '../../../../../helpers/radio-chip'
import useInput from '../../../../../helpers/use-input'
import { GiftAndGrantsForm } from './gift-grants-form'
import { formatNumericValue, parseNumericValue } from '../../../../../helpers/numeric-format'

export default function LoanGiftAndGrants({ partnerInfoPath }) {
    const location = useLocation();
    const route = useNavigate();

    let [formValue, setFormValue] = useRecoilState(formState)



    let [giftList, setGiftList] = useState(() =>
        formValue?.giftGrants?.page == null ? [] : _.cloneDeep(formValue?.giftGrants?.page))


    const [status] = useInput({
        data: giftList?.length ? 'yes' : 'no',
        status: true,
        initial: ""
    });

    useEffect(() => {
        if (giftList.length <= 0) {
            if (status.value == 'yes') {
                handleClickOpen()
            }
        }

    }, [status.value])

    const [editState, setEditState] = useState(() => {
        return null
    })


    useEffect(() => {
        if (editState != null) {
            let list = _.cloneDeep(giftList)
            let editItem = list.find((item, i) => i == editState)
            setSecondGift(editItem)
            setModalOpen(true)
        }
    }, [editState])


    const [deleteState, setDeleteState] = useState(() => {
        return null;
    })



    useEffect(() => {
        if (deleteState != null) {
            giftList.splice(deleteState, 1)
            setGiftList([...giftList])
            setDeleteState(null)
        }
    }, [deleteState])



    const giftColumn = useMemo(
        () => [
            {
                accessorKey: "assetType",
                header: "Asset type",
                enableColumnActions: false,
            },
            {
                accessorKey: "settlementType",
                header: "Settlement type",
                enableColumnActions: false,
            },
            {
                accessorKey: "sourceType",
                header: "Source",
                enableColumnActions: false,
            },
            {
                accessorKey: "marketValue",
                header: "Market Value",
                enableColumnActions: false,
                Cell: ({ cell, row }) => (
                    <Typography >
                        {`$ ${formatNumericValue(row.original["marketValue"])}`}
                    </Typography>
                ),
            },
        ],
        []
    );
    //
    const [modalOpen, setModalOpen] = useState(false);
    const [secondGift, setSecondGift] = useState();

    const handleClickOpen = () => {
        setSecondGift([])
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
        setEditState(null)
    };

    const handleCloseDelete = (event, row) => {
        if (event.target.value == 'yes') {
            setDeleteState(row.index)
        }

    }

    const giftSubmit = (data) => {

        if (editState != null) {
            giftList[editState] = {
                ...giftList[editState],
                ...data
            }
            setEditState(null)
        } else {
            giftList.push(data);
        }

        setGiftList([...giftList])
        handleClose()
    }

    useEffect(() => {
        setGiftList(giftList);
    }, [giftList.length])



    const handleSubmit = async () => {
        const apiCallData = {
            page: status.value == 'yes' ? giftList : [],
            formId: formValue.id,
            isComplete: true
        }

        const updateState = () => {
            setFormValue({
                ...formValue,
                giftGrants: {
                    ...formValue.gift,
                    page: giftList,
                    isComplete: true,
                }
            })
            route(location.pathname.replace(/[^/]*$/, 'real-estate'));
        }

        await postApiCallDynamicWithOutReturn(
            {
                data: apiCallData,
                path: API.gift,
                header: UPDATE,
                refresh: updateState
            }
        )
    }



    return (
        <>
            <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                <Box>
                    <Typography variant='h3' my={2}>{`Gift & Grants`}</Typography>
                    {giftList.length !== 0 ?
                        null
                        :
                        <CustomRadioChip
                            labelComponent={<CustomLargeLabel required value={'Do you own any gift and grants?'} />}
                            state={status}
                            data={CONSENT_STATUS}

                        />}
                    {status.value == 'yes' ?
                        <CustomReactMaterialTable
                            staticLoad={true}
                            staticData={giftList}
                            columns={giftColumn}
                            enablePagination={false}
                            enableBottomToolbar={false}
                            enableSorting={false}
                            enableToolbarInternalActions={false}
                            renderTopToolbarCustomActions={
                                () => (
                                    <Button variant='outlined' onClick={handleClickOpen}>Add</Button>
                                )
                            }
                            enableRowActions={true}
                            renderRowActions={
                                ({ row }) => {
                                    return (
                                        <Stack direction={'row'}>
                                            <IconButton onClick={() => { setEditState(row.index) }}>
                                                <KSPencilAltIcon />
                                            </IconButton>
                                            <ConfirmationModel
                                                body={<IconButton
                                                    id={row.index} onClick={() => {

                                                    }}>
                                                    <KSTrashIcon />
                                                </IconButton>}
                                                message={`Are you sure want remove the gift and grant details ${row?.original?.addressLine1}...?`}
                                                title={`Delete Gift & Grants`}
                                                handleSubmit={(event) => { handleCloseDelete(event, row) }}
                                            />
                                        </Stack>
                                    )
                                }
                            }
                        /> :
                        null
                    }


                </Box>
                <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Link to={'../assets-credits'}>
                            <Button startIcon={<ArrowBackIos />}>
                                Previous
                            </Button>
                        </Link>

                        <Link onClick={handleSubmit}>
                            <Button endIcon={<ArrowForwardIos />} >
                                Save and Next
                            </Button>
                        </Link>

                    </Stack>
                </Box>
            </Stack>

            <DialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={`Gift & Grants`}
                body={
                    <GiftAndGrantsForm
                        onSubmit={giftSubmit}
                        editItem={secondGift}
                        handleClose={handleClose}
                        hasPartner={formValue.hasPartner}
                    />
                }
            />
        </>
    )
}
